import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeMaxwords } from '../actions/maxwords.js';

class Maxwords extends React.Component {

    handleSelectMaxWords = (selectedOption) => {
        this.props.changeMaxwords(Number(selectedOption.target.value));
    }

    render() {
        return(
            <div id="max-words">
                <div>Nombre de mots : </div>
                <div>
                    <select id="max-words-select" value={this.props.maxwords} onChange={this.handleSelectMaxWords}>
                        <option value="0">max</option>
                        <option value="3">3</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {maxwords: state.maxwords}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { changeMaxwords },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Maxwords);