import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLevel } from '../actions/level.js';
import CustomRange from '../components/CustomRange';

class Level extends React.Component {
    
    handleChangeLevel = (level) => {
        this.props.changeLevel(level);
    }

    render() {
        return(
            <div id="level-container">
                <div>
                    Niveau : 
                </div>                            
                <div id="level">
                    <CustomRange changelevelFunction={this.handleChangeLevel} 
                                 currentValue={this.props.level}>                                     
                    </CustomRange>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {level: state.level}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { changeLevel },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Level);