const words = 
[{
    "id": 1101,
    "name": "épaule",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "épaule"
        },
        "nl": {
            "article": "De",
            "mainWord": "schouder"
        },
        "en": {
            "article": "The",
            "mainWord": "shoulder"
        },
        "es": {
            "article": "El",
            "mainWord": "hombro"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schulter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205849/vocimage/corps/epaule/girl-8026927_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205849/vocimage/corps/epaule/tulip-1309666_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205849/vocimage/corps/epaule/arm-4813365_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205849/vocimage/corps/epaule/sisters-1328070_640.jpg"]
}, {
    "id": 1102,
    "name": "oeil",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "oeil"
        },
        "nl": {
            "article": "Het",
            "mainWord": "oog"
        },
        "en": {
            "article": "The",
            "mainWord": "eye"
        },
        "es": {
            "article": "El",
            "mainWord": "ojo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Auge"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690207483/vocimage/corps/oeil/eye-1132531_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207483/vocimage/corps/oeil/crocodile-5176851_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207482/vocimage/corps/oeil/eye-4904885_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207482/vocimage/corps/oeil/iris-1052871_640.jpg"]
}, {
    "id": 1103,
    "name": "oreille",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "oreille"
        },
        "nl": {
            "article": "Het",
            "mainWord": "oor"
        },
        "en": {
            "article": "The",
            "mainWord": "ear"
        },
        "es": {
            "article": "La",
            "mainWord": "oreja"
        },
        "de": {
            "article": "Das",
            "mainWord": "Ohr"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690207607/vocimage/corps/oreille/ears-2545756_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207607/vocimage/corps/oreille/donkey-3636234_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207607/vocimage/corps/oreille/dog-4765784_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207607/vocimage/corps/oreille/ear-3971050_640.jpg"]
}, {
    "id": 1104,
    "name": "bouche",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "bouche"
        },
        "nl": {
            "article": "De",
            "mainWord": "mond"
        },
        "en": {
            "article": "The",
            "mainWord": "mouth"
        },
        "es": {
            "article": "La",
            "mainWord": "boca"
        },
        "de": {
            "article": "Der",
            "mainWord": "Mund"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690204041/vocimage/corps/bouche/child-3680154_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204041/vocimage/corps/bouche/baby-19295_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204041/vocimage/corps/bouche/face-1083861_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204041/vocimage/corps/bouche/scream-4751647_640.jpg"]
}, {
    "id": 1105,
    "name": "dent",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "dent"
        },
        "nl": {
            "article": "De",
            "mainWord": "tand"
        },
        "en": {
            "article": "The",
            "mainWord": "tooth"
        },
        "es": {
            "article": "El",
            "mainWord": "diente"
        },
        "de": {
            "article": "Der",
            "mainWord": "Zahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205468/vocimage/corps/dent/toothbrush-3191097_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690209359/vocimage/corps/dent/children-3471587_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205468/vocimage/corps/dent/crocodile-1851313_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205467/vocimage/corps/dent/horse-1844792_640.jpg"]
}, {
    "id": 1106,
    "name": "jambe",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "jambe"
        },
        "nl": {
            "article": "Het",
            "mainWord": "been"
        },
        "en": {
            "article": "The",
            "mainWord": "leg"
        },
        "es": {
            "article": "La",
            "mainWord": "pierna"
        },
        "de": {
            "article": "Das",
            "mainWord": "Bein"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690206300/vocimage/corps/jambe/jogging-2343558_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206300/vocimage/corps/jambe/dog-3329117_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206300/vocimage/corps/jambe/child-3947716_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206300/vocimage/corps/jambe/people-2834080_640.jpg"]
}, {
    "id": 1107,
    "name": "main",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "main"
        },
        "nl": {
            "article": "De",
            "mainWord": "hand"
        },
        "en": {
            "article": "The",
            "mainWord": "hand"
        },
        "es": {
            "article": "La",
            "mainWord": "mano"
        },
        "de": {
            "article": "Die",
            "mainWord": "Hand"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690206467/vocimage/corps/main/heart-3147976_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206447/vocimage/corps/main/hands-600497_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206447/vocimage/corps/main/baby-2416718_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206447/vocimage/corps/main/hands-2847508_640.jpg"]
}, {
    "id": 1108,
    "name": "poitrine",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "poitrine"
        },
        "nl": {
            "article": "De",
            "mainWord": "borst"
        },
        "en": {
            "article": "The",
            "mainWord": "bust"
        },
        "es": {
            "article": "El",
            "mainWord": "pecho"
        },
        "de": {
            "article": "Die",
            "mainWord": "Brust"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690208041/vocimage/corps/poitrine/chest-press-1406263_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208041/vocimage/corps/poitrine/characters-2226778_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208041/vocimage/corps/poitrine/beauty-863063_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208041/vocimage/corps/poitrine/man-1846050_640.jpg"]
}, {
    "id": 1109,
    "name": "tête",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "tête"
        },
        "nl": {
            "article": "Het",
            "mainWord": "hoofd"
        },
        "en": {
            "article": "The",
            "mainWord": "head"
        },
        "es": {
            "article": "La",
            "mainWord": "cabeza"
        },
        "de": {
            "article": "Der",
            "mainWord": "Kopf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690208255/vocimage/corps/tete/boy-2847513_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208255/vocimage/corps/tete/harris-hawk-on-mans-head-4353845_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208255/vocimage/corps/tete/desperate-5011953_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208255/vocimage/corps/tete/blogger-2838945_640.jpg"]
}, {
    "id": 1110,
    "name": "bras",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bras"
        },
        "nl": {
            "article": "De",
            "mainWord": "arm"
        },
        "en": {
            "article": "The",
            "mainWord": "arm"
        },
        "es": {
            "article": "El",
            "mainWord": "brazo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Arm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690204175/vocimage/corps/bras/wheat-925760_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204175/vocimage/corps/bras/man-2037255_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204175/vocimage/corps/bras/engineer-4904908_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204175/vocimage/corps/bras/people-2606731_640.jpg"]
}, {
    "id": 1111,
    "name": "corps",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "corps"
        },
        "nl": {
            "article": "Het",
            "mainWord": "lichaam"
        },
        "en": {
            "article": "The",
            "mainWord": "body"
        },
        "es": {
            "article": "El",
            "mainWord": "cuerpo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Körper"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205088/vocimage/corps/corps/woman-2959213_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205088/vocimage/corps/corps/family-2755604_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205088/vocimage/corps/corps/yoga-4355196_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205088/vocimage/corps/corps/anatomy-254129_640.jpg"]
}, {
    "id": 1112,
    "name": "cou",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cou"
        },
        "nl": {
            "article": "De",
            "mainWord": "hals"
        },
        "en": {
            "article": "The",
            "mainWord": "neck"
        },
        "es": {
            "article": "El",
            "mainWord": "cuello"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hals"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690209202/vocimage/corps/cou/birds-1419964_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690209202/vocimage/corps/cou/giraffe-women-315040_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690209202/vocimage/corps/cou/necktie-1809461_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690209202/vocimage/corps/cou/woman-4660887_1280.jpg"]
}, {
    "id": 1113,
    "name": "coude",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "coude"
        },
        "nl": {
            "article": "De",
            "mainWord": "elleboog"
        },
        "en": {
            "article": "The",
            "mainWord": "elbow"
        },
        "es": {
            "article": "El",
            "mainWord": "codo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Ellbogen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205251/vocimage/corps/coude/man-80638_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205251/vocimage/corps/coude/warm-and-cold-1681783_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205251/vocimage/corps/coude/skeleton-778117_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205251/vocimage/corps/coude/person-3419871_640.jpg"]
}, {
    "id": 1114,
    "name": "doigt",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "doigt"
        },
        "nl": {
            "article": "De",
            "mainWord": "vinger"
        },
        "en": {
            "article": "The",
            "mainWord": "finger"
        },
        "es": {
            "article": "El",
            "mainWord": "dedo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Finger"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205546/vocimage/corps/doigt/secret-2725302_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205546/vocimage/corps/doigt/art-1839006_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205546/vocimage/corps/doigt/index-315754_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205546/vocimage/corps/doigt/paint-2985569_640.jpg"]
}, {
    "id": 1115,
    "name": "dos",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "dos"
        },
        "nl": {
            "article": "De",
            "mainWord": "rug"
        },
        "en": {
            "article": "The",
            "mainWord": "back"
        },
        "es": {
            "article": "La",
            "mainWord": "espalda"
        },
        "de": {
            "article": "Der",
            "mainWord": "Rücken"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690205736/vocimage/corps/dos/adventure-1850912_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205736/vocimage/corps/dos/swan-2494986_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691352233/vocimage/corps/dos/dos5.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690205736/vocimage/corps/dos/figure-1797362_1280.jpg"]
}, {
    "id": 1116,
    "name": "genou",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "genou"
        },
        "nl": {
            "article": "De",
            "mainWord": "knie"
        },
        "en": {
            "article": "The",
            "mainWord": "knee"
        },
        "es": {
            "article": "La",
            "mainWord": "rodilla"
        },
        "de": {
            "article": "Das",
            "mainWord": "Knie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690206162/vocimage/corps/genou/man-7401823_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206162/vocimage/corps/genou/man-2590778_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206162/vocimage/corps/genou/injury-4211129_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206162/vocimage/corps/genou/motorcycle-6376107_640.jpg"]
}, {
    "id": 1117,
    "name": "nez",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "nez"
        },
        "nl": {
            "article": "De",
            "mainWord": "neus"
        },
        "en": {
            "article": "The",
            "mainWord": "nose"
        },
        "es": {
            "article": "La",
            "mainWord": "nariz"
        },
        "de": {
            "article": "Die",
            "mainWord": "Nase"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690206551/vocimage/corps/nez/woman-698964_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206551/vocimage/corps/nez/pinocchio-2917652_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206551/vocimage/corps/nez/african-bush-squirrel-1580046_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690206551/vocimage/corps/nez/adult-19033_640.jpg"]
}, {
    "id": 1118,
    "name": "pied",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "pied"
        },
        "nl": {
            "article": "De",
            "mainWord": "voet"
        },
        "en": {
            "article": "The",
            "mainWord": "foot"
        },
        "es": {
            "article": "El",
            "mainWord": "pie"
        },
        "de": {
            "article": "Der",
            "mainWord": "Fuß"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690207674/vocimage/corps/pied/alone-1869914_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207674/vocimage/corps/pied/feet-718146_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207674/vocimage/corps/pied/beach-1836461_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690207674/vocimage/corps/pied/feet-932346_640.jpg"]
}, {
    "id": 1119,
    "name": "ventre",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "ventre"
        },
        "nl": {
            "article": "De",
            "mainWord": "buik"
        },
        "en": {
            "article": "The",
            "mainWord": "belly"
        },
        "es": {
            "article": "La",
            "mainWord": "barriga"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bauch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690208563/vocimage/corps/ventre/awaiting-19026_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208563/vocimage/corps/ventre/woman-3186730_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208563/vocimage/corps/ventre/figure-2421052_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208563/vocimage/corps/ventre/stomach-3532098_640.jpg"]
}, {
    "id": 1120,
    "name": "visage",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "visage"
        },
        "nl": {
            "article": "Het",
            "mainWord": "gezicht"
        },
        "en": {
            "article": "The",
            "mainWord": "face"
        },
        "es": {
            "article": "La",
            "mainWord": "cara"
        },
        "de": {
            "article": "Das",
            "mainWord": "Gesicht"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690208817/vocimage/corps/visage/gehlert-852762_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208802/vocimage/corps/visage/woman-856125_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208802/vocimage/corps/visage/smile-6242237_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690208802/vocimage/corps/visage/puppet-679427_640.jpg"]
}, {
    "id": 1121,
    "name": "cheveux",
    "themeId": 12,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "cheveux"
        },
        "nl": {
            "article": "Het",
            "mainWord": "haar"
        },
        "en": {
            "article": "The",
            "mainWord": "hair"
        },
        "es": {
            "article": "El",
            "mainWord": "pelo"
        },
        "de": {
            "article": "Die",
            "mainWord": "Haare"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690204348/vocimage/corps/cheveux/woman-5991504_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204348/vocimage/corps/cheveux/barber-1007902_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204348/vocimage/corps/cheveux/bride-1355473_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690204348/vocimage/corps/cheveux/women-1838768_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}