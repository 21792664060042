const words = 
[{
    "id": 1901,
    "name": "Bonjour",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bonjour"
        },
        "nl": {
            "article": "",
            "mainWord": "Goedendag"
        },
        "en": {
            "article": "",
            "mainWord": "Good morning"
        },
        "es": {
            "article": "",
            "mainWord": "Buenos días"
        },
        "de": {
            "article": "",
            "mainWord": "Guten Tag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062365/vocimage/salutations/bonjour/coffee-2408201__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062384/vocimage/salutations/bonjour/paddington-1708630__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691063342/vocimage/salutations/bonjour/barbie-1366378_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691063342/vocimage/salutations/bonjour/se-reveiller-le-matin.jpg"]
}, {
    "id": 1902,
    "name": "Bonsoir",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bonsoir"
        },
        "nl": {
            "article": "",
            "mainWord": "Goedenavond"
        },
        "en": {
            "article": "",
            "mainWord": "Good evening"
        },
        "es": {
            "article": "",
            "mainWord": "Buenas tardes"
        },
        "de": {
            "article": "",
            "mainWord": "Guten Abend"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062356/vocimage/salutations/bonsoir/lantern-2369904__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691064046/vocimage/salutations/bonsoir/adult-1807500_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691064047/vocimage/salutations/bonsoir/handshake-4002834_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691064047/vocimage/salutations/bonsoir/sunset-538286_640.jpg"]
}, {
    "id": 1903,
    "name": "Au revoir",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Au revoir"
        },
        "nl": {
            "article": "",
            "mainWord": "Tot ziens"
        },
        "en": {
            "article": "",
            "mainWord": "Goodbye"
        },
        "es": {
            "article": "",
            "mainWord": "Adiós"
        },
        "de": {
            "article": "",
            "mainWord": "Auf Wiedersehen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062345/vocimage/salutations/aurevoir/older-3601678__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062345/vocimage/salutations/aurevoir/frogs-1701048__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062502/vocimage/salutations/aurevoir/face-1381994_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062514/vocimage/salutations/aurevoir/mural-1347673_640.jpg"]
}, {
    "id": 1904,
    "name": "Merci",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Merci"
        },
        "nl": {
            "article": "",
            "mainWord": "Bedankt"
        },
        "en": {
            "article": "",
            "mainWord": "Thank you"
        },
        "es": {
            "article": "",
            "mainWord": "Gracias"
        },
        "de": {
            "article": "",
            "mainWord": "Danke"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691338329/vocimage/salutations/merci/girl-2899034_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691338329/vocimage/salutations/merci/Merci.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062331/vocimage/salutations/merci/man-2798968__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691075018/vocimage/salutations/merci/father-551921_640.jpg"]
}, {
    "id": 1905,
    "name": "S'il vous plaît",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "S'il vous plaît"
        },
        "nl": {
            "article": "",
            "mainWord": "Alstublieft"
        },
        "en": {
            "article": "",
            "mainWord": "Please"
        },
        "es": {
            "article": "",
            "mainWord": "Por favor"
        },
        "de": {
            "article": "",
            "mainWord": "Bitte"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062319/vocimage/salutations/s%20il%20vous%20plait/apples-1841132__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062319/vocimage/salutations/s%20il%20vous%20plait/hand-1549224__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691075000/vocimage/salutations/s%20il%20vous%20plait/mom-5143233_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691075001/vocimage/salutations/s%20il%20vous%20plait/dogs-2712045_640.jpg"]
}, {
    "id": 1906,
    "name": "Désolé",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Désolé"
        },
        "nl": {
            "article": "",
            "mainWord": "Sorry"
        },
        "en": {
            "article": "",
            "mainWord": "Sorry"
        },
        "es": {
            "article": "",
            "mainWord": "Lo siento"
        },
        "de": {
            "article": "",
            "mainWord": "Entschuldigung"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062289/vocimage/salutations/desole/dog-4637257__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062303/vocimage/salutations/desole/hug-1315545__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062303/vocimage/salutations/desole/boy-3732871__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691074592/vocimage/salutations/desole/s-il-vous-plait.jpg"]
}, {
    "id": 1907,
    "name": "Bon anniversaire",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bon anniversaire"
        },
        "nl": {
            "article": "",
            "mainWord": "Gelukkige verjaardag"
        },
        "en": {
            "article": "",
            "mainWord": "Happy birthday"
        },
        "es": {
            "article": "",
            "mainWord": "Feliz cumpleaños"
        },
        "de": {
            "article": "",
            "mainWord": "viel Glück zum Geburstag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062272/vocimage/salutations/bon%20anniversaire/guinea-pig-1969698__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062273/vocimage/salutations/bon%20anniversaire/birthday-1796179__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062603/vocimage/salutations/bon%20anniversaire/cupcakes-380178_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062603/vocimage/salutations/bon%20anniversaire/company-3016615_640.jpg"]
}, {
    "id": 1908,
    "name": "Bonne nuit",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bonne nuit"
        },
        "nl": {
            "article": "",
            "mainWord": "Goede nacht"
        },
        "en": {
            "article": "",
            "mainWord": "Good night"
        },
        "es": {
            "article": "",
            "mainWord": "Buenas noches"
        },
        "de": {
            "article": "",
            "mainWord": "Gute Nacht"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062259/vocimage/salutations/bonne%20nuit/good-night-3027664__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062259/vocimage/salutations/bonne%20nuit/teddy-bears-4841062__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691063526/vocimage/salutations/bonne%20nuit/sleep-7847114_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691063585/vocimage/salutations/bonne%20nuit/dog-5430987_640.jpg"]
}, {
    "id": 1909,
    "name": "Bon appétit",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bon appétit"
        },
        "nl": {
            "article": "",
            "mainWord": "Smakelijk"
        },
        "en": {
            "article": "",
            "mainWord": "Enjoy your meal"
        },
        "es": {
            "article": "",
            "mainWord": "Que aproveche"
        },
        "de": {
            "article": "",
            "mainWord": "Guten Appetit"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062916/vocimage/salutations/bon%20appetit/steak-1807532_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062916/vocimage/salutations/bon%20appetit/animal-927904_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062916/vocimage/salutations/bon%20appetit/people-2602736_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691063039/vocimage/salutations/bon%20appetit/food-3020282_640.jpg"]
}, {
    "id": 1910,
    "name": "Santé",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Santé"
        },
        "nl": {
            "article": "",
            "mainWord": "Proost"
        },
        "en": {
            "article": "",
            "mainWord": "Cheers"
        },
        "es": {
            "article": "",
            "mainWord": "Salud"
        },
        "de": {
            "article": "",
            "mainWord": "Prost"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691062241/vocimage/salutations/sante/party-1639939__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062241/vocimage/salutations/sante/thanksgiving-2911497__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691062241/vocimage/salutations/sante/drinks-2578446__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691075164/vocimage/salutations/sante/wine-6364070_640.jpg"]
}, {
    "id": 1911,
    "name": "rien",
    "themeId": 20,
    "translations": {
        "fr": {
            "article": "De",
            "mainWord": "rien"
        },
        "nl": {
            "article": "",
            "mainWord": "Met plezier"
        },
        "en": {
            "article": "",
            "mainWord": "You are welcome"
        },
        "es": {
            "article": "De",
            "mainWord": "nada"
        },
        "de": {
            "article": "",
            "mainWord": "Gerne"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691064983/vocimage/salutations/de%20rien/de_rieb.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691064983/vocimage/salutations/de%20rien/avec_plaisir.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691065431/vocimage/salutations/de%20rien/avec_plaisir1.webp", "https://res.cloudinary.com/chaudard/image/upload/v1691074528/vocimage/salutations/de%20rien/senior.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}