const words = 
[{
    "id": 2601,
    "name": "bougie",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "bougie"
        },
        "nl": {
            "article": "De",
            "mainWord": "kaars"
        },
        "en": {
            "article": "A",
            "mainWord": "candle"
        },
        "es": {
            "article": "La",
            "mainWord": "vela"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kerze"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691235380/vocimage/noel/bougie/candle-5782066_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235380/vocimage/noel/bougie/candle-1750640_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235380/vocimage/noel/bougie/fourth-advent-3001674_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235380/vocimage/noel/bougie/advent-wreath-1069961_640.jpg"]
}, {
    "id": 2602,
    "name": "boule",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "boule"
        },
        "nl": {
            "article": "De",
            "mainWord": "bal"
        },
        "en": {
            "article": "A",
            "mainWord": "ball"
        },
        "es": {
            "article": "La",
            "mainWord": "bola"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kugel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691235670/vocimage/noel/boule/christmas-2948566_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235683/vocimage/noel/boule/christmas-baubles-3873427_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235683/vocimage/noel/boule/balls-65333_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235683/vocimage/noel/boule/christmas-market-550323_640.jpg"]
}, {
    "id": 2603,
    "name": "bûche",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "bûche"
        },
        "nl": {
            "article": "De",
            "mainWord": "kerststronk"
        },
        "en": {
            "article": "A",
            "mainWord": "Yule Log"
        },
        "es": {
            "article": "El",
            "mainWord": "tronco"
        },
        "de": {
            "article": "Die",
            "mainWord": "Bûche de Noël"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691236024/vocimage/noel/buche/yule-log-5878816_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236024/vocimage/noel/buche/buche4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236024/vocimage/noel/buche/buche1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236025/vocimage/noel/buche/buche2.jpg"]
}, {
    "id": 2604,
    "name": "cheminée",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "cheminée"
        },
        "nl": {
            "article": "De",
            "mainWord": "haard"
        },
        "en": {
            "article": "A",
            "mainWord": "fireplace"
        },
        "es": {
            "article": "La",
            "mainWord": "chimenea"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kamin"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691236350/vocimage/noel/cheminee/fire-7633783_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236350/vocimage/noel/cheminee/woman-7624305_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236351/vocimage/noel/cheminee/feet-5558288_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236351/vocimage/noel/cheminee/fireplace-558985_640.jpg"]
}, {
    "id": 2605,
    "name": "couronne",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "couronne"
        },
        "nl": {
            "article": "De",
            "mainWord": "kroon"
        },
        "en": {
            "article": "A",
            "mainWord": "crown"
        },
        "es": {
            "article": "La",
            "mainWord": "corona"
        },
        "de": {
            "article": "Der",
            "mainWord": "Kranz"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691236565/vocimage/noel/couronne/christmas-7620462_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236565/vocimage/noel/couronne/christmas-wreath-on-fence-1913898_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236565/vocimage/noel/couronne/barn-4628776_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236565/vocimage/noel/couronne/advent-556061_640.jpg"]
}, {
    "id": 2606,
    "name": "crèche",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "crèche"
        },
        "nl": {
            "article": "De",
            "mainWord": "kerststal"
        },
        "en": {
            "article": "A",
            "mainWord": "Christmas crib"
        },
        "es": {
            "article": "El",
            "mainWord": "Belén"
        },
        "de": {
            "article": "Die",
            "mainWord": "Krippe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691236705/vocimage/noel/creche/people-3024011_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236705/vocimage/noel/creche/nativity-scene-522516_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236705/vocimage/noel/creche/crib-236843_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236705/vocimage/noel/creche/christmas-2874137_640.jpg"]
}, {
    "id": 2607,
    "name": "dinde",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "dinde"
        },
        "nl": {
            "article": "De",
            "mainWord": "kalkoen"
        },
        "en": {
            "article": "A",
            "mainWord": "turkey"
        },
        "es": {
            "article": "El",
            "mainWord": "pavo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Truthahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691237398/vocimage/noel/dinde/dinde3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237399/vocimage/noel/dinde/dinde1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237399/vocimage/noel/dinde/turkey-598492_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237399/vocimage/noel/dinde/dinde2.jpg"]
}, {
    "id": 2608,
    "name": "guirlande",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "guirlande"
        },
        "nl": {
            "article": "De",
            "mainWord": "slinger"
        },
        "en": {
            "article": "A",
            "mainWord": "garland"
        },
        "es": {
            "article": "La",
            "mainWord": "guirnalda"
        },
        "de": {
            "article": "Die",
            "mainWord": "Girlande"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691237548/vocimage/noel/guirlande/christmas-1067588_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237548/vocimage/noel/guirlande/cat-5792303_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237548/vocimage/noel/guirlande/new-year-4713853_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237548/vocimage/noel/guirlande/new-year-4717396_640.jpg"]
}, {
    "id": 2609,
    "name": "guirlande lumineuse",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "guirlande lumineuse"
        },
        "nl": {
            "article": "De",
            "mainWord": "kerstverlichting"
        },
        "en": {
            "article": "A",
            "mainWord": "light garland"
        },
        "es": {
            "article": "La",
            "mainWord": "guirnalda de luces"
        },
        "de": {
            "article": "Die",
            "mainWord": "Lichterkette"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691237681/vocimage/noel/guirlande%20electrique/cat-3054820_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237681/vocimage/noel/guirlande%20electrique/garland-1914621_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237681/vocimage/noel/guirlande%20electrique/christmas-7663548_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237681/vocimage/noel/guirlande%20electrique/christmas-tree-4561980_640.jpg"]
}, {
    "id": 2610,
    "name": "ange",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "ange"
        },
        "nl": {
            "article": "De",
            "mainWord": "engel"
        },
        "en": {
            "article": "An",
            "mainWord": "angel"
        },
        "es": {
            "article": "El",
            "mainWord": "ángel"
        },
        "de": {
            "article": "Der",
            "mainWord": "Engel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691234897/vocimage/noel/ange/christmas-1073197_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691234897/vocimage/noel/ange/angel-564351_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691234897/vocimage/noel/ange/feathers-4661244_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691234897/vocimage/noel/ange/angel-2906712_640.jpg"]
}, {
    "id": 2611,
    "name": "cadeau",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cadeau"
        },
        "nl": {
            "article": "Het",
            "mainWord": "geschenk"
        },
        "en": {
            "article": "A",
            "mainWord": "gift"
        },
        "es": {
            "article": "El",
            "mainWord": "regalo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Geschenk"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691236158/vocimage/noel/cadeau/gifts-570821_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236158/vocimage/noel/cadeau/christmas-tree-2999722_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236158/vocimage/noel/cadeau/christmas-5815034_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691236336/vocimage/noel/cadeau/christmas-4645449_640.jpg"]
}, {
    "id": 2612,
    "name": "père Noël",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "père Noël"
        },
        "nl": {
            "article": "",
            "mainWord": "Kerstman"
        },
        "en": {
            "article": "",
            "mainWord": "Santa Claus"
        },
        "es": {
            "article": "",
            "mainWord": "Papá Noel"
        },
        "de": {
            "article": "Der",
            "mainWord": "Weihnachtsmann"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691238549/vocimage/noel/pere%20noel/pere_noel2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238549/vocimage/noel/pere%20noel/santas-arm-1906514_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238549/vocimage/noel/pere%20noel/santa-890523_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238549/vocimage/noel/pere%20noel/pere_noel1.jpg"]
}, {
    "id": 2613,
    "name": "renne",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "renne"
        },
        "nl": {
            "article": "Het",
            "mainWord": "rendier"
        },
        "en": {
            "article": "A",
            "mainWord": "reindeer"
        },
        "es": {
            "article": "El",
            "mainWord": "reno"
        },
        "de": {
            "article": "Das",
            "mainWord": "Rentier"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691238706/vocimage/noel/renne/dog-4712929_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238706/vocimage/noel/renne/reindeer-2023720_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238706/vocimage/noel/renne/lapland-4688371_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238706/vocimage/noel/renne/christmas-scene-1846486_640.jpg"]
}, {
    "id": 2614,
    "name": "sapin de Noël",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sapin de Noël"
        },
        "nl": {
            "article": "De",
            "mainWord": "kerstboom"
        },
        "en": {
            "article": "A",
            "mainWord": "Christmas tree"
        },
        "es": {
            "article": "El",
            "mainWord": "árbol de Navidad"
        },
        "de": {
            "article": "Der",
            "mainWord": "Weihnachtsbaum"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691239563/vocimage/noel/sapin%20de%20noel/christmas-4647374_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239563/vocimage/noel/sapin%20de%20noel/young-woman-3041464_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239563/vocimage/noel/sapin%20de%20noel/christmas-trees-1823938_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239563/vocimage/noel/sapin%20de%20noel/christmas-tree-565356_640.jpg"]
}, {
    "id": 2615,
    "name": "sucre d'orge",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sucre d'orge"
        },
        "nl": {
            "article": "Het",
            "mainWord": "zuurstokje"
        },
        "en": {
            "article": "A",
            "mainWord": "candy cane"
        },
        "es": {
            "article": "El",
            "mainWord": "bastón de caramelo"
        },
        "de": {
            "article": "Die",
            "mainWord": "Zuckerstange"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691256551/vocimage/noel/sucre%20d%20orge/cake-balls-4713284_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239672/vocimage/noel/sucre%20d%20orge/christmas-3852168_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239672/vocimage/noel/sucre%20d%20orge/candy-cane-3878541_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691239672/vocimage/noel/sucre%20d%20orge/cute-1336696_640.jpg"]
}, {
    "id": 2616,
    "name": "traîneau",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "traîneau"
        },
        "nl": {
            "article": "De",
            "mainWord": "arrenslee"
        },
        "en": {
            "article": "A",
            "mainWord": "sleigh"
        },
        "es": {
            "article": "El",
            "mainWord": "trineo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Schlitten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691239696/vocimage/noel/traineau/christmas-7631914_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691240515/vocimage/noel/traineau/sled-1070159_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691240515/vocimage/noel/traineau/horses-6963914_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691240563/vocimage/noel/traineau/sleds-1861201_640.jpg"]
}, {
    "id": 2617,
    "name": "biscuits",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "biscuits"
        },
        "nl": {
            "article": "De",
            "mainWord": "koekjes"
        },
        "en": {
            "article": "",
            "mainWord": "Cookies"
        },
        "es": {
            "article": "Las",
            "mainWord": "galletas"
        },
        "de": {
            "article": "",
            "mainWord": "Keksen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691235275/vocimage/noel/biscuit/santa-claus-1906513_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235275/vocimage/noel/biscuit/christmas-cookies-6859115_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235275/vocimage/noel/biscuit/christmas-cookies-2975570_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691235276/vocimage/noel/biscuit/cookies-8082386_640.jpg"]
}, {
    "id": 2618,
    "name": "décoration",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "décoration"
        },
        "nl": {
            "article": "De",
            "mainWord": "versiering"
        },
        "en": {
            "article": "An",
            "mainWord": "ornament"
        },
        "es": {
            "article": "El",
            "mainWord": "adorno"
        },
        "de": {
            "article": "Die",
            "mainWord": "dekoration"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691237172/vocimage/noel/decoration/deco4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237172/vocimage/noel/decoration/deco1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237172/vocimage/noel/decoration/deco3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237172/vocimage/noel/decoration/deco2.png"]
}, {
    "id": 2619,
    "name": "étoile",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "étoile"
        },
        "nl": {
            "article": "De",
            "mainWord": "ster"
        },
        "en": {
            "article": "A",
            "mainWord": "star"
        },
        "es": {
            "article": "La",
            "mainWord": "estrella"
        },
        "de": {
            "article": "Der",
            "mainWord": "Stern"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691237939/vocimage/noel/l%20etoile/star-2917_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237939/vocimage/noel/l%20etoile/snow-2992534_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237939/vocimage/noel/l%20etoile/bokeh-4616329_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691237939/vocimage/noel/l%20etoile/christmas-market-68695_640.jpg"]
}, {
    "id": 2620,
    "name": "Noël",
    "themeId": 27,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Noël"
        },
        "nl": {
            "article": "",
            "mainWord": "Kerstmis"
        },
        "en": {
            "article": "",
            "mainWord": "Christmas"
        },
        "es": {
            "article": "",
            "mainWord": "Navidad"
        },
        "de": {
            "article": "",
            "mainWord": "Weihnachten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691238167/vocimage/noel/noel/christmas-1058667_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238167/vocimage/noel/noel/christmas-motif-2938558_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238176/vocimage/noel/noel/christmas-2971961_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691238176/vocimage/noel/noel/church-648430_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}