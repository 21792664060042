const words = 
[{
    "id": 401,
    "name": "cent",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "cent"
        },
        "nl": {
            "article": "",
            "mainWord": "honderd"
        },
        "en": {
            "article": "",
            "mainWord": "one hundred"
        },
        "es": {
            "article": "",
            "mainWord": "cien"
        },
        "de": {
            "article": "",
            "mainWord": "hundert"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690482858/vocimage/chiffres/cent/balloons-343246__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482945/vocimage/chiffres/cent/one-hundred-1165990_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482946/vocimage/chiffres/cent/money-1502843_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690483179/vocimage/chiffres/cent/hundred-3835735_640.jpg"]
}, {
    "id": 402,
    "name": "cinq",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "cinq"
        },
        "nl": {
            "article": "",
            "mainWord": "vijf"
        },
        "en": {
            "article": "",
            "mainWord": "five"
        },
        "es": {
            "article": "",
            "mainWord": "cinco"
        },
        "de": {
            "article": "",
            "mainWord": "fünf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690471965/vocimage/chiffres/cinq/five-670151_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690471965/vocimage/chiffres/cinq/clock-3475903__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690471966/vocimage/chiffres/cinq/crochet-hooks-356752__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690471966/vocimage/chiffres/cinq/beach-chair-1550051_640.jpg"]
}, {
    "id": 403,
    "name": "cinquante",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "cinquante"
        },
        "nl": {
            "article": "",
            "mainWord": "vijftig"
        },
        "en": {
            "article": "",
            "mainWord": "fifty"
        },
        "es": {
            "article": "",
            "mainWord": "cincuenta"
        },
        "de": {
            "article": "",
            "mainWord": "fünfzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690480697/vocimage/chiffres/cinquante/decoration-3701129__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480697/vocimage/chiffres/cinquante/money-3481699__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691351235/vocimage/chiffres/cinquante/voiture50.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481267/vocimage/chiffres/cinquante/50.jpg"]
}, {
    "id": 404,
    "name": "deux",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "deux"
        },
        "nl": {
            "article": "",
            "mainWord": "twee"
        },
        "en": {
            "article": "",
            "mainWord": "two"
        },
        "es": {
            "article": "",
            "mainWord": "dos"
        },
        "de": {
            "article": "",
            "mainWord": "zwei"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690485958/vocimage/chiffres/deux/ducks-1339558_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690485832/vocimage/chiffres/deux/look-to-the-future-together-1125126_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690485835/vocimage/chiffres/deux/meerkat-4619275_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690485849/vocimage/chiffres/deux/teamwork-3237646_640.jpg"]
}, {
    "id": 405,
    "name": "dix",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "dix"
        },
        "nl": {
            "article": "",
            "mainWord": "tien"
        },
        "en": {
            "article": "",
            "mainWord": "ten"
        },
        "es": {
            "article": "",
            "mainWord": "diez"
        },
        "de": {
            "article": "",
            "mainWord": "zehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472594/vocimage/chiffres/dix/summer-3527250__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472594/vocimage/chiffres/dix/roman-numeral-1005700__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472760/vocimage/chiffres/dix/thermometer-2283796_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472800/vocimage/chiffres/dix/euros-718415_640.jpg"]
}, {
    "id": 406,
    "name": "dix-huit",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "dix-huit"
        },
        "nl": {
            "article": "",
            "mainWord": "achttien"
        },
        "en": {
            "article": "",
            "mainWord": "eighteen"
        },
        "es": {
            "article": "",
            "mainWord": "dieciocho"
        },
        "de": {
            "article": "",
            "mainWord": "achtzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690476432/vocimage/chiffres/dix%20huit/husum-3282466_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476432/vocimage/chiffres/dix%20huit/nest-box-2216754_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476442/vocimage/chiffres/dix%20huit/road-sign-4965869__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476454/vocimage/chiffres/dix%20huit/calendar-3073971_640.jpg"]
}, {
    "id": 407,
    "name": "dix-neuf",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "dix-neuf"
        },
        "nl": {
            "article": "",
            "mainWord": "negentien"
        },
        "en": {
            "article": "",
            "mainWord": "nineteen"
        },
        "es": {
            "article": "",
            "mainWord": "diecinueve"
        },
        "de": {
            "article": "",
            "mainWord": "neunzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690476490/vocimage/chiffres/dix%20neuf/bingo-4466817_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476880/vocimage/chiffres/dix%20neuf/19.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476880/vocimage/chiffres/dix%20neuf/foot.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476881/vocimage/chiffres/dix%20neuf/number-192346_640.jpg"]
}, {
    "id": 408,
    "name": "dix-sept",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "dix-sept"
        },
        "nl": {
            "article": "",
            "mainWord": "zeventien"
        },
        "en": {
            "article": "",
            "mainWord": "seventeen"
        },
        "es": {
            "article": "",
            "mainWord": "diecisiete"
        },
        "de": {
            "article": "",
            "mainWord": "siebzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690476360/vocimage/chiffres/dix%20sept/luck-839037__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476360/vocimage/chiffres/dix%20sept/auto-1087253__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476360/vocimage/chiffres/dix%20sept/cable-car-2332146__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476407/vocimage/chiffres/dix%20sept/storage-3434053_640.jpg"]
}, {
    "id": 409,
    "name": "douze",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "douze"
        },
        "nl": {
            "article": "",
            "mainWord": "twaalf"
        },
        "en": {
            "article": "",
            "mainWord": "twelve"
        },
        "es": {
            "article": "",
            "mainWord": "doce"
        },
        "de": {
            "article": "",
            "mainWord": "zwölf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690473799/vocimage/chiffres/douze/mailbox-1056324__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474112/vocimage/chiffres/douze/time-2829522_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474112/vocimage/chiffres/douze/cake-947438_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474112/vocimage/chiffres/douze/dice-1068618_640.jpg"]
}, {
    "id": 410,
    "name": "huit",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "huit"
        },
        "nl": {
            "article": "",
            "mainWord": "acht"
        },
        "en": {
            "article": "",
            "mainWord": "eight"
        },
        "es": {
            "article": "",
            "mainWord": "ocho"
        },
        "de": {
            "article": "",
            "mainWord": "acht"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472331/vocimage/chiffres/huit/spider-4189641__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472331/vocimage/chiffres/huit/eight-ball-3721944__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472331/vocimage/chiffres/huit/snake-1010085__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472331/vocimage/chiffres/huit/house-number-352147__340.jpg"]
}, {
    "id": 411,
    "name": "mille",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "mille"
        },
        "nl": {
            "article": "",
            "mainWord": "duizend"
        },
        "en": {
            "article": "",
            "mainWord": "thousand"
        },
        "es": {
            "article": "",
            "mainWord": "mil"
        },
        "de": {
            "article": "",
            "mainWord": "tausend"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690483503/vocimage/chiffres/mille/ruble-1863439_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690483623/vocimage/chiffres/mille/1000bis.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690483624/vocimage/chiffres/mille/A350b.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690483624/vocimage/chiffres/mille/1000.jpg"]
}, {
    "id": 412,
    "name": "neuf",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "neuf"
        },
        "nl": {
            "article": "",
            "mainWord": "negen"
        },
        "en": {
            "article": "",
            "mainWord": "nine"
        },
        "es": {
            "article": "",
            "mainWord": "nueve"
        },
        "de": {
            "article": "",
            "mainWord": "neun"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472413/vocimage/chiffres/neuf/harry-potter-1640525__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472413/vocimage/chiffres/neuf/nine-3095449__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472522/vocimage/chiffres/neuf/digits-273630_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472522/vocimage/chiffres/neuf/rubiks-cube-two-layer-solved-5980245_640.jpg"]
}, {
    "id": 413,
    "name": "nonante",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "nonante"
        },
        "nl": {
            "article": "",
            "mainWord": "negentig"
        },
        "en": {
            "article": "",
            "mainWord": "ninety"
        },
        "es": {
            "article": "",
            "mainWord": "noventa"
        },
        "de": {
            "article": "",
            "mainWord": "neunzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690482559/vocimage/chiffres/nonante/ninety-4682295__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482560/vocimage/chiffres/nonante/number-397491__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482780/vocimage/chiffres/nonante/90.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482817/vocimage/chiffres/nonante/90bis.jpg"]
}, {
    "id": 414,
    "name": "onze",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "onze"
        },
        "nl": {
            "article": "",
            "mainWord": "elf"
        },
        "en": {
            "article": "",
            "mainWord": "eleven"
        },
        "es": {
            "article": "",
            "mainWord": "once"
        },
        "de": {
            "article": "",
            "mainWord": "elf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472873/vocimage/chiffres/onze/the-eleventh-hour-1738431__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472873/vocimage/chiffres/onze/number-437929__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472873/vocimage/chiffres/onze/house-number-1522052_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472874/vocimage/chiffres/onze/nut-1538319__340.jpg"]
}, {
    "id": 415,
    "name": "quarante",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "quarante"
        },
        "nl": {
            "article": "",
            "mainWord": "veertig"
        },
        "en": {
            "article": "",
            "mainWord": "forty"
        },
        "es": {
            "article": "",
            "mainWord": "cuarenta"
        },
        "de": {
            "article": "",
            "mainWord": "vierzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690480250/vocimage/chiffres/quarante/house-number-193789__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480250/vocimage/chiffres/quarante/sign-4834728__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480260/vocimage/chiffres/quarante/folding-rule-205001_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480669/vocimage/chiffres/quarante/40.jpg"]
}, {
    "id": 416,
    "name": "quatorze",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "quatorze"
        },
        "nl": {
            "article": "",
            "mainWord": "veertien"
        },
        "en": {
            "article": "",
            "mainWord": "fourteen"
        },
        "es": {
            "article": "",
            "mainWord": "catorce"
        },
        "de": {
            "article": "",
            "mainWord": "vierzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690474329/vocimage/chiffres/quatorze/boat-353097__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474414/vocimage/chiffres/quatorze/calendar-771597_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691350860/vocimage/chiffres/quatorze/14.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474414/vocimage/chiffres/quatorze/ball-1741787_640.jpg"]
}, {
    "id": 417,
    "name": "quatre",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "quatre"
        },
        "nl": {
            "article": "",
            "mainWord": "vier"
        },
        "en": {
            "article": "",
            "mainWord": "four"
        },
        "es": {
            "article": "",
            "mainWord": "cuatro"
        },
        "de": {
            "article": "",
            "mainWord": "vier"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690455612/vocimage/chiffres/quatre/dessert-3129515_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690484652/vocimage/chiffres/quatre/clover-7051944_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690484662/vocimage/chiffres/quatre/duckling-3737285_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690484685/vocimage/chiffres/quatre/cakes-3601525_640.jpg"]
}, {
    "id": 418,
    "name": "quatre-vingts",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "quatre-vingts"
        },
        "nl": {
            "article": "",
            "mainWord": "tachtig"
        },
        "en": {
            "article": "",
            "mainWord": "eighty"
        },
        "es": {
            "article": "",
            "mainWord": "ochenta"
        },
        "de": {
            "article": "",
            "mainWord": "achtzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690481865/vocimage/chiffres/quatre%20vingts/wood-882189__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482154/vocimage/chiffres/quatre%20vingts/quatrevingt.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482168/vocimage/chiffres/quatre%20vingts/80.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690482535/vocimage/chiffres/quatre%20vingts/80bis.jpg"]
}, {
    "id": 419,
    "name": "quinze",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "quinze"
        },
        "nl": {
            "article": "",
            "mainWord": "vijftien"
        },
        "en": {
            "article": "",
            "mainWord": "fifteen"
        },
        "es": {
            "article": "",
            "mainWord": "quince"
        },
        "de": {
            "article": "",
            "mainWord": "fünfzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690474437/vocimage/chiffres/quinze/fifteen-years-2906843__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476241/vocimage/chiffres/quinze/quinzee.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476241/vocimage/chiffres/quinze/zoom-br%C3%A9sil--les-num%C3%A9ros-des-joueurs-d%C3%A9voil%C3%A9s-.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476241/vocimage/chiffres/quinze/quinze1.jpg"]
}, {
    "id": 420,
    "name": "seize",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "seize"
        },
        "nl": {
            "article": "",
            "mainWord": "zestien"
        },
        "en": {
            "article": "",
            "mainWord": "sixteen"
        },
        "es": {
            "article": "",
            "mainWord": "dieciséis"
        },
        "de": {
            "article": "",
            "mainWord": "sechzehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690476281/vocimage/chiffres/seize/birthday-1271358__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476282/vocimage/chiffres/seize/sixteen-4677399__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476306/vocimage/chiffres/seize/calendar-4980655_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476315/vocimage/chiffres/seize/nature-4095805_640.jpg"]
}, {
    "id": 421,
    "name": "sept",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "sept"
        },
        "nl": {
            "article": "",
            "mainWord": "zeven"
        },
        "en": {
            "article": "",
            "mainWord": "seven"
        },
        "es": {
            "article": "",
            "mainWord": "siete"
        },
        "de": {
            "article": "",
            "mainWord": "sieben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472194/vocimage/chiffres/sept/key-3784339__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472194/vocimage/chiffres/sept/hopscotch-3878606__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472194/vocimage/chiffres/sept/snow-white-354436__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691337320/vocimage/chiffres/sept/taiwan-4620346_640.jpg"]
}, {
    "id": 422,
    "name": "septante",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "septante"
        },
        "nl": {
            "article": "",
            "mainWord": "zeventig"
        },
        "en": {
            "article": "",
            "mainWord": "seventy"
        },
        "es": {
            "article": "",
            "mainWord": "setenta"
        },
        "de": {
            "article": "",
            "mainWord": "siebzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690481499/vocimage/chiffres/septante/decoration-3701141__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691350427/vocimage/chiffres/septante/cashbox-1764414_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481725/vocimage/chiffres/septante/70.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481815/vocimage/chiffres/septante/70bis.jpg"]
}, {
    "id": 423,
    "name": "six",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "six"
        },
        "nl": {
            "article": "",
            "mainWord": "zes"
        },
        "en": {
            "article": "",
            "mainWord": "six"
        },
        "es": {
            "article": "",
            "mainWord": "seis"
        },
        "de": {
            "article": "",
            "mainWord": "sechs"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690472083/vocimage/chiffres/six/marbles-319938__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472083/vocimage/chiffres/six/dice-769344_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472103/vocimage/chiffres/six/cake-5075590__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690472108/vocimage/chiffres/six/yellow-3087179__340.jpg"]
}, {
    "id": 424,
    "name": "soixante",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "soixante"
        },
        "nl": {
            "article": "",
            "mainWord": "zestig"
        },
        "en": {
            "article": "",
            "mainWord": "sixty"
        },
        "es": {
            "article": "",
            "mainWord": "sesenta"
        },
        "de": {
            "article": "",
            "mainWord": "sechzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690481305/vocimage/chiffres/soixante/route-66-3479627_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481306/vocimage/chiffres/soixante/percent-1176979_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481306/vocimage/chiffres/soixante/fahrenheit-1082174_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690481469/vocimage/chiffres/soixante/soixante.jpg"]
}, {
    "id": 425,
    "name": "treize",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "treize"
        },
        "nl": {
            "article": "",
            "mainWord": "dertien"
        },
        "en": {
            "article": "",
            "mainWord": "thirteen"
        },
        "es": {
            "article": "",
            "mainWord": "trece"
        },
        "de": {
            "article": "",
            "mainWord": "dreizehn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690474149/vocimage/chiffres/treize/number-14331__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474149/vocimage/chiffres/treize/thirteen-4532748__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474219/vocimage/chiffres/treize/thirteen-266679_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690474219/vocimage/chiffres/treize/door-1587023_1280.jpg"]
}, {
    "id": 426,
    "name": "trente",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "trente"
        },
        "nl": {
            "article": "",
            "mainWord": "dertig"
        },
        "en": {
            "article": "",
            "mainWord": "thirty"
        },
        "es": {
            "article": "",
            "mainWord": "treinta"
        },
        "de": {
            "article": "",
            "mainWord": "dreißig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690480120/vocimage/chiffres/trente/checkout-1764413__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480128/vocimage/chiffres/trente/number-1383639__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691350201/vocimage/chiffres/trente/zone_30.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690480221/vocimage/chiffres/trente/male-1754711_640.jpg"]
}, {
    "id": 427,
    "name": "trois",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "trois"
        },
        "nl": {
            "article": "",
            "mainWord": "drie"
        },
        "en": {
            "article": "",
            "mainWord": "three"
        },
        "es": {
            "article": "",
            "mainWord": "tres"
        },
        "de": {
            "article": "",
            "mainWord": "drei"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690486167/vocimage/chiffres/trois/teddy-bear-2855982_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690486165/vocimage/chiffres/trois/cat-3266675_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690486171/vocimage/chiffres/trois/daisy-1307330_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455492/vocimage/chiffres/trois/mopeds-3798183__340.jpg"]
}, {
    "id": 428,
    "name": "un",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "un"
        },
        "nl": {
            "article": "",
            "mainWord": "één"
        },
        "en": {
            "article": "",
            "mainWord": "one"
        },
        "es": {
            "article": "",
            "mainWord": "uno"
        },
        "de": {
            "article": "",
            "mainWord": "eins"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690485249/vocimage/chiffres/un/ace-5671671_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455309/vocimage/chiffres/un/number-1-5098366_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455318/vocimage/chiffres/un/finger-3026348_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455328/vocimage/chiffres/un/flower-887443__340.jpg"]
}, {
    "id": 429,
    "name": "vingt",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "vingt"
        },
        "nl": {
            "article": "",
            "mainWord": "twintig"
        },
        "en": {
            "article": "",
            "mainWord": "twenty"
        },
        "es": {
            "article": "",
            "mainWord": "veinte"
        },
        "de": {
            "article": "",
            "mainWord": "zwanzig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690476917/vocimage/chiffres/vingt/tape-measure-218415_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476938/vocimage/chiffres/vingt/drive-1781__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476938/vocimage/chiffres/vingt/shield-3705777__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690476938/vocimage/chiffres/vingt/money-5055579__340.jpg"]
}, {
    "id": 430,
    "name": "zéro",
    "themeId": 5,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "zéro"
        },
        "nl": {
            "article": "",
            "mainWord": "nul"
        },
        "en": {
            "article": "",
            "mainWord": "zero"
        },
        "es": {
            "article": "",
            "mainWord": "cero"
        },
        "de": {
            "article": "",
            "mainWord": "null"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690455276/vocimage/chiffres/zero/roulette-298029__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455285/vocimage/chiffres/zero/frost-1837012__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455286/vocimage/chiffres/zero/dashboard-4349032_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690455286/vocimage/chiffres/zero/gauge-4601454_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}