const words = 
[{
    "id": 1401,
    "name": "Amoureux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Amoureux"
        },
        "nl": {
            "article": "",
            "mainWord": "Verliefd"
        },
        "en": {
            "article": "",
            "mainWord": "In love"
        },
        "es": {
            "article": "",
            "mainWord": "Enamorado"
        },
        "de": {
            "article": "",
            "mainWord": "verliebt"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690734134/vocimage/humeurs/amoureux/balloon-991680_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734134/vocimage/humeurs/amoureux/love-1536226_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734135/vocimage/humeurs/amoureux/birds-5192377_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734262/vocimage/humeurs/amoureux/couple-1845334_640.jpg"]
}, {
    "id": 1402,
    "name": "Calme",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Calme"
        },
        "nl": {
            "article": "",
            "mainWord": "Kalm"
        },
        "en": {
            "article": "",
            "mainWord": "Quiet"
        },
        "es": {
            "article": "",
            "mainWord": "Tranquilo"
        },
        "de": {
            "article": "",
            "mainWord": "ruhig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690734484/vocimage/humeurs/calme/cat-3040345_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734484/vocimage/humeurs/calme/shaman-2837843_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734484/vocimage/humeurs/calme/sunset-2525181_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734291/vocimage/humeurs/calme/meditate-1851165__340.jpg"]
}, {
    "id": 1403,
    "name": "Courageux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Courageux"
        },
        "nl": {
            "article": "",
            "mainWord": "Moedig"
        },
        "en": {
            "article": "",
            "mainWord": "Brave"
        },
        "es": {
            "article": "",
            "mainWord": "Valiente"
        },
        "de": {
            "article": "",
            "mainWord": "mutig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690734970/vocimage/humeurs/courageux/air-ambulance-3970984_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734970/vocimage/humeurs/courageux/playground-6781442_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734970/vocimage/humeurs/courageux/firefighter-2679283_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690734645/vocimage/humeurs/courageux/bungee-jumping-364620_640.jpg"]
}, {
    "id": 1404,
    "name": "Fâché",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Fâché"
        },
        "nl": {
            "article": "",
            "mainWord": "Boos"
        },
        "en": {
            "article": "",
            "mainWord": "Angry"
        },
        "es": {
            "article": "",
            "mainWord": "Enfadado"
        },
        "de": {
            "article": "",
            "mainWord": "böse"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690745487/vocimage/humeurs/fache/cat-1865538__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735095/vocimage/humeurs/fache/woman-975339_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735046/vocimage/humeurs/fache/grimace-4880273__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735030/vocimage/humeurs/fache/meerkat-2223962__340.jpg"]
}, {
    "id": 1405,
    "name": "Farceur",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Farceur"
        },
        "nl": {
            "article": "",
            "mainWord": "Grappig"
        },
        "en": {
            "article": "",
            "mainWord": "Joker"
        },
        "es": {
            "article": "",
            "mainWord": "Bromista"
        },
        "de": {
            "article": "",
            "mainWord": "lustig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690735861/vocimage/humeurs/farceur/nizhny-novgorod-5258677_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735861/vocimage/humeurs/farceur/kid-3498473_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735861/vocimage/humeurs/farceur/wedding-dress-1793905_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690735129/vocimage/humeurs/farceur/joker-3114977__340.jpg"]
}, {
    "id": 1406,
    "name": "Fier",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Fier"
        },
        "nl": {
            "article": "",
            "mainWord": "Trots"
        },
        "en": {
            "article": "",
            "mainWord": "Proud"
        },
        "es": {
            "article": "",
            "mainWord": "Orgulloso"
        },
        "de": {
            "article": "",
            "mainWord": "stolz"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690743140/vocimage/humeurs/fier/fier2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743095/vocimage/humeurs/fier/fier.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743072/vocimage/humeurs/fier/peacock-2363750__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743055/vocimage/humeurs/fier/fier1.jpg"]
}, {
    "id": 1407,
    "name": "Heureux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Heureux"
        },
        "nl": {
            "article": "",
            "mainWord": "Gelukkig"
        },
        "en": {
            "article": "",
            "mainWord": "Happy"
        },
        "es": {
            "article": "",
            "mainWord": "Feliz"
        },
        "de": {
            "article": "",
            "mainWord": "glücklich"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690743251/vocimage/humeurs/heureux/ball-1845546_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743250/vocimage/humeurs/heureux/joy-2483926_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743181/vocimage/humeurs/heureux/children-82272__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743168/vocimage/humeurs/heureux/laugh-3673836_640.jpg"]
}, {
    "id": 1408,
    "name": "Nerveux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Nerveux"
        },
        "nl": {
            "article": "",
            "mainWord": "Nerveus"
        },
        "en": {
            "article": "",
            "mainWord": "Nervous"
        },
        "es": {
            "article": "",
            "mainWord": "Nervioso"
        },
        "de": {
            "article": "",
            "mainWord": "nervös"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690744323/vocimage/humeurs/nerveux/nervosite3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690744193/vocimage/humeurs/nerveux/nervosite2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690744005/vocimage/humeurs/nerveux/nervosite1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690743642/vocimage/humeurs/nerveux/nervosite.jpg"]
}, {
    "id": 1409,
    "name": "Paresseux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Paresseux"
        },
        "nl": {
            "article": "",
            "mainWord": "Lui"
        },
        "en": {
            "article": "",
            "mainWord": "Lazy"
        },
        "es": {
            "article": "",
            "mainWord": "Perezoso"
        },
        "de": {
            "article": "",
            "mainWord": "faul"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690744961/vocimage/humeurs/paresseux/animal-6762401_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690744961/vocimage/humeurs/paresseux/paresseuse.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690744961/vocimage/humeurs/paresseux/cat-2605502_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690744339/vocimage/humeurs/paresseux/break-2447708__340.jpg"]
}, {
    "id": 1410,
    "name": "Peureux",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Peureux"
        },
        "nl": {
            "article": "",
            "mainWord": "Bang"
        },
        "en": {
            "article": "",
            "mainWord": "Fearful"
        },
        "es": {
            "article": "",
            "mainWord": "Miedoso"
        },
        "de": {
            "article": "",
            "mainWord": "ängstlich"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690745426/vocimage/humeurs/peureux/peur2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690745350/vocimage/humeurs/peureux/peur.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690745350/vocimage/humeurs/peureux/little-boy-1635065_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690745349/vocimage/humeurs/peureux/close-up-3497989_640.jpg"]
}, {
    "id": 1411,
    "name": "Râleur",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Râleur"
        },
        "nl": {
            "article": "",
            "mainWord": "Mopperig"
        },
        "en": {
            "article": "",
            "mainWord": "Grouchy"
        },
        "es": {
            "article": "",
            "mainWord": "Gruñón"
        },
        "de": {
            "article": "",
            "mainWord": "mürrisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690745986/vocimage/humeurs/raleur/raleur2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690745985/vocimage/humeurs/raleur/raleur3.png", "https://res.cloudinary.com/chaudard/image/upload/v1690745985/vocimage/humeurs/raleur/raleur1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690745985/vocimage/humeurs/raleur/raleur4.jpg"]
}, {
    "id": 1412,
    "name": "Romantique",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Romantique"
        },
        "nl": {
            "article": "",
            "mainWord": "Romantisch"
        },
        "en": {
            "article": "",
            "mainWord": "Romantic"
        },
        "es": {
            "article": "",
            "mainWord": "Romántico"
        },
        "de": {
            "article": "",
            "mainWord": "romantisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690746309/vocimage/humeurs/romantique/plage_romantique.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746267/vocimage/humeurs/romantique/diner-romantique.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746004/vocimage/humeurs/romantique/wedding-1770860__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746004/vocimage/humeurs/romantique/love-1790142__340.jpg"]
}, {
    "id": 1413,
    "name": "Sensible",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Sensible"
        },
        "nl": {
            "article": "",
            "mainWord": "Gevoelig"
        },
        "en": {
            "article": "",
            "mainWord": "Sensitive"
        },
        "es": {
            "article": "",
            "mainWord": "Sensible"
        },
        "de": {
            "article": "",
            "mainWord": "sensibel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690733895/vocimage/humeurs/sensible/sensible1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690733895/vocimage/humeurs/sensible/sensible4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690733895/vocimage/humeurs/sensible/sensible2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690733895/vocimage/humeurs/sensible/sensible3.jpg"]
}, {
    "id": 1414,
    "name": "Surmené",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Surmené"
        },
        "nl": {
            "article": "",
            "mainWord": "Overwerkt"
        },
        "en": {
            "article": "",
            "mainWord": "Overworked"
        },
        "es": {
            "article": "",
            "mainWord": "Agotado"
        },
        "de": {
            "article": "",
            "mainWord": "uberfordert"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690746615/vocimage/humeurs/surmene/woman-4118058_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746614/vocimage/humeurs/surmene/despaired-2261021_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746340/vocimage/humeurs/surmene/frog-1339892__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746339/vocimage/humeurs/surmene/stress.jpg"]
}, {
    "id": 1415,
    "name": "Timide",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Timide"
        },
        "nl": {
            "article": "",
            "mainWord": "Verlegen"
        },
        "en": {
            "article": "",
            "mainWord": "Shy"
        },
        "es": {
            "article": "",
            "mainWord": "Tímido"
        },
        "de": {
            "article": "",
            "mainWord": "schüchtern"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690747217/vocimage/humeurs/timide/shy-girl-5942197_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690747217/vocimage/humeurs/timide/child-4255453_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690747217/vocimage/humeurs/timide/ai-generated-7742201_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690746637/vocimage/humeurs/timide/boy-603805__340.jpg"]
}, {
    "id": 1416,
    "name": "Triste",
    "themeId": 15,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Triste"
        },
        "nl": {
            "article": "",
            "mainWord": "Triest"
        },
        "en": {
            "article": "",
            "mainWord": "Sad"
        },
        "es": {
            "article": "",
            "mainWord": "Triste"
        },
        "de": {
            "article": "",
            "mainWord": "traurig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690747260/vocimage/humeurs/triste/girl-3421489__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690747246/vocimage/humeurs/triste/doll-1636128__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690747242/vocimage/humeurs/triste/child-sitting-1816400__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690747237/vocimage/humeurs/triste/childrens-eyes-1914519_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}