const words = 
[{
    "id": 1001,
    "name": "athlétisme",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "athlétisme"
        },
        "nl": {
            "article": "De",
            "mainWord": "atletiek"
        },
        "en": {
            "article": "",
            "mainWord": "Athletics"
        },
        "es": {
            "article": "",
            "mainWord": "Atletismo"
        },
        "de": {
            "article": "Die",
            "mainWord": "Leischtathletik"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815141/vocimage/loisirs/athletisme/athlete-1840437_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815141/vocimage/loisirs/athletisme/athletics-649650_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815026/vocimage/loisirs/athletisme/sport-659224__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815026/vocimage/loisirs/athletisme/athletics-649636__340.jpg"]
}, {
    "id": 1002,
    "name": "basket",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "basket"
        },
        "nl": {
            "article": "De",
            "mainWord": "basketbal"
        },
        "en": {
            "article": "",
            "mainWord": "Basketball"
        },
        "es": {
            "article": "El",
            "mainWord": "baloncesto"
        },
        "de": {
            "article": "",
            "mainWord": "Basketball"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815288/vocimage/loisirs/basket/basketball-1544370_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815236/vocimage/loisirs/basket/haikou-city-93546_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815236/vocimage/loisirs/basket/basketball-102377__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815236/vocimage/loisirs/basket/man-1837119_640.jpg"]
}, {
    "id": 1003,
    "name": "boxe",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "boxe"
        },
        "nl": {
            "article": "",
            "mainWord": "Boksen"
        },
        "en": {
            "article": "",
            "mainWord": "Boxing"
        },
        "es": {
            "article": "El",
            "mainWord": "boxeo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Boxen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815001/vocimage/loisirs/boxe/ai-generated-8028020_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814918/vocimage/loisirs/boxe/boxing-546143__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814918/vocimage/loisirs/boxe/box-3849936__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814918/vocimage/loisirs/boxe/girl-3556557__340.jpg"]
}, {
    "id": 1004,
    "name": "Cuisiner",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Cuisiner"
        },
        "nl": {
            "article": "",
            "mainWord": "Koken"
        },
        "en": {
            "article": "",
            "mainWord": "Cooking"
        },
        "es": {
            "article": "",
            "mainWord": "Cocinar"
        },
        "de": {
            "article": "",
            "mainWord": "Kochen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815724/vocimage/loisirs/cuisiner/pot-544071_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815724/vocimage/loisirs/cuisiner/restaurant-4055826_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815723/vocimage/loisirs/cuisiner/spaghetti-6639970_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815723/vocimage/loisirs/cuisiner/chef-6004036_640.jpg"]
}, {
    "id": 1005,
    "name": "cyclisme",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cyclisme"
        },
        "nl": {
            "article": "Het",
            "mainWord": "wielrennen"
        },
        "en": {
            "article": "",
            "mainWord": "Cycling"
        },
        "es": {
            "article": "El",
            "mainWord": "ciclismo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Radsport"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815779/vocimage/loisirs/cyclisme/bicycle-397960_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814845/vocimage/loisirs/cyclisme/cycling-1814362__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814844/vocimage/loisirs/cyclisme/snow-3066167__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814844/vocimage/loisirs/cyclisme/bike-775799__340.jpg"]
}, {
    "id": 1006,
    "name": "pêche",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "pêche"
        },
        "nl": {
            "article": "",
            "mainWord": "Vissen"
        },
        "en": {
            "article": "",
            "mainWord": "Fishing"
        },
        "es": {
            "article": "La",
            "mainWord": "pesca"
        },
        "de": {
            "article": "Das",
            "mainWord": "Angel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690814830/vocimage/loisirs/peche/boat-207129_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814830/vocimage/loisirs/peche/fishermen-2983615_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814830/vocimage/loisirs/peche/boy-909552_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814825/vocimage/loisirs/peche/fishing-1572408__340.jpg"]
}, {
    "id": 1007,
    "name": "football",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "football"
        },
        "nl": {
            "article": "De",
            "mainWord": "voetbal"
        },
        "en": {
            "article": "",
            "mainWord": "Football"
        },
        "es": {
            "article": "El",
            "mainWord": "fútbol"
        },
        "de": {
            "article": "",
            "mainWord": "Fussball"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690814685/vocimage/loisirs/football/soccer-7392844_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814685/vocimage/loisirs/football/soccer-1457988_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814685/vocimage/loisirs/football/architecture-1853520_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814630/vocimage/loisirs/football/football-3471307__340.jpg"]
}, {
    "id": 1008,
    "name": "jardinage",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jardinage"
        },
        "nl": {
            "article": "Het",
            "mainWord": "tuinieren"
        },
        "en": {
            "article": "",
            "mainWord": "Gardening"
        },
        "es": {
            "article": "La",
            "mainWord": "jardinería"
        },
        "de": {
            "article": "Die",
            "mainWord": "Gartenarbeit"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690814613/vocimage/loisirs/jardinage/vegetables-790023_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814613/vocimage/loisirs/jardinage/plants-6520443_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814538/vocimage/loisirs/jardinage/planting-780736__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814538/vocimage/loisirs/jardinage/planting-1898946__340.jpg"]
}, {
    "id": 1009,
    "name": "gymnastique",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "gymnastique"
        },
        "nl": {
            "article": "",
            "mainWord": "Turnen"
        },
        "en": {
            "article": "",
            "mainWord": "Gymnastics"
        },
        "es": {
            "article": "La",
            "mainWord": "gimnasia"
        },
        "de": {
            "article": "Das",
            "mainWord": "Turnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690816338/vocimage/loisirs/gymnastique/gymnastics-90936_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816338/vocimage/loisirs/gymnastique/competition-1184392_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816338/vocimage/loisirs/gymnastique/gymnastics-89611_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816337/vocimage/loisirs/gymnastique/girl-7711441_640.jpg"]
}, {
    "id": 1010,
    "name": "karaté",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "karaté"
        },
        "nl": {
            "article": "De",
            "mainWord": "karate"
        },
        "en": {
            "article": "",
            "mainWord": "Karate"
        },
        "es": {
            "article": "El",
            "mainWord": "karate"
        },
        "de": {
            "article": "Das",
            "mainWord": "Karate"
        }
    },
    "images": []
}, {
    "id": 1011,
    "name": "musique",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "musique"
        },
        "nl": {
            "article": "De",
            "mainWord": "muziek"
        },
        "en": {
            "article": "",
            "mainWord": "Music"
        },
        "es": {
            "article": "La",
            "mainWord": "música"
        },
        "de": {
            "article": "Die",
            "mainWord": "Musik"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690814234/vocimage/loisirs/musique/saxophone-2637004_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814190/vocimage/loisirs/musique/bass-guitar-1841186_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814111/vocimage/loisirs/musique/piano-1655558__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814111/vocimage/loisirs/musique/record-player-4994400__340.jpg"]
}, {
    "id": 1012,
    "name": "photographie",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "photographie"
        },
        "nl": {
            "article": "De",
            "mainWord": "fotografie"
        },
        "en": {
            "article": "",
            "mainWord": "Photography"
        },
        "es": {
            "article": "La",
            "mainWord": "fotografía"
        },
        "de": {
            "article": "Die",
            "mainWord": "Fotografie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690814095/vocimage/loisirs/photographie/photography-1850469_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814095/vocimage/loisirs/photographie/night-1927265_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814095/vocimage/loisirs/photographie/keyboard-338505_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690814086/vocimage/loisirs/photographie/camera-711040__340.jpg"]
}, {
    "id": 1013,
    "name": "Skier",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Skier"
        },
        "nl": {
            "article": "",
            "mainWord": "Skiën"
        },
        "en": {
            "article": "",
            "mainWord": "Skiing"
        },
        "es": {
            "article": "",
            "mainWord": "Esquiar"
        },
        "de": {
            "article": "",
            "mainWord": "Skifahren"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813997/vocimage/loisirs/ski/ski-810500_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813942/vocimage/loisirs/ski/skiing-1723857__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813942/vocimage/loisirs/ski/gondola-1949683__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813942/vocimage/loisirs/ski/boy-1835416__340.jpg"]
}, {
    "id": 1014,
    "name": "natation",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "natation"
        },
        "nl": {
            "article": "Het",
            "mainWord": "zwemmen"
        },
        "en": {
            "article": "",
            "mainWord": "Swimming"
        },
        "es": {
            "article": "La",
            "mainWord": "natación"
        },
        "de": {
            "article": "Das",
            "mainWord": "Schwimmen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813923/vocimage/loisirs/natation/young-swimmer-2501548_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813828/vocimage/loisirs/natation/swimming-pool-504780__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813828/vocimage/loisirs/natation/young-swimmer-2494906__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813828/vocimage/loisirs/natation/swimming-78112__340.jpg"]
}, {
    "id": 1015,
    "name": "cinéma",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cinéma"
        },
        "nl": {
            "article": "De",
            "mainWord": "bioscoop"
        },
        "en": {
            "article": "The",
            "mainWord": "cinema"
        },
        "es": {
            "article": "El",
            "mainWord": "cine"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kino"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813807/vocimage/loisirs/cinema/universal-studios-1640516_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813773/vocimage/loisirs/cinema/popcorn-1085072__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813773/vocimage/loisirs/cinema/analogue-2974647__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813773/vocimage/loisirs/cinema/cinema-1241422__340.jpg"]
}, {
    "id": 1016,
    "name": "danse",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "danse"
        },
        "nl": {
            "article": "",
            "mainWord": "Dansen"
        },
        "en": {
            "article": "The",
            "mainWord": "dance"
        },
        "es": {
            "article": "La",
            "mainWord": "danza"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tanzen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813752/vocimage/loisirs/danse/girl-3691314_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813752/vocimage/loisirs/danse/girl-1258727_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813677/vocimage/loisirs/danse/ballet-2789416__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813677/vocimage/loisirs/danse/dance-5054944__340.jpg"]
}, {
    "id": 1017,
    "name": "équitation",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "équitation"
        },
        "nl": {
            "article": "",
            "mainWord": "Paardrijden"
        },
        "en": {
            "article": "",
            "mainWord": "Horse riding"
        },
        "es": {
            "article": "La",
            "mainWord": "equitación"
        },
        "de": {
            "article": "Der",
            "mainWord": "Pferdesport"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813666/vocimage/loisirs/equitation/the-horse-4268618__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813666/vocimage/loisirs/equitation/stable-3712870__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813666/vocimage/loisirs/equitation/horse-1808727__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813666/vocimage/loisirs/equitation/horse-721136__340.jpg"]
}, {
    "id": 1018,
    "name": "judo",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "judo"
        },
        "nl": {
            "article": "Het",
            "mainWord": "judo"
        },
        "en": {
            "article": "The",
            "mainWord": "judo"
        },
        "es": {
            "article": "El",
            "mainWord": "judo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Judo"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813649/vocimage/loisirs/judo/judo-4454836_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813649/vocimage/loisirs/judo/judo-2121655_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813587/vocimage/loisirs/judo/judo-4454835__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813587/vocimage/loisirs/judo/brazilian-jiu-jitsu-2957075__340.jpg"]
}, {
    "id": 1019,
    "name": "tennis de table",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tennis de table"
        },
        "nl": {
            "article": "Het",
            "mainWord": "tafeltennis"
        },
        "en": {
            "article": "The",
            "mainWord": "table tennis"
        },
        "es": {
            "article": "El",
            "mainWord": "tenis de mesa"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tischtennis"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813572/vocimage/loisirs/tennis%20de%20table/table-tennis-4934489_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813572/vocimage/loisirs/tennis%20de%20table/sports-3097621_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813487/vocimage/loisirs/tennis%20de%20table/table-tennis-1428052__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813487/vocimage/loisirs/tennis%20de%20table/table-tennis-1208384__340.jpg"]
}, {
    "id": 1020,
    "name": "tennis",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tennis"
        },
        "nl": {
            "article": "De",
            "mainWord": "tennis"
        },
        "en": {
            "article": "The",
            "mainWord": "tennis"
        },
        "es": {
            "article": "El",
            "mainWord": "tenis"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tennis"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813460/vocimage/loisirs/tennis/tennis-5018589_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813425/vocimage/loisirs/tennis/tennis-court-1671852__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813425/vocimage/loisirs/tennis/tennis-1381230__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813425/vocimage/loisirs/tennis/tennis-614183__340.jpg"]
}, {
    "id": 1021,
    "name": "jeu vidéo",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jeu vidéo"
        },
        "nl": {
            "article": "De",
            "mainWord": "spelcomputer"
        },
        "en": {
            "article": "The",
            "mainWord": "video game"
        },
        "es": {
            "article": "El",
            "mainWord": "videojuego"
        },
        "de": {
            "article": "Das",
            "mainWord": "Videospiel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813405/vocimage/loisirs/jeu%20video/video-game-6578106_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813405/vocimage/loisirs/jeu%20video/girls-playing-1967833_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813405/vocimage/loisirs/jeu%20video/joysticks-6603119_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813249/vocimage/loisirs/jeu%20video/amiga-4321211__340.jpg"]
}, {
    "id": 1022,
    "name": "Colorier",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Colorier"
        },
        "nl": {
            "article": "",
            "mainWord": "Kleuren"
        },
        "en": {
            "article": "To",
            "mainWord": "colour"
        },
        "es": {
            "article": "",
            "mainWord": "Colorear"
        },
        "de": {
            "article": "",
            "mainWord": "färben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813230/vocimage/loisirs/colorier/coloring-book-for-adults-1396827__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813230/vocimage/loisirs/colorier/coloring-book-for-adults-1396833__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813230/vocimage/loisirs/colorier/paint-2108434__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813230/vocimage/loisirs/colorier/oekaki-2009817__340.jpg"]
}, {
    "id": 1023,
    "name": "Dessiner",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Dessiner"
        },
        "nl": {
            "article": "",
            "mainWord": "Tekenen"
        },
        "en": {
            "article": "To",
            "mainWord": "draw"
        },
        "es": {
            "article": "",
            "mainWord": "Dibujar"
        },
        "de": {
            "article": "",
            "mainWord": "zeichnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690816385/vocimage/loisirs/dessiner/street-chalk-1761052_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816385/vocimage/loisirs/dessiner/fashion-2939985_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816385/vocimage/loisirs/dessiner/art-256895_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690816385/vocimage/loisirs/dessiner/charcoal-drawing-1558900_640.jpg"]
}, {
    "id": 1024,
    "name": "Peindre",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Peindre"
        },
        "nl": {
            "article": "",
            "mainWord": "Schilderen"
        },
        "en": {
            "article": "To",
            "mainWord": "paint"
        },
        "es": {
            "article": "",
            "mainWord": "Pintar"
        },
        "de": {
            "article": "",
            "mainWord": "malen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813212/vocimage/loisirs/peindre/girl-3194977_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813178/vocimage/loisirs/peindre/coloring-4384244__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813178/vocimage/loisirs/peindre/painter-2504395__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813178/vocimage/loisirs/peindre/paint-878820__340.jpg"]
}, {
    "id": 1025,
    "name": "Jouer",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Jouer"
        },
        "nl": {
            "article": "",
            "mainWord": "Spelen"
        },
        "en": {
            "article": "To",
            "mainWord": "play"
        },
        "es": {
            "article": "",
            "mainWord": "Jugar"
        },
        "de": {
            "article": "",
            "mainWord": "spielen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690813161/vocimage/loisirs/jouer/child-1864718_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813118/vocimage/loisirs/jouer/playground-99509__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813115/vocimage/loisirs/jouer/play-614898__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690813104/vocimage/loisirs/jouer/baby-1842293__340.jpg"]
}, {
    "id": 1026,
    "name": "Lire",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Lire"
        },
        "nl": {
            "article": "",
            "mainWord": "Lezen"
        },
        "en": {
            "article": "To",
            "mainWord": "read"
        },
        "es": {
            "article": "",
            "mainWord": "Leer"
        },
        "de": {
            "article": "",
            "mainWord": "lesen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690812788/vocimage/loisirs/lire/child-857021_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812788/vocimage/loisirs/lire/man-1853961_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812681/vocimage/loisirs/lire/book-3531412__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812681/vocimage/loisirs/lire/people-2572105__340.jpg"]
}, {
    "id": 1027,
    "name": "Courir",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Courir"
        },
        "nl": {
            "article": "",
            "mainWord": "Rennen"
        },
        "en": {
            "article": "To",
            "mainWord": "run"
        },
        "es": {
            "article": "",
            "mainWord": "Correr"
        },
        "de": {
            "article": "",
            "mainWord": "laufen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690815480/vocimage/loisirs/courir/running-4782722_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815480/vocimage/loisirs/courir/jogging-4910487_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815480/vocimage/loisirs/courir/running-1944798_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690815480/vocimage/loisirs/courir/child-817371_640.jpg"]
}, {
    "id": 1028,
    "name": "volleyball",
    "themeId": 11,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "volleyball"
        },
        "nl": {
            "article": "Het",
            "mainWord": "volleybal"
        },
        "en": {
            "article": "",
            "mainWord": "Volleyball"
        },
        "es": {
            "article": "El",
            "mainWord": "voleibol"
        },
        "de": {
            "article": "",
            "mainWord": "Volleyball"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690812660/vocimage/loisirs/volleyball/volleyball-90896__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812660/vocimage/loisirs/volleyball/ball-1930194__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812660/vocimage/loisirs/volleyball/volleyball-1617874__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690812660/vocimage/loisirs/volleyball/volleyball-520083__340.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}