import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';

class Faqcollapse extends React.Component {

    renderIcon = () => {
        switch (this.props.sizeicon) {
            case 1:                
                return <FontAwesomeIcon icon={faQuestionCircle} className="app-main-color" data-toggle="collapse" data-target="#faq"/>
            case 2:
                return <FontAwesomeIcon icon={faQuestionCircle} size="2x" className="app-main-color" data-toggle="collapse" data-target="#faq"/>
            case 3:
                return <FontAwesomeIcon icon={faQuestionCircle} size="3x" className="app-main-color" data-toggle="collapse" data-target="#faq"/>
            default:
                return <FontAwesomeIcon icon={faQuestionCircle} className="app-main-color" data-toggle="collapse" data-target="#faq"/>
        }
    }

    render() {
        return(
            <div>
                <div className="flex-container">
                    {this.renderIcon()}
                </div>
                <div id="faq" className="collapse">

                    <div className="faq-item">
                        <div className="question">
                            A qui est destiné le jeu ?
                        </div>
                        <div className="answer">
                            A toute personne désireuse de revoir ou apprendre, en s'amusant, le vocabulaire élémentaire d'une langue. <br/>
                            Le jeu permet de choisir parmi les 4 langues suivantes : 
                            <ul>
                                <li>Français</li>
                                <li>Néerlandais</li>
                                <li>Anglais</li>
                                <li>Espagnol</li>
                                <li>Allemand</li>
                            </ul>
                            Il propose une multitude de thèmes très variés.
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Comment jouer ?
                        </div>
                        <div className="answer">
                            <ol>
                                <li>
                                    Le joueur choisit la langue dans laquelle il souhaite deviner les mots. <br/>
                                    <i>Le jeu reste en français; seuls les mots à deviner sont dans la langue choisie.</i>
                                </li>
                                <li>
                                    Le joueur choisit un ou plusieurs thèmes. <br/>
                                    <i>
                                        S'il le désire, il peut tous les sélectionner en un clic, en utilisant le bouton "Tous".
                                    </i>
                                </li>
                                <li>
                                    Le joueur choisit son niveau. <br/>
                                    <i>
                                        Plus le niveau augmente, plus le nombre de lettres augmente. <br/>
                                        Au niveau 0, aucune autre lettre n'est ajoutée au mot à deviner ; au niveau 10, ce sont 10 lettres aléatoires qui sont ajoutées.
                                    </i>
                                </li>
                                <li>
                                    Le joueur choisit le nombre de mots à deviner. <br/>
                                    <i>Parmi ces mots se trouveront un mélange aléatoire de mots contenus dans les différents thèmes choisis.</i>
                                </li>
                                <li>
                                    Le joueur démarre le jeu. <br/>
                                    <i>Il clique sur le bouton "Jouer".</i>
                                </li>
                                <li>
                                    Le joueur cherche le mot représenté par les images. <br/>
                                    <i>
                                        Pour chaque mot, 4 images lui sont proposées. <br/>
                                        Il clique sur les lettres proposées pour reconstituer le mot. <br/>
                                        S'il se trompe de lettre, il clique la lettre mal placée pour la remettre à sa position d'origine. <br/>                                    
                                    </i>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Comment corriger quand le mot est incorrect ?
                        </div>
                        <div className="answer">
                            2 possibilités : <br/>
                            <ol>
                                <li>
                                    Cliquez sur le bouton 'Effacer' pour supprimer toutes les lettres en une fois. <br/>
                                    <i>NB : Ce bouton n'apparaît que si toutes les cases du mot sont remplies, mais que le mot proposé est incorrect.</i>
                                </li>
                                <li>
                                    Cliquez la lettre mal positionnée pour la retirer du mot; elle revient alors à sa position initiale. <br/>
                                    Sélectionnez ensuite une autre lettre.
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Que signifient les icônes ?
                        </div>
                        <div className="answer">
                            <FontAwesomeIcon icon={faHatWizard}/> : nombre de chapeaux magiques gagnés. (au départ, 40 chapeaux sont à la disposition du joueur.) <br/>
                            <FontAwesomeIcon icon={faMagic} className="app-main-color"/> : baguette magique permettant de découvrir une lettre. <br/>
                            <FontAwesomeIcon icon={faFlagCheckered}/> : nombre de mots restants à trouver. <br/>
                            <FontAwesomeIcon icon={faPauseCircle} className="app-main-color"/> : mise en pause du jeu. <br/> 
                            <FontAwesomeIcon icon={faQuestionCircle} className="app-main-color"/> : FAQ
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Comment se servir des <FontAwesomeIcon icon={faHatWizard}/> ?
                        </div>
                        <div className="answer">
                            Lorsque le joueur trouve un mot, il reçoit 3 <FontAwesomeIcon icon={faHatWizard}/>. <br/>
                            Si le joueur a besoin d'aide, il peut alors cliquer sur <FontAwesomeIcon icon={faMagic} className="app-main-color"/> ; la découverte d'une lettre lui coûtera 15 <FontAwesomeIcon icon={faHatWizard}/>.
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Comment arrêter le jeu ?
                        </div>
                        <div className="answer">
                            En bas à gauche se trouve le bouton "Choix / Pause". <br/>
                            Cliquez dessus afin de faire apparaître, juste en-dessus, les choix de jeu et une icône pour la mise en pause du jeu. <br/>
                            Lors de l'arrêt du jeu (mise en pause), vous ne perdez pas votre progression; aucun chapeau magique n'est perdu.
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="separator"></div>
                    </div>

                    <div className="faq-item">
                        <div className="question">
                            Le jeu est-il gratuit ?
                        </div>
                        <div className="answer">
                            Oui, ce jeu est totalement gratuit.
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Faqcollapse;