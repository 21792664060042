import React, { Component } from 'react';
import { connect } from 'react-redux';
// import UIfx from 'uifx';
// import letterMp3 from '../../data/sounds/letter.mp3'; // letter-pop.mp3

class Letter extends Component {

    constructor(props) {
        super(props);
        this.touchedScreen = false;
    }

    handleCommonClick = () => {
        // const song = new UIfx(
        //     letterMp3,
        //     {
        //       volume: 0.05 // number between 0.0 ~ 1.0
        //     }
        // );
        // song.play();            
        this.props.clickFunction(this.props.letter);
    }

    isClickable = () => {
        const checked = (!this.props.game.found && this.props.letter.visible && !this.props.letter.locked)
        return checked;
    }

    handleClick = () => {
        if (!this.touchedScreen && this.isClickable()) {
            this.handleCommonClick();
        }
        this.touchedScreen = false;
    }
    
    handleTouchStart = (event) => {
        if (this.isClickable()) {
            this.touchedScreen = true;
            this.handleCommonClick();
        }
    }

    uppercaseLetter = (letter) => {
        let result = letter.toUpperCase();
        if (letter == 'ß') {
            result = 'ẞ'
        }
        return result;
    }

    renderLetter = () => {
        if (this.props.letter.visible) {
            return this.uppercaseLetter(this.props.letter.letter)
        }
        return ''
    }

    renderClass = () => {
        let result = 'letter';
        if (this.props.letter.afterWhiteSpace) {
            result += ' after-white-space';
        }
        if (this.props.letter.locked) {
            result += ' locked';
        }
        return result;
    }

    renderClassWhiteSpace = () => {
        let result = '';
        if (this.props.letter.afterWhiteSpace) {
            result = 'mark-white-space'
        }
        return result;
    }

    render() {
        return(
            <div className={this.renderClass()} onMouseDown={this.handleClick} onTouchStart={this.handleTouchStart}>
                {this.renderLetter()}
                <div className={this.renderClassWhiteSpace()}></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {game: state.game}
}

export default connect(mapStateToProps) (Letter);