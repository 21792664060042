export const LOAD_GAME = 'LOAD_GAME';
export const REMOVE_PLAYER_WORD = 'REMOVE_PLAYER_WORD';
export const NEXT_WORD = 'NEXT_WORD';
export const SET_FINISHED = 'SET_FINISHED';
import {randomItem, shuffle, shuffleLetters, stringWithoutWhiteSpace} from '../../helpers/math';
import {translatedWord} from '../../helpers/translations';

const initializeLetters = (word, language, level) => {
    const alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    
    let wordLetters = translatedWord(word, language).split('');

    const wordToFind = stringWithoutWhiteSpace(wordLetters);

    const playerLetters = [];
    let whiteSpace = false;
    let position = -1;
    wordLetters.forEach((letter) => {
        if (letter === ' ') {
            whiteSpace = true;
        } else {
            position += 1;
            playerLetters.push({letter: '', position: position + 10000 , visible: false, afterWhiteSpace: whiteSpace, locked: false});
            whiteSpace = false;
        }
    });
           
    const maxWordLetters = wordLetters.length + level;
    while (wordLetters.length < maxWordLetters) {
        wordLetters.push(randomItem(alphabet))
    }

    shuffleLetters(wordLetters, wordToFind);
    
    const possibleLetters = [];
    position = -1;
    wordLetters.forEach((letter) => {
        if (letter != ' ') {
            position += 1;                
            possibleLetters.push({letter, position: position, visible: true, afterWhiteSpace: false, locked: false});
        }
    });
    return [playerLetters, possibleLetters, wordToFind]
}

export function loadGame(words, themesids, maxwords, language, level) {
    // compute wordIds
    let wordIds = [];
    words.forEach(word => {
        if (themesids.includes(word.themeId)) {
            wordIds.push(word.id);
        }           
    })
    if (maxwords > 0) {  // maxwords = 0 in Test.jsx - componentWillMount
        shuffle(wordIds);
        if (wordIds.length >= maxwords) {            
            wordIds = wordIds.slice(0, maxwords)
        }
    }

    // compute word
    const testApp = false;
    let wordId = 0;
    if (testApp == true) {
        wordId = wordIds[0];
    } else {
        wordId = randomItem(wordIds);
    }
    const word = words.find(w => w.id === wordId)

    const letters = initializeLetters(word, language, level);
    const playerLetters = letters[0];
    const possibleLetters = letters[1];
    const wordToFind = letters[2];

    const images = [];
    const found = false;
    const finished = false;
    return {
        type: LOAD_GAME,
        payload: {wordIds, word, images, possibleLetters, playerLetters, wordToFind, found, finished}
    }    
}


export function RemovePlayerWord(possibleLetters, playerLetters) {
    let newPlayerLetters = [...playerLetters];
    let newPossibleLetters = [...possibleLetters];

    newPossibleLetters.forEach(letter => {
        if (!letter.locked) {            
            letter.visible = true
        }
    })

    newPlayerLetters.forEach((letter, position) => {
        if (!letter.locked) {
            letter.letter = '';
            letter.visible = false;
            letter.position = position + 10000;
        }
    })
    return {
        type: REMOVE_PLAYER_WORD,
        payload: {newPossibleLetters, newPlayerLetters}
    }    
}

export function nextWord(word, wordIds, words, language, level) {
    const newWordIds = wordIds.filter(id => id !== word.id);

    const testApp = false;
    let newWordId = 0;
    if (testApp == true) {
        newWordId = newWordIds[0];
    } else {
        newWordId = randomItem(newWordIds);
    }
    const newWord = words.find(w => w.id === newWordId)

    const letters = initializeLetters(newWord, language, level);
    const newPlayerLetters = letters[0];
    const newPossibleLetters = letters[1];
    const newWordToFind = letters[2];

    const newImages = [];
    const newFound = false;
    return {
        type: NEXT_WORD,
        payload: {newWordIds, newWord, newImages, newFound, newPossibleLetters, newPlayerLetters, newWordToFind}
    }    
}

export function setFinished(value) {
    return {
        type: SET_FINISHED,
        payload: value
    }
}