const words = 
[{
    "id": 601,
    "name": "Année",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Année"
        },
        "nl": {
            "article": "Het",
            "mainWord": "jaar"
        },
        "en": {
            "article": "A",
            "mainWord": "year"
        },
        "es": {
            "article": "El",
            "mainWord": "año"
        },
        "de": {
            "article": "Das",
            "mainWord": "Jahr"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690395355/vocimage/calendrier/annee/clock-3837039_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394729/vocimage/calendrier/annee/Cambio-2022-2023.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394560/vocimage/calendrier/annee/agenda-2923057_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394559/vocimage/calendrier/annee/annee.jpg"]
}, {
    "id": 602,
    "name": "Août",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Août"
        },
        "nl": {
            "article": "",
            "mainWord": "Augustus"
        },
        "en": {
            "article": "",
            "mainWord": "August"
        },
        "es": {
            "article": "",
            "mainWord": "Agosto"
        },
        "de": {
            "article": "",
            "mainWord": "August"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690386182/vocimage/calendrier/aout/boy-1822621_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386182/vocimage/calendrier/aout/sky-3027856_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385592/vocimage/calendrier/aout/harvest-2644382_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385592/vocimage/calendrier/aout/fantasy-3502188_640.jpg"]
}, {
    "id": 603,
    "name": "Avril",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Avril"
        },
        "nl": {
            "article": "",
            "mainWord": "April"
        },
        "en": {
            "article": "",
            "mainWord": "April"
        },
        "es": {
            "article": "",
            "mainWord": "Abril"
        },
        "de": {
            "article": "",
            "mainWord": "April"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690384152/vocimage/calendrier/avril/pigeons-50005_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383945/vocimage/calendrier/avril/cherry-blossom-6509_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383945/vocimage/calendrier/avril/poisson.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383945/vocimage/calendrier/avril/girl-7914279_640.jpg"]
}, {
    "id": 604,
    "name": "Décembre",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Décembre"
        },
        "nl": {
            "article": "",
            "mainWord": "December"
        },
        "en": {
            "article": "",
            "mainWord": "December"
        },
        "es": {
            "article": "",
            "mainWord": "Diciembre"
        },
        "de": {
            "article": "",
            "mainWord": "Dezember"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690386935/vocimage/calendrier/decembre/cup-1891446_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386935/vocimage/calendrier/decembre/illuminated-1479168_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386935/vocimage/calendrier/decembre/deer-1940367_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386935/vocimage/calendrier/decembre/christmas-6895856_640.jpg"]
}, {
    "id": 605,
    "name": "Dimanche",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Dimanche"
        },
        "nl": {
            "article": "",
            "mainWord": "Zondag"
        },
        "en": {
            "article": "",
            "mainWord": "Sunday"
        },
        "es": {
            "article": "",
            "mainWord": "Domingo"
        },
        "de": {
            "article": "",
            "mainWord": "Sonntag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691353364/vocimage/calendrier/dimanche/sun7.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690381735/vocimage/calendrier/dimanche/soleil.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690381735/vocimage/calendrier/dimanche/bed-3804161_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690381985/vocimage/calendrier/dimanche/Calendar.jpg"]
}, {
    "id": 606,
    "name": "Février",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Février"
        },
        "nl": {
            "article": "",
            "mainWord": "Februari"
        },
        "en": {
            "article": "",
            "mainWord": "February"
        },
        "es": {
            "article": "",
            "mainWord": "Febrero"
        },
        "de": {
            "article": "",
            "mainWord": "Februar"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690382541/vocimage/calendrier/fevrier/daffodil-4872717_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382821/vocimage/calendrier/fevrier/bissextile.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382821/vocimage/calendrier/fevrier/pancakes-2020863_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382957/vocimage/calendrier/fevrier/i-beg-your-pardon-1272744_640.jpg"]
}, {
    "id": 607,
    "name": "Janvier",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Janvier"
        },
        "nl": {
            "article": "",
            "mainWord": "Januari"
        },
        "en": {
            "article": "",
            "mainWord": "January"
        },
        "es": {
            "article": "",
            "mainWord": "Enero"
        },
        "de": {
            "article": "",
            "mainWord": "Januar"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690382373/vocimage/calendrier/janvier/stars-6868012_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382373/vocimage/calendrier/janvier/sylvester-3891889_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382373/vocimage/calendrier/janvier/flower-3119657__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690382373/vocimage/calendrier/janvier/winter-1367153_640.jpg"]
}, {
    "id": 608,
    "name": "Jeudi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Jeudi"
        },
        "nl": {
            "article": "",
            "mainWord": "Donderdag"
        },
        "en": {
            "article": "",
            "mainWord": "Thursday"
        },
        "es": {
            "article": "",
            "mainWord": "Jueves"
        },
        "de": {
            "article": "",
            "mainWord": "Donnerstag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690379698/vocimage/calendrier/jeudi/Jeudi.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690379698/vocimage/calendrier/jeudi/Day-4.png", "https://res.cloudinary.com/chaudard/image/upload/v1690379698/vocimage/calendrier/jeudi/fat-thursday-4123506_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690379731/vocimage/calendrier/jeudi/Jeudi_1.jpg"]
}, {
    "id": 609,
    "name": "Jour",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Jour"
        },
        "nl": {
            "article": "De",
            "mainWord": "dag"
        },
        "en": {
            "article": "A",
            "mainWord": "day"
        },
        "es": {
            "article": "El",
            "mainWord": "día"
        },
        "de": {
            "article": "Der",
            "mainWord": "Tag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690392870/vocimage/calendrier/jour/jour.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690393330/vocimage/calendrier/jour/planetes_jours2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690393330/vocimage/calendrier/jour/jours.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690393538/vocimage/calendrier/jour/calendar-487695_640.jpg"]
}, {
    "id": 610,
    "name": "Juillet",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Juillet"
        },
        "nl": {
            "article": "",
            "mainWord": "Juli"
        },
        "en": {
            "article": "",
            "mainWord": "July"
        },
        "es": {
            "article": "",
            "mainWord": "Julio"
        },
        "de": {
            "article": "",
            "mainWord": "Juli"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690385305/vocimage/calendrier/juillet/military-parade-7321300_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385305/vocimage/calendrier/juillet/indiana-dunes-state-park-1848559_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385305/vocimage/calendrier/juillet/fireworks-1759_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385306/vocimage/calendrier/juillet/juillet.jpg"]
}, {
    "id": 611,
    "name": "Juin",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Juin"
        },
        "nl": {
            "article": "",
            "mainWord": "Juni"
        },
        "en": {
            "article": "",
            "mainWord": "June"
        },
        "es": {
            "article": "",
            "mainWord": "Junio"
        },
        "de": {
            "article": "",
            "mainWord": "Juni"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690385011/vocimage/calendrier/juin/exam.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385011/vocimage/calendrier/juin/juin.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385011/vocimage/calendrier/juin/greeting-card-3697683_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690385011/vocimage/calendrier/juin/birds-6555575_640.jpg"]
}, {
    "id": 612,
    "name": "automne",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "automne"
        },
        "nl": {
            "article": "De",
            "mainWord": "herfst"
        },
        "en": {
            "article": "",
            "mainWord": "Autumn"
        },
        "es": {
            "article": "El",
            "mainWord": "otoño"
        },
        "de": {
            "article": "Der",
            "mainWord": "Herbst"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690392603/vocimage/calendrier/automne/benches-560435_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392600/vocimage/calendrier/automne/child-1051288_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392600/vocimage/calendrier/automne/mushroom-3775725_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392600/vocimage/calendrier/automne/road-1072821_640.jpg"]
}, {
    "id": 613,
    "name": "été",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "été"
        },
        "nl": {
            "article": "De",
            "mainWord": "zomer"
        },
        "en": {
            "article": "",
            "mainWord": "Summer"
        },
        "es": {
            "article": "El",
            "mainWord": "verano"
        },
        "de": {
            "article": "Der",
            "mainWord": "Sommer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690392348/vocimage/calendrier/ete/luggage-1149289_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392348/vocimage/calendrier/ete/woman-1509956_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392348/vocimage/calendrier/ete/pink-lemonade-795029_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392348/vocimage/calendrier/ete/ice-cream-1274894_1280.jpg"]
}, {
    "id": 614,
    "name": "hiver",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "hiver"
        },
        "nl": {
            "article": "De",
            "mainWord": "winter"
        },
        "en": {
            "article": "",
            "mainWord": "Winter"
        },
        "es": {
            "article": "El",
            "mainWord": "invierno"
        },
        "de": {
            "article": "Der",
            "mainWord": "Winter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690392061/vocimage/calendrier/hiver/winter-7633193_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392061/vocimage/calendrier/hiver/tree-6835828_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392061/vocimage/calendrier/hiver/person-1145525_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392062/vocimage/calendrier/hiver/bridge-1458513_640.jpg"]
}, {
    "id": 615,
    "name": "printemps",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "printemps"
        },
        "nl": {
            "article": "De",
            "mainWord": "lente"
        },
        "en": {
            "article": "",
            "mainWord": "Spring"
        },
        "es": {
            "article": "La",
            "mainWord": "primavera"
        },
        "de": {
            "article": "Der",
            "mainWord": "Frühling"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690392203/vocimage/calendrier/printemps/leaf-6199807_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392203/vocimage/calendrier/printemps/nature-5016245_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392203/vocimage/calendrier/printemps/bird-7082154_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690392204/vocimage/calendrier/printemps/spring-flowers-110671_640.jpg"]
}, {
    "id": 616,
    "name": "Lundi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Lundi"
        },
        "nl": {
            "article": "",
            "mainWord": "Maandag"
        },
        "en": {
            "article": "",
            "mainWord": "Monday"
        },
        "es": {
            "article": "",
            "mainWord": "Lunes"
        },
        "de": {
            "article": "",
            "mainWord": "Montag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690375284/vocimage/calendrier/lundi/cyber-monday-3869345_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690375284/vocimage/calendrier/lundi/yawning-1895561_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690375284/vocimage/calendrier/lundi/clock-2743994_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690375284/vocimage/calendrier/lundi/day-1.jpg"]
}, {
    "id": 617,
    "name": "Mai",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mai"
        },
        "nl": {
            "article": "",
            "mainWord": "Mei"
        },
        "en": {
            "article": "",
            "mainWord": "May"
        },
        "es": {
            "article": "",
            "mainWord": "Mayo"
        },
        "de": {
            "article": "",
            "mainWord": "Mai"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690384576/vocimage/calendrier/mai/lilies-of-the-valley-4240031_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690384576/vocimage/calendrier/mai/bird-3176552_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691352957/vocimage/calendrier/mai/Mothers.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690384576/vocimage/calendrier/mai/lawn-mower-2408925_640.jpg"]
}, {
    "id": 618,
    "name": "Mardi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mardi"
        },
        "nl": {
            "article": "",
            "mainWord": "Dinsdag"
        },
        "en": {
            "article": "",
            "mainWord": "Tuesday"
        },
        "es": {
            "article": "",
            "mainWord": "Martes"
        },
        "de": {
            "article": "",
            "mainWord": "Dienstag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690376082/vocimage/calendrier/mardi/carnival-berlin-1133965__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690376082/vocimage/calendrier/mardi/city2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690376082/vocimage/calendrier/mardi/mardi.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690376741/vocimage/calendrier/mardi/fountain-pen-5715696_1280.jpg"]
}, {
    "id": 619,
    "name": "Mars",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mars"
        },
        "nl": {
            "article": "",
            "mainWord": "Maart"
        },
        "en": {
            "article": "",
            "mainWord": "March"
        },
        "es": {
            "article": "",
            "mainWord": "Marzo"
        },
        "de": {
            "article": "",
            "mainWord": "März"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690383274/vocimage/calendrier/mars/flowers-6972916_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383274/vocimage/calendrier/mars/bird-5981360_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383274/vocimage/calendrier/mars/child-1399188_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690383274/vocimage/calendrier/mars/skiing-1723857_640.jpg"]
}, {
    "id": 620,
    "name": "Mercredi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mercredi"
        },
        "nl": {
            "article": "",
            "mainWord": "Woensdag"
        },
        "en": {
            "article": "",
            "mainWord": "Wednesday"
        },
        "es": {
            "article": "",
            "mainWord": "Miércoles"
        },
        "de": {
            "article": "",
            "mainWord": "Mittwoch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691353239/vocimage/calendrier/mercredi/merc3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690379105/vocimage/calendrier/mercredi/mercredi.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690379105/vocimage/calendrier/mercredi/jour_3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690379105/vocimage/calendrier/mercredi/agenda-1667321820.jpg"]
}, {
    "id": 621,
    "name": "Mois",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mois"
        },
        "nl": {
            "article": "De",
            "mainWord": "maand"
        },
        "en": {
            "article": "A",
            "mainWord": "month"
        },
        "es": {
            "article": "El",
            "mainWord": "mes"
        },
        "de": {
            "article": "Der",
            "mainWord": "Monat"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690394224/vocimage/calendrier/mois/calendar-3045825__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394224/vocimage/calendrier/mois/astronomical-clock-5706__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394532/vocimage/calendrier/mois/valentines-day-3126232_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394532/vocimage/calendrier/mois/months.jpg"]
}, {
    "id": 622,
    "name": "Novembre",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Novembre"
        },
        "nl": {
            "article": "",
            "mainWord": "November"
        },
        "en": {
            "article": "",
            "mainWord": "November"
        },
        "es": {
            "article": "",
            "mainWord": "Noviembre"
        },
        "de": {
            "article": "",
            "mainWord": "November"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690386681/vocimage/calendrier/novembre/fog-337730_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386681/vocimage/calendrier/novembre/all-saints-day-7563282_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386681/vocimage/calendrier/novembre/chestnut-3694472_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386725/vocimage/calendrier/novembre/human-and-animal-4642626_640.jpg"]
}, {
    "id": 623,
    "name": "Octobre",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Octobre"
        },
        "nl": {
            "article": "",
            "mainWord": "Oktober"
        },
        "en": {
            "article": "",
            "mainWord": "October"
        },
        "es": {
            "article": "",
            "mainWord": "Octubre"
        },
        "de": {
            "article": "",
            "mainWord": "Oktober"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690386469/vocimage/calendrier/octobre/halloween-2870607_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386469/vocimage/calendrier/octobre/pumpkin-3775726_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386470/vocimage/calendrier/octobre/wooden-bridge-986345_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386470/vocimage/calendrier/octobre/hedgehog-985315_640.jpg"]
}, {
    "id": 624,
    "name": "Samedi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Samedi"
        },
        "nl": {
            "article": "",
            "mainWord": "Zaterdag"
        },
        "en": {
            "article": "",
            "mainWord": "Saturday"
        },
        "es": {
            "article": "",
            "mainWord": "Sábado"
        },
        "de": {
            "article": "",
            "mainWord": "Samstag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690381240/vocimage/calendrier/samedi/samedi.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691352712/vocimage/calendrier/samedi/Day-6.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690381240/vocimage/calendrier/samedi/etirement.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691352712/vocimage/calendrier/samedi/samedi5.jpg"]
}, {
    "id": 625,
    "name": "Semaine",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Semaine"
        },
        "nl": {
            "article": "De",
            "mainWord": "week"
        },
        "en": {
            "article": "A",
            "mainWord": "week"
        },
        "es": {
            "article": "La",
            "mainWord": "semana"
        },
        "de": {
            "article": "Die",
            "mainWord": "Woche"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690393330/vocimage/calendrier/semaine/days-week-1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394025/vocimage/calendrier/semaine/full-week-1468271__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394025/vocimage/calendrier/semaine/diary-2241986__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690394025/vocimage/calendrier/semaine/timetable-3222250_640.jpg"]
}, {
    "id": 626,
    "name": "Septembre",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Septembre"
        },
        "nl": {
            "article": "",
            "mainWord": "September"
        },
        "en": {
            "article": "",
            "mainWord": "September"
        },
        "es": {
            "article": "",
            "mainWord": "Septiembre"
        },
        "de": {
            "article": "",
            "mainWord": "September"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690386357/vocimage/calendrier/septembre/1129299-quand-les-devoirs-deviennent-un-jeu-d-enfant.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386358/vocimage/calendrier/septembre/apples-3313225_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386358/vocimage/calendrier/septembre/pathway-2532588_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690386358/vocimage/calendrier/septembre/school-bus-4406479_640.jpg"]
}, {
    "id": 627,
    "name": "Vendredi",
    "themeId": 7,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Vendredi"
        },
        "nl": {
            "article": "",
            "mainWord": "Vrijdag"
        },
        "en": {
            "article": "",
            "mainWord": "Friday"
        },
        "es": {
            "article": "",
            "mainWord": "Viernes"
        },
        "de": {
            "article": "",
            "mainWord": "Freitag"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690379892/vocimage/calendrier/vendredi/weekend-loading.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690380211/vocimage/calendrier/vendredi/fry-1807668_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690380382/vocimage/calendrier/vendredi/vendredi_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690380515/vocimage/calendrier/vendredi/quiet_weekend.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}