import React from 'react';
import { connect } from 'react-redux';
import Language from './Language';

class Languages extends React.Component {

    renderLanguages = () => {
        return this.props.languages.map(
            ln =>
            {
                return (
                    <Language ln={ln} key={ln.name}/>
                )
            }
        )
    }

    render() {
        return(
            <div className="subform-home">
                <div>Langue des mots</div>
                <div className="choices">
                    {this.renderLanguages()}
                </div>
            </div>            
        )
    }
}

function mapStateToProps(state) {
    return {languages: state.languages}
}

export default connect(mapStateToProps) (Languages);