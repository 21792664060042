const words = 
[{
    "id": 1801,
    "name": "Bronzer",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Bronzer"
        },
        "nl": {
            "article": "",
            "mainWord": "Zonnen"
        },
        "en": {
            "article": "",
            "mainWord": "Sunbathing"
        },
        "es": {
            "article": "",
            "mainWord": "Tomar el sol"
        },
        "de": {
            "article": "",
            "mainWord": "sonnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826812/vocimage/mer/bronzer/vacation-2218989_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826345/vocimage/mer/bronzer/seal-4926115__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826274/vocimage/mer/bronzer/sunbathing-826918__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826274/vocimage/mer/bronzer/beach-4346309__340.jpg"]
}, {
    "id": 1802,
    "name": "île",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "île"
        },
        "nl": {
            "article": "Het",
            "mainWord": "eiland"
        },
        "en": {
            "article": "An",
            "mainWord": "island"
        },
        "es": {
            "article": "La",
            "mainWord": "isla"
        },
        "de": {
            "article": "die",
            "mainWord": "Insel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826259/vocimage/mer/ile/beach-1836335__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826259/vocimage/mer/ile/island-3542290__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826259/vocimage/mer/ile/polynesia-3021072__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826259/vocimage/mer/ile/maldives-3220702__340.jpg"]
}, {
    "id": 1803,
    "name": "bouée",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "bouée"
        },
        "nl": {
            "article": "De",
            "mainWord": "zwemband"
        },
        "en": {
            "article": "A",
            "mainWord": "float"
        },
        "es": {
            "article": "El",
            "mainWord": "flotador"
        },
        "de": {
            "article": "der",
            "mainWord": "Schwimmreifen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826243/vocimage/mer/bouee/life-buoy-2590213__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826243/vocimage/mer/bouee/swim-ring-84625__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826243/vocimage/mer/bouee/buoy-914766__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826243/vocimage/mer/bouee/horizon-3332692__340.jpg"]
}, {
    "id": 1804,
    "name": "côte",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "côte"
        },
        "nl": {
            "article": "De",
            "mainWord": "kust"
        },
        "en": {
            "article": "The",
            "mainWord": "coast"
        },
        "es": {
            "article": "La",
            "mainWord": "costa"
        },
        "de": {
            "article": "die",
            "mainWord": "Küste"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826962/vocimage/mer/cote/runswick-bay-5430911_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826962/vocimage/mer/cote/village-279013_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826317/vocimage/mer/cote/lighthouse-1872998__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826225/vocimage/mer/cote/coast-4860818__340.jpg"]
}, {
    "id": 1805,
    "name": "digue",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "digue"
        },
        "nl": {
            "article": "De",
            "mainWord": "duik"
        },
        "en": {
            "article": "A",
            "mainWord": "dike"
        },
        "es": {
            "article": "El",
            "mainWord": "dique"
        },
        "de": {
            "article": "der",
            "mainWord": "Deich"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826205/vocimage/mer/digue/whitby-2779706__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826205/vocimage/mer/digue/cape-town-164668__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826205/vocimage/mer/digue/seascape-4730202__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826205/vocimage/mer/digue/sea-defence-4854116__340.jpg"]
}, {
    "id": 1806,
    "name": "dune",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "dune"
        },
        "nl": {
            "article": "Het",
            "mainWord": "duin"
        },
        "en": {
            "article": "A",
            "mainWord": "dune"
        },
        "es": {
            "article": "La",
            "mainWord": "duna"
        },
        "de": {
            "article": "die",
            "mainWord": "Düne"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826161/vocimage/mer/dune/dunes-2184976__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826147/vocimage/mer/dune/indiana-dunes-state-park-1848559__340_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826147/vocimage/mer/dune/sea-193803__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826147/vocimage/mer/dune/dunes-4033821__340_2.jpg"]
}, {
    "id": 1807,
    "name": "méduse",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "méduse"
        },
        "nl": {
            "article": "De",
            "mainWord": "kwal"
        },
        "en": {
            "article": "A",
            "mainWord": "jellyfish"
        },
        "es": {
            "article": "La",
            "mainWord": "medusa"
        },
        "de": {
            "article": "die",
            "mainWord": "Qualle"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827344/vocimage/mer/meduse/jellyfish-6651202_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827344/vocimage/mer/meduse/jellyfish-6653502_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826290/vocimage/mer/meduse/jellyfish-698521__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826290/vocimage/mer/meduse/jellyfish-257860__340.jpg"]
}, {
    "id": 1808,
    "name": "mer",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "mer"
        },
        "nl": {
            "article": "De",
            "mainWord": "zee"
        },
        "en": {
            "article": "The",
            "mainWord": "sea"
        },
        "es": {
            "article": "El",
            "mainWord": "mar"
        },
        "de": {
            "article": "das",
            "mainWord": "Meer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827893/vocimage/mer/mer/ocean-1867285_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827498/vocimage/mer/mer/sun-2542438_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827459/vocimage/mer/mer/bora-bora-685303_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827459/vocimage/mer/mer/to-stage-339252_640.jpg"]
}, {
    "id": 1809,
    "name": "mouette",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "mouette"
        },
        "nl": {
            "article": "De",
            "mainWord": "zeemeeuw"
        },
        "en": {
            "article": "A",
            "mainWord": "seagull"
        },
        "es": {
            "article": "La",
            "mainWord": "gaviota"
        },
        "de": {
            "article": "die",
            "mainWord": "Seemöwe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826118/vocimage/mer/mouette/seagull-5042292__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826108/vocimage/mer/mouette/gulls-1937919__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826108/vocimage/mer/mouette/seagull-1900657__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826108/vocimage/mer/mouette/seagull-601287__340.jpg"]
}, {
    "id": 1810,
    "name": "pêche",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "pêche"
        },
        "nl": {
            "article": "",
            "mainWord": "Vissen"
        },
        "en": {
            "article": "",
            "mainWord": "Fishing"
        },
        "es": {
            "article": "La",
            "mainWord": "pesca"
        },
        "de": {
            "article": "das",
            "mainWord": "Fischen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827622/vocimage/mer/peche/fishing-boat-5736839_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826095/vocimage/mer/peche/boy-909552__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826095/vocimage/mer/peche/fishermen-2983615__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826095/vocimage/mer/peche/white-tailed-eagle-2015098__340.jpg"]
}, {
    "id": 1811,
    "name": "plage",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "plage"
        },
        "nl": {
            "article": "Het",
            "mainWord": "strand"
        },
        "en": {
            "article": "The",
            "mainWord": "beach"
        },
        "es": {
            "article": "La",
            "mainWord": "playa"
        },
        "de": {
            "article": "der",
            "mainWord": "Strand"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827756/vocimage/mer/plage/beach-1751455_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827756/vocimage/mer/plage/beach-1824855_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826074/vocimage/mer/plage/young-woman-1745173__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826074/vocimage/mer/plage/beach-1852945__340.jpg"]
}, {
    "id": 1812,
    "name": "plongée",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "plongée"
        },
        "nl": {
            "article": "",
            "mainWord": "Duiken"
        },
        "en": {
            "article": "",
            "mainWord": "Diving"
        },
        "es": {
            "article": "El",
            "mainWord": "buceo"
        },
        "de": {
            "article": "",
            "mainWord": "tauchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827850/vocimage/mer/plongee/diver-668777_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827850/vocimage/mer/plongee/red-sea-2613882_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826667/vocimage/mer/plongee/turtle-5029662__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826539/vocimage/mer/plongee/beach-2333__340.jpg"]
}, {
    "id": 1813,
    "name": "poissonnerie",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "poissonnerie"
        },
        "nl": {
            "article": "De",
            "mainWord": "viswinkel"
        },
        "en": {
            "article": "A",
            "mainWord": "fish shop"
        },
        "es": {
            "article": "La",
            "mainWord": "pescadería"
        },
        "de": {
            "article": "das",
            "mainWord": "Fischgeschäft"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826515/vocimage/mer/poissonnerie/bream-2312881__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826051/vocimage/mer/poissonnerie/fish-counter-3664747__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826051/vocimage/mer/poissonnerie/fish-shop-1111293__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826051/vocimage/mer/poissonnerie/fund-786436__340.jpg"]
}, {
    "id": 1814,
    "name": "vague",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "vague"
        },
        "nl": {
            "article": "De",
            "mainWord": "golf"
        },
        "en": {
            "article": "A",
            "mainWord": "wave"
        },
        "es": {
            "article": "La",
            "mainWord": "ola"
        },
        "de": {
            "article": "die",
            "mainWord": "Welle"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690828157/vocimage/mer/vague/surfing-2212948_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826383/vocimage/mer/vague/water-2208931__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820641/vocimage/mer/vague/water-1555170__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820641/vocimage/mer/vague/big-waves-2193828__340.jpg"]
}, {
    "id": 1815,
    "name": "bâteau",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bâteau"
        },
        "nl": {
            "article": "De",
            "mainWord": "boot"
        },
        "en": {
            "article": "A",
            "mainWord": "boat"
        },
        "es": {
            "article": "El",
            "mainWord": "barco"
        },
        "de": {
            "article": "das",
            "mainWord": "Boot"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827710/vocimage/mer/bateau/boat-4119276_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826769/vocimage/mer/bateau/ship-105596_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826768/vocimage/mer/bateau/hallstatt-3609863_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820371/vocimage/mer/bateau/boat-1778040__340.jpg"]
}, {
    "id": 1816,
    "name": "cerf-volant",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cerf-volant"
        },
        "nl": {
            "article": "De",
            "mainWord": "vlieger"
        },
        "en": {
            "article": "A",
            "mainWord": "kite"
        },
        "es": {
            "article": "La",
            "mainWord": "cometa"
        },
        "de": {
            "article": "der",
            "mainWord": "Drachen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690820597/vocimage/mer/cerf%20volant/dragons-4512393__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820597/vocimage/mer/cerf%20volant/kite-2173913__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820597/vocimage/mer/cerf%20volant/sunset-4337497__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820597/vocimage/mer/cerf%20volant/kite-2173917__340.jpg"]
}, {
    "id": 1817,
    "name": "coquillage",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "coquillage"
        },
        "nl": {
            "article": "De",
            "mainWord": "schelp"
        },
        "en": {
            "article": "A",
            "mainWord": "shellfish"
        },
        "es": {
            "article": "La",
            "mainWord": "concha"
        },
        "de": {
            "article": "die",
            "mainWord": "Muschel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690826877/vocimage/mer/coquillage/shells-4395609_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820578/vocimage/mer/coquillage/blue-3121354__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820578/vocimage/mer/coquillage/shells-792912__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820578/vocimage/mer/coquillage/seashells-3478015__340.jpg"]
}, {
    "id": 1818,
    "name": "crabe",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "crabe"
        },
        "nl": {
            "article": "De",
            "mainWord": "krab"
        },
        "en": {
            "article": "A",
            "mainWord": "crab"
        },
        "es": {
            "article": "El",
            "mainWord": "cangrejo"
        },
        "de": {
            "article": "die",
            "mainWord": "Krabbe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690820563/vocimage/mer/crabe/crab-79156__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820562/vocimage/mer/crabe/crab-215170__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820562/vocimage/mer/crabe/crab-63084__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820562/vocimage/mer/crabe/crab-1990198__340.jpg"]
}, {
    "id": 1819,
    "name": "jet ski",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jet ski"
        },
        "nl": {
            "article": "De",
            "mainWord": "waterscooter"
        },
        "en": {
            "article": "A",
            "mainWord": "jet ski"
        },
        "es": {
            "article": "La",
            "mainWord": "moto de agua"
        },
        "de": {
            "article": "der",
            "mainWord": "Jetski"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827056/vocimage/mer/jet%20ski/jet-ski-188904_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691351490/vocimage/mer/jet%20ski/sea-2601079_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820549/vocimage/mer/jet%20ski/jet-ski-2176365__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820549/vocimage/mer/jet%20ski/jet-ski-4751379__340.jpg"]
}, {
    "id": 1820,
    "name": "marin",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "marin"
        },
        "nl": {
            "article": "De",
            "mainWord": "zeeman"
        },
        "en": {
            "article": "A",
            "mainWord": "sailor"
        },
        "es": {
            "article": "El",
            "mainWord": "marinero"
        },
        "de": {
            "article": "der",
            "mainWord": "Matrose"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827302/vocimage/mer/marin/sailor-380424_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827301/vocimage/mer/marin/adult-3890780_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820528/vocimage/mer/marin/sailor-4431281__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820528/vocimage/mer/marin/teddy-1603274__340.jpg"]
}, {
    "id": 1821,
    "name": "parasol",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "parasol"
        },
        "nl": {
            "article": "De",
            "mainWord": "parasol"
        },
        "en": {
            "article": "A",
            "mainWord": "parasol"
        },
        "es": {
            "article": "La",
            "mainWord": "sombrilla"
        },
        "de": {
            "article": "der",
            "mainWord": "Sonnenschirm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690827577/vocimage/mer/parasol/parasol-4347277_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827576/vocimage/mer/parasol/roof-terrace-354906_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690827576/vocimage/mer/parasol/maldives-2122547_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826480/vocimage/mer/parasol/pool-191972__340.jpg"]
}, {
    "id": 1822,
    "name": "phare",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "phare"
        },
        "nl": {
            "article": "De",
            "mainWord": "vuurtoren"
        },
        "en": {
            "article": "A",
            "mainWord": "lighthouse"
        },
        "es": {
            "article": "El",
            "mainWord": "faro"
        },
        "de": {
            "article": "der",
            "mainWord": "Leuchtturm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690829299/vocimage/mer/phare/lighthouse-2707528_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829300/vocimage/mer/phare/lighthouse-2372461_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829299/vocimage/mer/phare/santa-cruz-2287588_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829299/vocimage/mer/phare/plouzane-1758197_640.jpg"]
}, {
    "id": 1823,
    "name": "poisson",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "poisson"
        },
        "nl": {
            "article": "De",
            "mainWord": "vis"
        },
        "en": {
            "article": "A",
            "mainWord": "fish"
        },
        "es": {
            "article": "El",
            "mainWord": "pez"
        },
        "de": {
            "article": "der",
            "mainWord": "Fisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690828008/vocimage/mer/poisson/swarm-1656503_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826362/vocimage/mer/poisson/fish-378286__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820508/vocimage/mer/poisson/water-glass-2542790__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820508/vocimage/mer/poisson/coral-1355479__340.jpg"]
}, {
    "id": 1824,
    "name": "port",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "port"
        },
        "nl": {
            "article": "De",
            "mainWord": "haven"
        },
        "en": {
            "article": "A",
            "mainWord": "harbor"
        },
        "es": {
            "article": "El",
            "mainWord": "puerto"
        },
        "de": {
            "article": "der",
            "mainWord": "Hafen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690829184/vocimage/mer/port/portofino-4839356_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829184/vocimage/mer/port/fisherman-732037_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829184/vocimage/mer/port/hamburg-6849995_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690829184/vocimage/mer/port/port-6496782_640.jpg"]
}, {
    "id": 1825,
    "name": "sable",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sable"
        },
        "nl": {
            "article": "Het",
            "mainWord": "zand"
        },
        "en": {
            "article": "The",
            "mainWord": "sand"
        },
        "es": {
            "article": "La",
            "mainWord": "arena"
        },
        "de": {
            "article": "der",
            "mainWord": "Sand"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690828085/vocimage/mer/sable/sea-1281780_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690828084/vocimage/mer/sable/drink-84533_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820465/vocimage/mer/sable/sandburg-1639999__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820465/vocimage/mer/sable/sand-2005066__340.jpg"]
}, {
    "id": 1826,
    "name": "transat",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "transat"
        },
        "nl": {
            "article": "De",
            "mainWord": "strandstoel"
        },
        "en": {
            "article": "A",
            "mainWord": "deck chair"
        },
        "es": {
            "article": "La",
            "mainWord": "tumbona"
        },
        "de": {
            "article": "der",
            "mainWord": "Liegestuhl"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690828119/vocimage/mer/transat/maldive-islands-488030_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820443/vocimage/mer/transat/sunbeds-4392064__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820443/vocimage/mer/transat/sunbeds-2669784__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820443/vocimage/mer/transat/sunbeds-2446616__340.jpg"]
}, {
    "id": 1827,
    "name": "voilier",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "voilier"
        },
        "nl": {
            "article": "De",
            "mainWord": "zeilboot"
        },
        "en": {
            "article": "A",
            "mainWord": "sailboat"
        },
        "es": {
            "article": "El",
            "mainWord": "velero"
        },
        "de": {
            "article": "das",
            "mainWord": "Segelboot"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690828222/vocimage/mer/voilier/regatta-1049741_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690826832/vocimage/mer/voilier/sailing-ship-659758_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820412/vocimage/mer/voilier/sailing-boat-1593613__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820412/vocimage/mer/voilier/boat-1867124__340.jpg"]
}, {
    "id": 1828,
    "name": "crustacés",
    "themeId": 19,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "crustacés"
        },
        "nl": {
            "article": "De",
            "mainWord": "schaaldieren"
        },
        "en": {
            "article": "",
            "mainWord": "Crustaceans"
        },
        "es": {
            "article": "Los",
            "mainWord": "crustáceos"
        },
        "de": {
            "article": "die",
            "mainWord": "Schalentiere"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690820399/vocimage/mer/crustaces/seafood-395782__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820399/vocimage/mer/crustaces/seafood-platter-1232389__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820399/vocimage/mer/crustaces/prawns-1016068__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690820398/vocimage/mer/crustaces/lobster-1802731__340.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}