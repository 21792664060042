const words = 
[{
    "id": 301,
    "name": "beige",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "beige"
        },
        "nl": {
            "article": "",
            "mainWord": "beige"
        },
        "en": {
            "article": "",
            "mainWord": "beige"
        },
        "es": {
            "article": "",
            "mainWord": "beige"
        },
        "de": {
            "article": "",
            "mainWord": "beige"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690299563/vocimage/couleurs/beige/soap-775267_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299563/vocimage/couleurs/beige/pug-1646844_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299563/vocimage/couleurs/beige/sand-2848098_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299563/vocimage/couleurs/beige/british-shorthair-8032816_640.jpg"]
}, {
    "id": 302,
    "name": "blanc",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "blanc"
        },
        "nl": {
            "article": "",
            "mainWord": "wit"
        },
        "en": {
            "article": "",
            "mainWord": "white"
        },
        "es": {
            "article": "",
            "mainWord": "blanco"
        },
        "de": {
            "article": "",
            "mainWord": "weiß"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690299696/vocimage/couleurs/blanc/kitten-1285341_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299696/vocimage/couleurs/blanc/white-rose-snow-queen-5208843_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299696/vocimage/couleurs/blanc/snowflakes-3971461_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299696/vocimage/couleurs/blanc/paint-2420634_640.jpg"]
}, {
    "id": 303,
    "name": "bleu",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "bleu"
        },
        "nl": {
            "article": "",
            "mainWord": "blauw"
        },
        "en": {
            "article": "",
            "mainWord": "blue"
        },
        "es": {
            "article": "",
            "mainWord": "azul"
        },
        "de": {
            "article": "",
            "mainWord": "blau"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690299884/vocimage/couleurs/bleu/bellflower-1576086_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299883/vocimage/couleurs/bleu/macroperspective-6503070_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299883/vocimage/couleurs/bleu/sea-20627_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690299883/vocimage/couleurs/bleu/art-2578353_640.jpg"]
}, {
    "id": 304,
    "name": "brun",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "brun"
        },
        "nl": {
            "article": "",
            "mainWord": "bruin"
        },
        "en": {
            "article": "",
            "mainWord": "brown"
        },
        "es": {
            "article": "",
            "mainWord": "marrón"
        },
        "de": {
            "article": "",
            "mainWord": "braun"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690300220/vocimage/couleurs/brun/wood-2045380_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300219/vocimage/couleurs/brun/coffee-beans-1291656_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300219/vocimage/couleurs/brun/horse-3604162_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300219/vocimage/couleurs/brun/cupcakes-1452178_640.jpg"]
}, {
    "id": 305,
    "name": "clair",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "clair"
        },
        "nl": {
            "article": "",
            "mainWord": "licht"
        },
        "en": {
            "article": "",
            "mainWord": "light"
        },
        "es": {
            "article": "",
            "mainWord": "claro"
        },
        "de": {
            "article": "",
            "mainWord": "hell"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690300621/vocimage/couleurs/clair/daisy-182150_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300621/vocimage/couleurs/clair/tulips-2167654_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300621/vocimage/couleurs/clair/easter-4954907_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690300621/vocimage/couleurs/clair/texture-1876097_1280.jpg"]
}, {
    "id": 306,
    "name": "doré",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "doré"
        },
        "nl": {
            "article": "",
            "mainWord": "goudkleurig"
        },
        "en": {
            "article": "",
            "mainWord": "golden"
        },
        "es": {
            "article": "",
            "mainWord": "dorado"
        },
        "de": {
            "article": "",
            "mainWord": "golden"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690301104/vocimage/couleurs/dore/tutankhamun-1038544_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690301104/vocimage/couleurs/dore/christmas-balls-6837253_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690301104/vocimage/couleurs/dore/fuca-2491995_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690301104/vocimage/couleurs/dore/dragon-238931_640.jpg"]
}, {
    "id": 307,
    "name": "foncé",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "foncé"
        },
        "nl": {
            "article": "",
            "mainWord": "donker"
        },
        "en": {
            "article": "",
            "mainWord": "dark"
        },
        "es": {
            "article": "",
            "mainWord": "oscuro"
        },
        "de": {
            "article": "",
            "mainWord": "dunkel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690302117/vocimage/couleurs/fonce/apple-4833764_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302117/vocimage/couleurs/fonce/black-1072366_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302117/vocimage/couleurs/fonce/jeans-933682_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302117/vocimage/couleurs/fonce/park-bench-338429_640.jpg"]
}, {
    "id": 308,
    "name": "gris",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "gris"
        },
        "nl": {
            "article": "",
            "mainWord": "grijs"
        },
        "en": {
            "article": "",
            "mainWord": "grey"
        },
        "es": {
            "article": "",
            "mainWord": "gris"
        },
        "de": {
            "article": "",
            "mainWord": "grau"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690302441/vocimage/couleurs/gris/rocks-1869970_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302441/vocimage/couleurs/gris/donkey-2927987_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302441/vocimage/couleurs/gris/weaving-1803874_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690302441/vocimage/couleurs/gris/brush-7917642_640.jpg"]
}, {
    "id": 309,
    "name": "jaune",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "jaune"
        },
        "nl": {
            "article": "",
            "mainWord": "geel"
        },
        "en": {
            "article": "",
            "mainWord": "yellow"
        },
        "es": {
            "article": "",
            "mainWord": "amarillo"
        },
        "de": {
            "article": "",
            "mainWord": "gelb"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690306655/vocimage/couleurs/jaune/classic-car-1130622_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306655/vocimage/couleurs/jaune/minion-1847622_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306655/vocimage/couleurs/jaune/drip-1972411_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306655/vocimage/couleurs/jaune/birds-3860034_1920.jpg"]
}, {
    "id": 310,
    "name": "mauve",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "mauve"
        },
        "nl": {
            "article": "",
            "mainWord": "paars"
        },
        "en": {
            "article": "",
            "mainWord": "purple"
        },
        "es": {
            "article": "",
            "mainWord": "malva"
        },
        "de": {
            "article": "",
            "mainWord": "purpurn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690306892/vocimage/couleurs/mauve/thunder-953118_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306892/vocimage/couleurs/mauve/macaroons-3375245_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306892/vocimage/couleurs/mauve/lavenders-6484003_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690306892/vocimage/couleurs/mauve/child-7972995_640.jpg"]
}, {
    "id": 311,
    "name": "multicolore",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "multicolore"
        },
        "nl": {
            "article": "",
            "mainWord": "veelkleurig"
        },
        "en": {
            "article": "",
            "mainWord": "multicolored"
        },
        "es": {
            "article": "",
            "mainWord": "multicolor"
        },
        "de": {
            "article": "",
            "mainWord": "vielfarbig"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690307120/vocimage/couleurs/multicolore/smarties-991229_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307120/vocimage/couleurs/multicolore/rainbow-4285843_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307120/vocimage/couleurs/multicolore/abstract-2624807_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307120/vocimage/couleurs/multicolore/parrot-3017449_640.jpg"]
}, {
    "id": 312,
    "name": "noir",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "noir"
        },
        "nl": {
            "article": "",
            "mainWord": "zwart"
        },
        "en": {
            "article": "",
            "mainWord": "black"
        },
        "es": {
            "article": "",
            "mainWord": "negro"
        },
        "de": {
            "article": "",
            "mainWord": "schwarz"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690307327/vocimage/couleurs/noir/chess-316658_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307327/vocimage/couleurs/noir/swan-3340932_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307327/vocimage/couleurs/noir/dog-423398_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307327/vocimage/couleurs/noir/porsche-80153_640.jpg"]
}, {
    "id": 313,
    "name": "orange",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "orange"
        },
        "nl": {
            "article": "",
            "mainWord": "oranje"
        },
        "en": {
            "article": "",
            "mainWord": "orange"
        },
        "es": {
            "article": "",
            "mainWord": "naranja"
        },
        "de": {
            "article": "",
            "mainWord": "orangefarben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690307671/vocimage/couleurs/orange/flamingo-1850622_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307671/vocimage/couleurs/orange/dragon-2035743_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307671/vocimage/couleurs/orange/flower-5189902_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690307671/vocimage/couleurs/orange/sun-3275314_640.jpg"]
}, {
    "id": 314,
    "name": "rouge",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "rouge"
        },
        "nl": {
            "article": "",
            "mainWord": "rood"
        },
        "en": {
            "article": "",
            "mainWord": "red"
        },
        "es": {
            "article": "",
            "mainWord": "rojo"
        },
        "de": {
            "article": "",
            "mainWord": "rot"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690308478/vocimage/couleurs/rouge/baby-shoes-3644176_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308986/vocimage/couleurs/rouge/scooter-6380221_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308477/vocimage/couleurs/rouge/ford-2705402_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308477/vocimage/couleurs/rouge/heart-762564_640.jpg"]
}, {
    "id": 315,
    "name": "turquoise",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "turquoise"
        },
        "nl": {
            "article": "",
            "mainWord": "turquoise"
        },
        "en": {
            "article": "",
            "mainWord": "turquoise"
        },
        "es": {
            "article": "",
            "mainWord": "turquesa"
        },
        "de": {
            "article": "",
            "mainWord": "türkisfarben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690308938/vocimage/couleurs/turquoise/stone-3665285_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308938/vocimage/couleurs/turquoise/water-1330252_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308938/vocimage/couleurs/turquoise/marble-393356_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690308938/vocimage/couleurs/turquoise/summer-3571079_640.jpg"]
}, {
    "id": 316,
    "name": "vert",
    "themeId": 4,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "vert"
        },
        "nl": {
            "article": "",
            "mainWord": "groen"
        },
        "en": {
            "article": "",
            "mainWord": "green"
        },
        "es": {
            "article": "",
            "mainWord": "verde"
        },
        "de": {
            "article": "",
            "mainWord": "grün"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690309332/vocimage/couleurs/vert/incredible-hulk-613335_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690309331/vocimage/couleurs/vert/leaf-176722_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690309331/vocimage/couleurs/vert/ice-cream-3522629_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690309331/vocimage/couleurs/vert/chameleon-540655_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}