let maison = require('./maison.js');
let ecole = require('./ecole.js');
let animaux = require('./animaux.js');
let couleurs = require('./couleurs.js');
let nombres = require('./nombres.js');
let vetements = require('./vetements.js');
let calendrier = require('./calendrier.js');
let metiers = require('./metiers.js');
let transports = require('./transports.js');
let alimentation = require('./alimentation.js');
let loisirs = require('./loisirs.js');
let corps = require('./corps.js');
let meteo = require('./meteo.js');
let famille = require('./famille.js');
let humeurs = require('./humeurs.js');
let verbes = require('./verbes.js');
let campagne = require('./campagne.js');
let ville = require('./ville.js');
let mer = require('./mer.js');
let salutations = require('./salutations.js');
let montagne = require('./montagne.js');
let objets = require('./objets.js');
let pays = require('./pays.js');
let carnaval = require('./carnaval.js');
let halloween = require('./halloween.js');
let paques = require('./paques.js');
let noel = require('./noel.js');
// let autres = require('./autres.js');

const addWords = (theme, wordsArray) => {
    const words = theme.getWords();
    words.forEach(word => { wordsArray.push(word) });
}

const allWords = () => {
    const result = [];
    addWords(maison, result);
    addWords(ecole, result);
    addWords(animaux, result);
    addWords(couleurs, result);
    addWords(nombres, result);
    addWords(vetements, result);
    addWords(calendrier, result);
    addWords(metiers, result);
    addWords(transports, result);
    addWords(alimentation, result);
    addWords(loisirs, result);
    addWords(corps, result);
    addWords(meteo, result);
    addWords(famille, result);
    addWords(humeurs, result);
    addWords(verbes, result);
    addWords(campagne, result);
    addWords(ville, result);
    addWords(mer, result);
    addWords(salutations, result);
    addWords(montagne, result);
    addWords(objets, result);
    addWords(pays, result);
    addWords(carnaval, result);
    addWords(halloween, result);
    addWords(paques, result);
    addWords(noel, result);
    // addWords(autres, result);
    return result;
}

export default allWords;