const words = 
[{
    "id": 2501,
    "name": "agneau",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "agneau"
        },
        "nl": {
            "article": "Het",
            "mainWord": "lam"
        },
        "en": {
            "article": "A",
            "mainWord": "lamb"
        },
        "es": {
            "article": "El",
            "mainWord": "cordero"
        },
        "de": {
            "article": "Das",
            "mainWord": "Lamm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691232382/vocimage/paques/agneau/lamb-2216160_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232382/vocimage/paques/agneau/sheep-50914_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232382/vocimage/paques/agneau/sheep-7200918_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232382/vocimage/paques/agneau/lamb-5733685_640.jpg"]
}, {
    "id": 2502,
    "name": "chocolat",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chocolat"
        },
        "nl": {
            "article": "De",
            "mainWord": "chocolade"
        },
        "en": {
            "article": "A",
            "mainWord": "chocolate"
        },
        "es": {
            "article": "El",
            "mainWord": "chocolate"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schokolade"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691232527/vocimage/paques/chocolat/chocolate-716883_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232527/vocimage/paques/chocolat/chocolate-5026365_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232527/vocimage/paques/chocolat/easter-nest-2157018_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232527/vocimage/paques/chocolat/chocolates-2224998_640.jpg"]
}, {
    "id": 2503,
    "name": "lapin",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "lapin"
        },
        "nl": {
            "article": "Het",
            "mainWord": "konijn"
        },
        "en": {
            "article": "A",
            "mainWord": "rabbit"
        },
        "es": {
            "article": "El",
            "mainWord": "conejo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kaninchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691232752/vocimage/paques/lapin/bunny-1276628_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232752/vocimage/paques/lapin/puppy-2208365_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232752/vocimage/paques/lapin/easter-2197043_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232752/vocimage/paques/lapin/ai-generated-7770311_1280.jpg"]
}, {
    "id": 2504,
    "name": "nid",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "nid"
        },
        "nl": {
            "article": "Het",
            "mainWord": "nest"
        },
        "en": {
            "article": "A",
            "mainWord": "nest"
        },
        "es": {
            "article": "El",
            "mainWord": "nido"
        },
        "de": {
            "article": "Das",
            "mainWord": "Nest"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691232938/vocimage/paques/nid/easter-eggs-3257098_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232938/vocimage/paques/nid/easter-4107779_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232938/vocimage/paques/nid/nest-4194445_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691232938/vocimage/paques/nid/blackbirdest-2206124_640.jpg"]
}, {
    "id": 2505,
    "name": "panier",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "panier"
        },
        "nl": {
            "article": "De",
            "mainWord": "mand"
        },
        "en": {
            "article": "A",
            "mainWord": "basket"
        },
        "es": {
            "article": "La",
            "mainWord": "cesta"
        },
        "de": {
            "article": "Der",
            "mainWord": "Korb"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691233374/vocimage/paques/panier/easter-4739320_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233374/vocimage/paques/panier/easter-5008052_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233374/vocimage/paques/panier/easter-3165469_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233429/vocimage/paques/panier/animals-3196858_640.jpg"]
}, {
    "id": 2506,
    "name": "poussin",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "poussin"
        },
        "nl": {
            "article": "Het",
            "mainWord": "kuiken"
        },
        "en": {
            "article": "A",
            "mainWord": "chick"
        },
        "es": {
            "article": "El",
            "mainWord": "polluelo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Küken"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691233784/vocimage/paques/poussin/bird-349026_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233784/vocimage/paques/poussin/chick-7098110_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233784/vocimage/paques/poussin/chicks-3971179_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233785/vocimage/paques/poussin/poussin1.jpg"]
}, {
    "id": 2507,
    "name": "œufs",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "œufs"
        },
        "nl": {
            "article": "De",
            "mainWord": "eieren"
        },
        "en": {
            "article": "",
            "mainWord": "Eggs"
        },
        "es": {
            "article": "Los",
            "mainWord": "huevos"
        },
        "de": {
            "article": "Die",
            "mainWord": "Eier"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691233077/vocimage/paques/oeufs/easter-3985674_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233077/vocimage/paques/oeufs/easter-bunny-7036345_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233077/vocimage/paques/oeufs/eggs-1510449_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233454/vocimage/paques/oeufs/easter-1370427_640.jpg"]
}, {
    "id": 2508,
    "name": "Pâques",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Pâques"
        },
        "nl": {
            "article": "",
            "mainWord": "Pasen"
        },
        "en": {
            "article": "",
            "mainWord": "Easter"
        },
        "es": {
            "article": "",
            "mainWord": "Pascua"
        },
        "de": {
            "article": "",
            "mainWord": "Ostern"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691233894/vocimage/paques/paques/daffodils-1316127_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233894/vocimage/paques/paques/muffin-7870491_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233894/vocimage/paques/paques/easter-3281582_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233894/vocimage/paques/paques/easter-2173193_640.jpg"]
}, {
    "id": 2509,
    "name": "poule",
    "themeId": 26,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "poule"
        },
        "nl": {
            "article": "De",
            "mainWord": "kip"
        },
        "en": {
            "article": "A",
            "mainWord": "chicken"
        },
        "es": {
            "article": "La",
            "mainWord": "gallina"
        },
        "de": {
            "article": "Das",
            "mainWord": "Huhn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691233549/vocimage/paques/poule/orpington-3316345_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233549/vocimage/paques/poule/chickens-6078279_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233549/vocimage/paques/poule/cochin-1415260_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691233549/vocimage/paques/poule/easter-1217499_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}