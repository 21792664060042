import React from 'react';
import { connect } from 'react-redux';
import Home from './Home';
import Play from './Play';
import Test from './Test';
import Favicon from 'react-favicon';

class App extends React.Component {

    renderFavicon = () => {
        return(
            <Favicon url="https://www.myle.be/vocimage.png" />
        )
    }

    render() {
        const testApp = false;
        if (this.props.play === true) {
            if (testApp == true) {
                return(
                    <div>
                        {this.renderFavicon()}
                        <Test />
                    </div>
                )
            } else {
                return(
                    <div>
                        {this.renderFavicon()}
                        <Play />
                    </div>
                )
            }
        } else {
            return(
                <div>
                    {this.renderFavicon()}
                    <Home />
                </div>                    
           )
        }
    }
}

function mapStateToProps(state) {
    return { play: state.play }
}

export default connect(mapStateToProps) (App);