import { CHANGE_LEVEL } from '../actions/level.js'
export default function(state, action) {
    if (state === undefined) {
        return null
    }
    switch (action.type) {
        case CHANGE_LEVEL:
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}