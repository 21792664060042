import React from 'react';

class Image extends React.Component {
    render() {
        const {image} = this.props;
        return(
            <div className="box ratio1_1">
                <div className="image" style={{backgroundImage: `url(${image})`}}></div>
            </div>
        )
    }
}

export default Image;