import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePlay } from '../actions/play.js';
import { setFinished } from '../actions/game.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';

class Options extends React.Component {

    renderThemes = () => {
        return this.props.themesids.map(
            themeId =>
            {   const theme = this.props.themes.find(theme => theme.id === themeId)
                return(
                <li key={theme.id}>
                    {theme.name}
                </li>    
            )}
        )
    }

    playAgain = () => {
        if (window.confirm("Etes-vous certain de revenir au menu ? (vous ne perdrez pas votre progression.)")) {            
            this.props.changePlay(false);
            this.props.setFinished(true);
        }
    }

    render() {
        return(
            <div>
                <h3>
                    <button type="button" className="btn btn-primary" data-toggle="collapse" data-target="#options">Choix / Pause <FontAwesomeIcon icon={faCompressAlt} /></button>
                    <div id="options" className="collapse">
                        Tu as choisi de jouer
                        <ul>
                            {/* for the moment, the app is in french */}
                            <li>en {this.props.language.translations.fr}</li> 
                            <li>au niveau : {this.props.level}</li>
                            <li>avec les thèmes : 
                                <ul>
                                    {this.renderThemes()}
                                </ul>
                            </li>
                        </ul>
                        <div className="flex-container" >
                            <div id="icon-power-off" className="flex-container" onClick={this.playAgain}>
                                <FontAwesomeIcon icon={faPauseCircle} size="2x" className="app-main-color"/>
                            </div>
                        </div>
                    </div>
                </h3>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {themesids: state.themesids, 
            themes: state.themes,
            language: state.language,
            level: state.level}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { changePlay, setFinished },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Options);