import { FETCH_LANGUAGES } from '../actions/languages.js'
export default function(state, action) {
    if (state === undefined) {
        return [];
    }
    switch (action.type) {
        case FETCH_LANGUAGES:
            return action.payload;
        default:
            return state;
    }
}
