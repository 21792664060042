const words = 
[{
    "id": 2401,
    "name": "Halloween",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Halloween"
        },
        "nl": {
            "article": "",
            "mainWord": "Halloween"
        },
        "en": {
            "article": "",
            "mainWord": "Halloween"
        },
        "es": {
            "article": "",
            "mainWord": "Halloween"
        },
        "de": {
            "article": "",
            "mainWord": "Halloween"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691265436/vocimage/halloween/halloween/halloween1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265436/vocimage/halloween/halloween/halloween32.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265436/vocimage/halloween/halloween/halloween2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265436/vocimage/halloween/halloween/halloween.jpg"]
}, {
    "id": 2402,
    "name": "chauve-souris",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chauve-souris"
        },
        "nl": {
            "article": "De",
            "mainWord": "vleermuis"
        },
        "en": {
            "article": "a",
            "mainWord": "bat"
        },
        "es": {
            "article": "El",
            "mainWord": "murciélago"
        },
        "de": {
            "article": "Die",
            "mainWord": "Fledermaus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691266539/vocimage/halloween/chauve%20souris/full-moon-3659317_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266539/vocimage/halloween/chauve%20souris/animals-1081294_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266539/vocimage/halloween/chauve%20souris/bat1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266539/vocimage/halloween/chauve%20souris/batman-6023672_1280.jpg"]
}, {
    "id": 2403,
    "name": "citrouille",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "citrouille"
        },
        "nl": {
            "article": "De",
            "mainWord": "pompoen"
        },
        "en": {
            "article": "a",
            "mainWord": "pumpkin"
        },
        "es": {
            "article": "La",
            "mainWord": "calabaza"
        },
        "de": {
            "article": "Der",
            "mainWord": "Kürbis"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691271160/vocimage/halloween/citrouille/halloween-4585684_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691271160/vocimage/halloween/citrouille/pumpkin-6720424_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691271160/vocimage/halloween/citrouille/cat-4611169_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691271279/vocimage/halloween/citrouille/pumpkins-2878159_640.jpg"]
}, {
    "id": 2404,
    "name": "maison hantée",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "maison hantée"
        },
        "nl": {
            "article": "Het",
            "mainWord": "spookhuis"
        },
        "en": {
            "article": "a",
            "mainWord": "haunted house"
        },
        "es": {
            "article": "La",
            "mainWord": "casa encantada"
        },
        "de": {
            "article": "Das",
            "mainWord": "Geisterhaus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691266914/vocimage/halloween/maison%20hantee/haunted-house-7508035_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266914/vocimage/halloween/maison%20hantee/house-4530848_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266914/vocimage/halloween/maison%20hantee/woman-6099577_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266914/vocimage/halloween/maison%20hantee/haunted-house-7535322_640.jpg"]
}, {
    "id": 2405,
    "name": "momie",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "momie"
        },
        "nl": {
            "article": "De",
            "mainWord": "mummie"
        },
        "en": {
            "article": "a",
            "mainWord": "mummy"
        },
        "es": {
            "article": "La",
            "mainWord": "momia"
        },
        "de": {
            "article": "Die",
            "mainWord": "Mumie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691267156/vocimage/halloween/momie/sculpture-533983_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267156/vocimage/halloween/momie/mummy-2723444_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267156/vocimage/halloween/momie/momie1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267156/vocimage/halloween/momie/history-1901078_1280.jpg"]
}, {
    "id": 2406,
    "name": "potion",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "potion"
        },
        "nl": {
            "article": "De",
            "mainWord": "toverdrank"
        },
        "en": {
            "article": "a",
            "mainWord": "potion"
        },
        "es": {
            "article": "La",
            "mainWord": "poción"
        },
        "de": {
            "article": "Der",
            "mainWord": "Zaubertrank"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691268124/vocimage/halloween/potion/harry-potter-2348767_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268125/vocimage/halloween/potion/potion-hands-2958610_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268125/vocimage/halloween/potion/poyion.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268125/vocimage/halloween/potion/potion-2579774_640.jpg"]
}, {
    "id": 2407,
    "name": "sorcière",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "sorcière"
        },
        "nl": {
            "article": "De",
            "mainWord": "heks"
        },
        "en": {
            "article": "a",
            "mainWord": "witch"
        },
        "es": {
            "article": "La",
            "mainWord": "bruja"
        },
        "de": {
            "article": "Die",
            "mainWord": "Hexe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691268634/vocimage/halloween/sorciere/sorciere1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268635/vocimage/halloween/sorciere/baby-witch-7477038_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268635/vocimage/halloween/sorciere/magic-2974384_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268635/vocimage/halloween/sorciere/background-6689699_640.jpg"]
}, {
    "id": 2408,
    "name": "tombe",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "tombe"
        },
        "nl": {
            "article": "Het",
            "mainWord": "graf"
        },
        "en": {
            "article": "a",
            "mainWord": "grave"
        },
        "es": {
            "article": "La",
            "mainWord": "tumba"
        },
        "de": {
            "article": "Das",
            "mainWord": "Grab"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691269231/vocimage/halloween/tombe/tombeau.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269231/vocimage/halloween/tombe/tombstone-660890_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269231/vocimage/halloween/tombe/autumn-2182010_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269231/vocimage/halloween/tombe/celtic-cross-1457423_640.jpg"]
}, {
    "id": 2409,
    "name": "araignée",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "araignée"
        },
        "nl": {
            "article": "De",
            "mainWord": "spin"
        },
        "en": {
            "article": "a",
            "mainWord": "spider"
        },
        "es": {
            "article": "La",
            "mainWord": "araña"
        },
        "de": {
            "article": "Die",
            "mainWord": "Spinne"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691265656/vocimage/halloween/araignee/lego-1268070_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265656/vocimage/halloween/araignee/spider-111075_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265656/vocimage/halloween/araignee/cobweb-921039_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691265656/vocimage/halloween/araignee/wooden-wall-2733589_640.jpg"]
}, {
    "id": 2410,
    "name": "balai",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "balai"
        },
        "nl": {
            "article": "De",
            "mainWord": "bezem"
        },
        "en": {
            "article": "a",
            "mainWord": "broomstick"
        },
        "es": {
            "article": "La",
            "mainWord": "escoba"
        },
        "de": {
            "article": "Der",
            "mainWord": "Besen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691266079/vocimage/halloween/balai/rice-straw-broom-3491961_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266079/vocimage/halloween/balai/one-3176454_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266079/vocimage/halloween/balai/brooms-972304_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266080/vocimage/halloween/balai/harry-potter-2405542_640.jpg"]
}, {
    "id": 2411,
    "name": "bonbon",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bonbon"
        },
        "nl": {
            "article": "Het",
            "mainWord": "snoepje"
        },
        "en": {
            "article": "a",
            "mainWord": "candy"
        },
        "es": {
            "article": "El",
            "mainWord": "caramelo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Bonbon"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691266346/vocimage/halloween/bonbon/bonbons1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266346/vocimage/halloween/bonbon/halloween-candy-2903173_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266347/vocimage/halloween/bonbon/gummy-8150728_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266347/vocimage/halloween/bonbon/candy-2363_640.jpg"]
}, {
    "id": 2412,
    "name": "cimetière",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cimetière"
        },
        "nl": {
            "article": "Het",
            "mainWord": "kerkhof"
        },
        "en": {
            "article": "a",
            "mainWord": "cemetery"
        },
        "es": {
            "article": "El",
            "mainWord": "cementerio"
        },
        "de": {
            "article": "Der",
            "mainWord": "Friedhof"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691266710/vocimage/halloween/cimetiere/jewish-195099_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266710/vocimage/halloween/cimetiere/cemetery-4653166_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266710/vocimage/halloween/cimetiere/graveyard-2246071_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691266710/vocimage/halloween/cimetiere/sunrise-287097_640.jpg"]
}, {
    "id": 2413,
    "name": "fantôme",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "fantôme"
        },
        "nl": {
            "article": "De",
            "mainWord": "geest"
        },
        "en": {
            "article": "a",
            "mainWord": "ghost"
        },
        "es": {
            "article": "El",
            "mainWord": "fantasma"
        },
        "de": {
            "article": "Der",
            "mainWord": "Geist"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691269394/vocimage/halloween/fantome/halloween-2870607_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269394/vocimage/halloween/fantome/halloween-1746354_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269394/vocimage/halloween/fantome/FANTOME.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691269584/vocimage/halloween/fantome/FANTOME1.jpg"]
}, {
    "id": 2414,
    "name": "monstre",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "monstre"
        },
        "nl": {
            "article": "Het",
            "mainWord": "monster"
        },
        "en": {
            "article": "a",
            "mainWord": "monster"
        },
        "es": {
            "article": "El",
            "mainWord": "monstruo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Monster"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691267886/vocimage/halloween/monstre/monsters-532744_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267895/vocimage/halloween/monstre/monster-book-of-monsters-2405539_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267908/vocimage/halloween/monstre/ai-generated-7783343_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691267925/vocimage/halloween/monstre/monster-5017470_640.jpg"]
}, {
    "id": 2415,
    "name": "squelette",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "squelette"
        },
        "nl": {
            "article": "Het",
            "mainWord": "skelet"
        },
        "en": {
            "article": "a",
            "mainWord": "skeleton"
        },
        "es": {
            "article": "El",
            "mainWord": "esqueleto"
        },
        "de": {
            "article": "Das",
            "mainWord": "Skelett"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691268919/vocimage/halloween/squelette/halloween-5842741_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268919/vocimage/halloween/squelette/halloween-7481374_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268919/vocimage/halloween/squelette/lego-7006818_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691268919/vocimage/halloween/squelette/dog-7553020_640.jpg"]
}, {
    "id": 2416,
    "name": "vampire",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "vampire"
        },
        "nl": {
            "article": "De",
            "mainWord": "vampier"
        },
        "en": {
            "article": "a",
            "mainWord": "vampire"
        },
        "es": {
            "article": "El",
            "mainWord": "vampiro"
        },
        "de": {
            "article": "Der",
            "mainWord": "Vampir"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691270288/vocimage/halloween/vampire/vampire2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270288/vocimage/halloween/vampire/vampire3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270288/vocimage/halloween/vampire/vampire5.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270288/vocimage/halloween/vampire/vampire1.jpg"]
}, {
    "id": 2417,
    "name": "zombie",
    "themeId": 25,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "zombie"
        },
        "nl": {
            "article": "De",
            "mainWord": "zombie"
        },
        "en": {
            "article": "a",
            "mainWord": "zombie"
        },
        "es": {
            "article": "El",
            "mainWord": "zombi"
        },
        "de": {
            "article": "Der",
            "mainWord": "Zombie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691270941/vocimage/halloween/zombie/zombie4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270941/vocimage/halloween/zombie/zombie3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270941/vocimage/halloween/zombie/zombie1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691270941/vocimage/halloween/zombie/zombie2.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}