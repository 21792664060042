import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAllTheme, deselectAllTheme } from '../actions/themes.js';
import Toggle from 'react-toggle';
import Theme from './Theme';

class Themes extends React.Component {

    handleToggleAllThemes = (event) => {
        if (event.target.checked) {
            this.props.selectAllTheme(this.props.themes)
        } else {
            this.props.deselectAllTheme()
        }
    }

    renderToggle = () => {
        return(
            <div>                                        
                <label>
                    <Toggle
                        checked={this.props.themesids.length == this.props.themes.length}
                        icons={false}
                        onChange={this.handleToggleAllThemes} 
                    />                                            
                </label>
                <span id="toggle-text">Tous</span>
            </div>
        )
    }

    renderThemes = () => {
        return this.props.themes.map(
            theme => 
            {   return (
                <Theme theme={theme} key={theme.id}/>
            )}
        )
    }

    render() {
        return(
            <div className="subform-home">
                <div id="choose-themes-title">
                    <div>Thèmes</div>
                    {this.renderToggle()}
                </div>
                <div className="choices">
                    {this.renderThemes()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {themesids: state.themesids,
            themes: state.themes}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { selectAllTheme, deselectAllTheme },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Themes);