const words = 
[{
    "id": 501,
    "name": "écharpe",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "écharpe"
        },
        "nl": {
            "article": "De",
            "mainWord": "sjaal"
        },
        "en": {
            "article": "A",
            "mainWord": "scarf"
        },
        "es": {
            "article": "La",
            "mainWord": "bufanda"
        },
        "de": {
            "article": "der",
            "mainWord": "Schal"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151917/vocimage/vetements/echarpe/winter-1080045__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151917/vocimage/vetements/echarpe/girl-3904263__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151917/vocimage/vetements/echarpe/woman-2059158__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153288/vocimage/vetements/echarpe/cat-5767334_640.jpg"]
}, {
    "id": 502,
    "name": "boutique",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "boutique"
        },
        "nl": {
            "article": "De",
            "mainWord": "winkel"
        },
        "en": {
            "article": "A",
            "mainWord": "shop"
        },
        "es": {
            "article": "La",
            "mainWord": "tienda"
        },
        "de": {
            "article": "das",
            "mainWord": "Geschäft"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151891/vocimage/vetements/boutique/showcase-1597292__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151891/vocimage/vetements/boutique/shopping-2163323__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152297/vocimage/vetements/boutique/apparel-1850804_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152297/vocimage/vetements/boutique/business-1817472_640.jpg"]
}, {
    "id": 503,
    "name": "cabine d’essayage",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "cabine d’essayage"
        },
        "nl": {
            "article": "De",
            "mainWord": "paskamer"
        },
        "en": {
            "article": "A",
            "mainWord": "fitting room"
        },
        "es": {
            "article": "El",
            "mainWord": "probador"
        },
        "de": {
            "article": "die",
            "mainWord": "Ankleidekabine"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151945/vocimage/vetements/cabine%20d%20essayage/clothing-650370__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151945/vocimage/vetements/cabine%20d%20essayage/clothing-650380__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151945/vocimage/vetements/cabine%20d%20essayage/clothing-1275905__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152379/vocimage/vetements/cabine%20d%20essayage/shop-4988807_640.jpg"]
}, {
    "id": 504,
    "name": "casquette",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "casquette"
        },
        "nl": {
            "article": "De",
            "mainWord": "pet"
        },
        "en": {
            "article": "A",
            "mainWord": "cap"
        },
        "es": {
            "article": "La",
            "mainWord": "gorra"
        },
        "de": {
            "article": "die",
            "mainWord": "Schirmmütze"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151980/vocimage/vetements/casquette/child-3569431__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152478/vocimage/vetements/casquette/pilot-3359860_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152478/vocimage/vetements/casquette/portrait-7676482_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152516/vocimage/vetements/casquette/new-york-1565888_640.jpg"]
}, {
    "id": 505,
    "name": "ceinture",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "ceinture"
        },
        "nl": {
            "article": "De",
            "mainWord": "riem"
        },
        "en": {
            "article": "A",
            "mainWord": "belt"
        },
        "es": {
            "article": "El",
            "mainWord": "cinturón"
        },
        "de": {
            "article": "der",
            "mainWord": "Gürtel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691152008/vocimage/vetements/ceinture/belt-673177__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152008/vocimage/vetements/ceinture/blue-jeans-2160265__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152008/vocimage/vetements/ceinture/karate-1665606__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152009/vocimage/vetements/ceinture/belt-952834__340.jpg"]
}, {
    "id": 506,
    "name": "chemise",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chemise"
        },
        "nl": {
            "article": "Het",
            "mainWord": "hemd"
        },
        "en": {
            "article": "A",
            "mainWord": "shirt"
        },
        "es": {
            "article": "La",
            "mainWord": "camisa"
        },
        "de": {
            "article": "das",
            "mainWord": "Hemd"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151905/vocimage/vetements/chemise/shirts-428600__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152819/vocimage/vetements/chemise/white-845071_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152819/vocimage/vetements/chemise/rabbit-4988412_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152819/vocimage/vetements/chemise/blur-2841225_640.jpg"]
}, {
    "id": 507,
    "name": "cravate",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "cravate"
        },
        "nl": {
            "article": "De",
            "mainWord": "das"
        },
        "en": {
            "article": "A",
            "mainWord": "tie"
        },
        "es": {
            "article": "La",
            "mainWord": "corbata"
        },
        "de": {
            "article": "die",
            "mainWord": "Krawatte"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151823/vocimage/vetements/cravate/business-attire-1853857__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151823/vocimage/vetements/cravate/neckties-210347__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153262/vocimage/vetements/cravate/dog-2467149_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153262/vocimage/vetements/cravate/coat-1867388_640.jpg"]
}, {
    "id": 508,
    "name": "garde-robe",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "garde-robe"
        },
        "nl": {
            "article": "De",
            "mainWord": "klerenkast"
        },
        "en": {
            "article": "A",
            "mainWord": "wardrobe"
        },
        "es": {
            "article": "El",
            "mainWord": "guardaropa"
        },
        "de": {
            "article": "der",
            "mainWord": "Kleiderschrank"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151788/vocimage/vetements/garde%20robe/dresses-816033__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151788/vocimage/vetements/garde%20robe/wardrobe-3371665__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151799/vocimage/vetements/garde%20robe/apartment-2094681__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151805/vocimage/vetements/garde%20robe/dresses-53319__340.jpg"]
}, {
    "id": 509,
    "name": "jupe",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "jupe"
        },
        "nl": {
            "article": "De",
            "mainWord": "rok"
        },
        "en": {
            "article": "A",
            "mainWord": "skirt"
        },
        "es": {
            "article": "La",
            "mainWord": "falda"
        },
        "de": {
            "article": "der",
            "mainWord": "Rock"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151880/vocimage/vetements/jupe/people-2590092__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151880/vocimage/vetements/jupe/flower-girl-2081313__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151880/vocimage/vetements/jupe/barbie-doll-1749181__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153397/vocimage/vetements/jupe/girl-1258727_640.jpg"]
}, {
    "id": 510,
    "name": "robe",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "robe"
        },
        "nl": {
            "article": "De",
            "mainWord": "jurk"
        },
        "en": {
            "article": "A",
            "mainWord": "dress"
        },
        "es": {
            "article": "El",
            "mainWord": "vestido"
        },
        "de": {
            "article": "das",
            "mainWord": "Kleid"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151956/vocimage/vetements/robe/girl-1505407__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151956/vocimage/vetements/robe/girl-1349270__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151956/vocimage/vetements/robe/red-bicycle-3498602__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153837/vocimage/vetements/robe/woman-1840538_640.jpg"]
}, {
    "id": 511,
    "name": "taille",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "taille"
        },
        "nl": {
            "article": "De",
            "mainWord": "maat"
        },
        "en": {
            "article": "The",
            "mainWord": "size"
        },
        "es": {
            "article": "El",
            "mainWord": "tamaño"
        },
        "de": {
            "article": "Die",
            "mainWord": "Größe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151720/vocimage/vetements/taille/belly-2354__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154195/vocimage/vetements/taille/measure-1897778_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154196/vocimage/vetements/taille/sewing-1325767_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154441/vocimage/vetements/taille/taille.jpg"]
}, {
    "id": 512,
    "name": "veste",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "veste"
        },
        "nl": {
            "article": "De",
            "mainWord": "jas"
        },
        "en": {
            "article": "A",
            "mainWord": "jacket"
        },
        "es": {
            "article": "La",
            "mainWord": "chaqueta"
        },
        "de": {
            "article": "die",
            "mainWord": "Jacke"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151935/vocimage/vetements/veste/blue-2566082__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154868/vocimage/vetements/veste/people-2557413_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154868/vocimage/vetements/veste/down-jackets-1281699_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154916/vocimage/vetements/veste/gratitude-3968280_640.jpg"]
}, {
    "id": 513,
    "name": "bonnet",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bonnet"
        },
        "nl": {
            "article": "De",
            "mainWord": "muts"
        },
        "en": {
            "article": "A",
            "mainWord": "beanie"
        },
        "es": {
            "article": "El",
            "mainWord": "gorro"
        },
        "de": {
            "article": "die",
            "mainWord": "Mütze"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691152180/vocimage/vetements/bonnet/people-2592050_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152180/vocimage/vetements/bonnet/bobble-hat-4118004_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152180/vocimage/vetements/bonnet/baby-1868683_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152022/vocimage/vetements/bonnet/cap-93726__340.jpg"]
}, {
    "id": 514,
    "name": "chapeau",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chapeau"
        },
        "nl": {
            "article": "De",
            "mainWord": "hoed"
        },
        "en": {
            "article": "A",
            "mainWord": "hat"
        },
        "es": {
            "article": "El",
            "mainWord": "sombrero"
        },
        "de": {
            "article": "der",
            "mainWord": "Hut"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691152638/vocimage/vetements/chapeau/cap-7278216_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152638/vocimage/vetements/chapeau/teddy-bear-797577_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152639/vocimage/vetements/chapeau/queen-63006_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152639/vocimage/vetements/chapeau/bulldog-2489829_640.jpg"]
}, {
    "id": 515,
    "name": "chemisier",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chemisier"
        },
        "nl": {
            "article": "De",
            "mainWord": "bloes"
        },
        "en": {
            "article": "A",
            "mainWord": "blouse"
        },
        "es": {
            "article": "La",
            "mainWord": "blusa"
        },
        "de": {
            "article": "die",
            "mainWord": "Bluse"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151994/vocimage/vetements/chemisier/blouse-792803__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151994/vocimage/vetements/chemisier/blouse-480218__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151995/vocimage/vetements/chemisier/mannequin-3858615__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152882/vocimage/vetements/chemisier/lady-1409015_640.jpg"]
}, {
    "id": 516,
    "name": "jeans",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jeans"
        },
        "nl": {
            "article": "De",
            "mainWord": "jeans"
        },
        "en": {
            "article": "The",
            "mainWord": "jeans"
        },
        "es": {
            "article": "Los",
            "mainWord": "vaqueros"
        },
        "de": {
            "article": "die",
            "mainWord": "Jeanshose"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151764/vocimage/vetements/jean/clothes-166848__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151764/vocimage/vetements/jean/pocket-2324242__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153360/vocimage/vetements/jean/kid-2586010_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153360/vocimage/vetements/jean/jeans-5394561_640.jpg"]
}, {
    "id": 517,
    "name": "maillot de bain",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "maillot de bain"
        },
        "nl": {
            "article": "De",
            "mainWord": "zwempak"
        },
        "en": {
            "article": "A",
            "mainWord": "swimsuit"
        },
        "es": {
            "article": "El",
            "mainWord": "bañador"
        },
        "de": {
            "article": "der",
            "mainWord": "Badeanzug"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151838/vocimage/vetements/maillot%20de%20bain/swim-ring-84625__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151838/vocimage/vetements/maillot%20de%20bain/women-4293831__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153570/vocimage/vetements/maillot%20de%20bain/swimmer-1861949_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153570/vocimage/vetements/maillot%20de%20bain/swimsuit-1062192_640.jpg"]
}, {
    "id": 518,
    "name": "manteau",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "manteau"
        },
        "nl": {
            "article": "De",
            "mainWord": "mantel"
        },
        "en": {
            "article": "A",
            "mainWord": "coat"
        },
        "es": {
            "article": "El",
            "mainWord": "abrigo"
        },
        "de": {
            "article": "der",
            "mainWord": "Mantel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151968/vocimage/vetements/manteau/christmas-girls-556768__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151968/vocimage/vetements/manteau/fashion-2940043__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153612/vocimage/vetements/manteau/man-7518890_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153612/vocimage/vetements/manteau/woman-789663_640.jpg"]
}, {
    "id": 519,
    "name": "pantalon",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "pantalon"
        },
        "nl": {
            "article": "De",
            "mainWord": "broek"
        },
        "en": {
            "article": "The",
            "mainWord": "trousers"
        },
        "es": {
            "article": "Los",
            "mainWord": "pantalones"
        },
        "de": {
            "article": "die",
            "mainWord": "Hose"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151659/vocimage/vetements/pantalon/uniform-238483__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151660/vocimage/vetements/pantalon/adult-1853436__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153719/vocimage/vetements/pantalon/trousers-268653_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153719/vocimage/vetements/pantalon/converse-925773_640.jpg"]
}, {
    "id": 520,
    "name": "pull",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "pull"
        },
        "nl": {
            "article": "De",
            "mainWord": "trui"
        },
        "en": {
            "article": "A",
            "mainWord": "sweater"
        },
        "es": {
            "article": "El",
            "mainWord": "jersey"
        },
        "de": {
            "article": "der",
            "mainWord": "Pullover"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691152046/vocimage/vetements/pull/sweaters-428626__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153807/vocimage/vetements/pull/shirt-428620_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153807/vocimage/vetements/pull/girl-1387118_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153808/vocimage/vetements/pull/woman-594465_640.jpg"]
}, {
    "id": 521,
    "name": "short",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "short"
        },
        "nl": {
            "article": "De",
            "mainWord": "korte broek"
        },
        "en": {
            "article": "The",
            "mainWord": "shorts"
        },
        "es": {
            "article": "Los",
            "mainWord": "pantalones cortos"
        },
        "de": {
            "article": "der",
            "mainWord": "Short"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691154040/vocimage/vetements/short/boy-1822565_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154040/vocimage/vetements/short/girl-2606776_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154040/vocimage/vetements/short/shorts-4392043_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154070/vocimage/vetements/short/soccer-434343_640.jpg"]
}, {
    "id": 522,
    "name": "tee-shirt",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tee-shirt"
        },
        "nl": {
            "article": "Het",
            "mainWord": "T-shirt"
        },
        "en": {
            "article": "A",
            "mainWord": "T-shirt"
        },
        "es": {
            "article": "La",
            "mainWord": "camiseta"
        },
        "de": {
            "article": "das",
            "mainWord": "T-Shirt"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691152035/vocimage/vetements/tee%20shirt/holding-4328043__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154578/vocimage/vetements/tee%20shirt/guy-2607141_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154578/vocimage/vetements/tee%20shirt/teddy-3698769_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691154578/vocimage/vetements/tee%20shirt/kid-2101832_640.jpg"]
}, {
    "id": 523,
    "name": "bottes",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "bottes"
        },
        "nl": {
            "article": "De",
            "mainWord": "laarzen"
        },
        "en": {
            "article": "The",
            "mainWord": "boots"
        },
        "es": {
            "article": "Las",
            "mainWord": "botas"
        },
        "de": {
            "article": "die",
            "mainWord": "Stiefel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151774/vocimage/vetements/bottes/boots-5028821__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151774/vocimage/vetements/bottes/fun-socks-1179312__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152243/vocimage/vetements/bottes/kindergarten-981518_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152243/vocimage/vetements/bottes/rain-791893_640.jpg"]
}, {
    "id": 524,
    "name": "chaussettes",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "chaussettes"
        },
        "nl": {
            "article": "De",
            "mainWord": "sokken"
        },
        "en": {
            "article": "The",
            "mainWord": "socks"
        },
        "es": {
            "article": "Los",
            "mainWord": "calcetines"
        },
        "de": {
            "article": "die",
            "mainWord": "Socken"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151704/vocimage/vetements/chaussettes/socks-1322489__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152725/vocimage/vetements/chaussettes/feet-932346_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152725/vocimage/vetements/chaussettes/frost-2721870_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691152750/vocimage/vetements/chaussettes/socks-1578652_640.jpg"]
}, {
    "id": 525,
    "name": "souliers",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "souliers"
        },
        "nl": {
            "article": "De",
            "mainWord": "schoenen"
        },
        "en": {
            "article": "The",
            "mainWord": "shoes"
        },
        "es": {
            "article": "Los",
            "mainWord": "zapatos"
        },
        "de": {
            "article": "die",
            "mainWord": "Schuhe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151735/vocimage/vetements/souliers/business-2049312__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151735/vocimage/vetements/souliers/skateboarding-498298__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151736/vocimage/vetements/souliers/foot-3463555__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691151736/vocimage/vetements/souliers/boot-250012__340.jpg"]
}, {
    "id": 526,
    "name": "collants",
    "themeId": 6,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "collants"
        },
        "nl": {
            "article": "De",
            "mainWord": "kousen"
        },
        "en": {
            "article": "The",
            "mainWord": "stockings"
        },
        "es": {
            "article": "Las",
            "mainWord": "medias"
        },
        "de": {
            "article": "die",
            "mainWord": "Strumpfhose"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691151681/vocimage/vetements/collant/child-2590166__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153213/vocimage/vetements/collant/stockings-428602_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153213/vocimage/vetements/collant/shoe-1589220_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691153213/vocimage/vetements/collant/bas.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}