import React from 'react';
import Playerletters from '../containers/Playerletters';
import Icon from '../containers/Icon';
import Possibleletters from '../containers/Possibleletters';

class Letters extends React.Component {

    render() {
        return (
            <div id="letters-container">
                <Playerletters />
                <Icon />
                <Possibleletters />
            </div>
        )
    }
}

export default Letters;