const words = 
[{
    "id": 201,
    "name": "araignée",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "araignée"
        },
        "nl": {
            "article": "De",
            "mainWord": "spin"
        },
        "en": {
            "article": "A",
            "mainWord": "spider"
        },
        "es": {
            "article": "La",
            "mainWord": "araña"
        },
        "de": {
            "article": "Die",
            "mainWord": "Spinne"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690041243/vocimage/animaux/araignee/yorkshire-terrier-3787767_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041243/vocimage/animaux/araignee/spider-111075_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004466/vocimage/animaux/araignee/araign%C3%A9e_3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004458/vocimage/animaux/araignee/araign%C3%A9e_4.jpg"]
}, {
    "id": 202,
    "name": "écureuil",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "écureuil"
        },
        "nl": {
            "article": "De",
            "mainWord": "eekhoorn"
        },
        "en": {
            "article": "A",
            "mainWord": "squirrel"
        },
        "es": {
            "article": "La",
            "mainWord": "ardilla"
        },
        "de": {
            "article": "Das",
            "mainWord": "Eichhörnchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690104323/vocimage/animaux/ecureuil/squirrel-7025721_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104323/vocimage/animaux/ecureuil/squirrel-4240426_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004708/vocimage/animaux/ecureuil/%C3%A9cureuil_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004704/vocimage/animaux/ecureuil/%C3%A9cureuil_2.jpg"]
}, {
    "id": 203,
    "name": "éléphant",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "éléphant"
        },
        "nl": {
            "article": "De",
            "mainWord": "olifant"
        },
        "en": {
            "article": "An",
            "mainWord": "elephant"
        },
        "es": {
            "article": "El",
            "mainWord": "elefante"
        },
        "de": {
            "article": "Der",
            "mainWord": "Elefant"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690104582/vocimage/animaux/elephant/elephant-2894633_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004785/vocimage/animaux/elephant/%C3%A9l%C3%A9phant_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004782/vocimage/animaux/elephant/%C3%A9l%C3%A9phant_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004777/vocimage/animaux/elephant/%C3%A9l%C3%A9phant_3.jpg"]
}, {
    "id": 204,
    "name": "hippopotame",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "hippopotame"
        },
        "nl": {
            "article": "Het",
            "mainWord": "nijlpaard"
        },
        "en": {
            "article": "A",
            "mainWord": "hippopotamus"
        },
        "es": {
            "article": "El",
            "mainWord": "hipopótamo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Nilpferd"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105323/vocimage/animaux/hippopotame/hippo-3805553_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004934/vocimage/animaux/hippopotame/hippopotame_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004930/vocimage/animaux/hippopotame/hippopotame_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687004922/vocimage/animaux/hippopotame/hippopotame_4.jpg"]
}, {
    "id": 205,
    "name": "oiseau",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "oiseau"
        },
        "nl": {
            "article": "De",
            "mainWord": "vogel"
        },
        "en": {
            "article": "A",
            "mainWord": "bird"
        },
        "es": {
            "article": "El",
            "mainWord": "pájaro"
        },
        "de": {
            "article": "Der",
            "mainWord": "Vogel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106498/vocimage/animaux/oiseau/sparrow-3698507_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106497/vocimage/animaux/oiseau/bird-1045954_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106497/vocimage/animaux/oiseau/feathers-1952382_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106497/vocimage/animaux/oiseau/beach-1852945_640.jpg"]
}, {
    "id": 206,
    "name": "ours",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "ours"
        },
        "nl": {
            "article": "De",
            "mainWord": "beer"
        },
        "en": {
            "article": "A",
            "mainWord": "bear"
        },
        "es": {
            "article": "El",
            "mainWord": "oso"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bär"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106585/vocimage/animaux/ours/bear-1564448_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005121/vocimage/animaux/ours/ours_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005111/vocimage/animaux/ours/ours_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005105/vocimage/animaux/ours/ours_4.jpg"]
}, {
    "id": 207,
    "name": "abeille",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "abeille"
        },
        "nl": {
            "article": "De",
            "mainWord": "bij"
        },
        "en": {
            "article": "A",
            "mainWord": "bee"
        },
        "es": {
            "article": "La",
            "mainWord": "abeja"
        },
        "de": {
            "article": "Die",
            "mainWord": "Biene"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690041067/vocimage/animaux/abeille/bee-1726659_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041067/vocimage/animaux/abeille/apiary-1867537_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041067/vocimage/animaux/abeille/apiary-1866740_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041067/vocimage/animaux/abeille/honey-1958464_640.jpg"]
}, {
    "id": 208,
    "name": "baleine",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "baleine"
        },
        "nl": {
            "article": "De",
            "mainWord": "walvis"
        },
        "en": {
            "article": "A",
            "mainWord": "whale"
        },
        "es": {
            "article": "La",
            "mainWord": "ballena"
        },
        "de": {
            "article": "Der",
            "mainWord": "Wal"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690041467/vocimage/animaux/baleine/humpback-79854_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041466/vocimage/animaux/baleine/national-history-museum-4314035_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041466/vocimage/animaux/baleine/sea-2052650_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687018062/vocimage/animaux/baleine/baleine_3.webp"]
}, {
    "id": 209,
    "name": "chauve-souris",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chauve-souris"
        },
        "nl": {
            "article": "De",
            "mainWord": "vleermuis"
        },
        "en": {
            "article": "A",
            "mainWord": "bat"
        },
        "es": {
            "article": "El",
            "mainWord": "murciélago"
        },
        "de": {
            "article": "Die",
            "mainWord": "Fledermaus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690042130/vocimage/animaux/chauve%20souris/toy-3692614_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690042130/vocimage/animaux/chauve%20souris/rodrigues-fruit-bat-7523361_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005235/vocimage/animaux/chauve%20souris/chauve-souris_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005225/vocimage/animaux/chauve%20souris/chauve-souris_4.jpg"]
}, {
    "id": 210,
    "name": "girafe",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "girafe"
        },
        "nl": {
            "article": "De",
            "mainWord": "giraf"
        },
        "en": {
            "article": "A",
            "mainWord": "giraffe"
        },
        "es": {
            "article": "La",
            "mainWord": "jirafa"
        },
        "de": {
            "article": "Die",
            "mainWord": "Giraffe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690104687/vocimage/animaux/girafe/wild-214747_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104687/vocimage/animaux/girafe/nose-4610466_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104687/vocimage/animaux/girafe/giraffe-4491868_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104686/vocimage/animaux/girafe/giraffe-1341638_640.jpg"]
}, {
    "id": 211,
    "name": "grenouille",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "grenouille"
        },
        "nl": {
            "article": "De",
            "mainWord": "kikker"
        },
        "en": {
            "article": "A",
            "mainWord": "frog"
        },
        "es": {
            "article": "La",
            "mainWord": "rana"
        },
        "de": {
            "article": "Der",
            "mainWord": "Frosch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690104848/vocimage/animaux/grenouille/frog-prince-334970_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104848/vocimage/animaux/grenouille/pond-frog-3732307_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104848/vocimage/animaux/grenouille/frog-5319326_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005533/vocimage/animaux/grenouille/grenouille_3.jpg"]
}, {
    "id": 212,
    "name": "panthère",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "panthère"
        },
        "nl": {
            "article": "De",
            "mainWord": "panter"
        },
        "en": {
            "article": "A",
            "mainWord": "panther"
        },
        "es": {
            "article": "La",
            "mainWord": "pantera"
        },
        "de": {
            "article": "Der",
            "mainWord": "Panther"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107038/vocimage/animaux/panthere/panther-7690092_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106929/vocimage/animaux/panthere/leopard-1147989_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106929/vocimage/animaux/panthere/water-1525007_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687005954/vocimage/animaux/panthere/panth%C3%A8re_3.jpg"]
}, {
    "id": 213,
    "name": "poule",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "poule"
        },
        "nl": {
            "article": "De",
            "mainWord": "kip"
        },
        "en": {
            "article": "A",
            "mainWord": "chicken"
        },
        "es": {
            "article": "La",
            "mainWord": "gallina"
        },
        "de": {
            "article": "Das",
            "mainWord": "Huhn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107554/vocimage/animaux/poule/farm-3206306_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107554/vocimage/animaux/poule/chicken-3662513_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107554/vocimage/animaux/poule/chicken-7153770_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687006293/vocimage/animaux/poule/poule_3.jpg"]
}, {
    "id": 214,
    "name": "souris",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "souris"
        },
        "nl": {
            "article": "De",
            "mainWord": "muis"
        },
        "en": {
            "article": "A",
            "mainWord": "mouse"
        },
        "es": {
            "article": "El",
            "mainWord": "ratón"
        },
        "de": {
            "article": "Die",
            "mainWord": "Maus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690108388/vocimage/animaux/souris/cat-4068840_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690108347/vocimage/animaux/souris/mickey-1185754_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690108346/vocimage/animaux/souris/mouse-trap-2846147_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690108346/vocimage/animaux/souris/mouse-1708192_640.jpg"]
}, {
    "id": 215,
    "name": "tortue",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "tortue"
        },
        "nl": {
            "article": "De",
            "mainWord": "schildpad"
        },
        "en": {
            "article": "A",
            "mainWord": "turtle"
        },
        "es": {
            "article": "La",
            "mainWord": "tortuga"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schildkröte"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690109951/vocimage/animaux/tortue/turtle-3433360_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690109951/vocimage/animaux/tortue/turtle-2201433_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690109951/vocimage/animaux/tortue/spurred-tortoise-621372_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687015018/vocimage/animaux/tortue/tortue_2.jpg"]
}, {
    "id": 216,
    "name": "vache",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "vache"
        },
        "nl": {
            "article": "De",
            "mainWord": "koe"
        },
        "en": {
            "article": "A",
            "mainWord": "cow"
        },
        "es": {
            "article": "La",
            "mainWord": "vaca"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kuh"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690110157/vocimage/animaux/vache/milk-1809238_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690110157/vocimage/animaux/vache/cows-7019167_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690110157/vocimage/animaux/vache/cow-5299176_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687015219/vocimage/animaux/vache/vache_1.jpg"]
}, {
    "id": 217,
    "name": "canard",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "canard"
        },
        "nl": {
            "article": "De",
            "mainWord": "eend"
        },
        "en": {
            "article": "A",
            "mainWord": "duck"
        },
        "es": {
            "article": "El",
            "mainWord": "pato"
        },
        "de": {
            "article": "Die",
            "mainWord": "Ente"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691353640/vocimage/animaux/canard/ducklings-3466950_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041552/vocimage/animaux/canard/animal-1772737_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041552/vocimage/animaux/canard/rubber-duck-1401225_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687015291/vocimage/animaux/canard/canard_3.jpg"]
}, {
    "id": 218,
    "name": "chameau",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chameau"
        },
        "nl": {
            "article": "De",
            "mainWord": "kameel"
        },
        "en": {
            "article": "A",
            "mainWord": "camel"
        },
        "es": {
            "article": "El",
            "mainWord": "camello"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kamel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690041816/vocimage/animaux/chameau/camel-2500618_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041815/vocimage/animaux/chameau/camel-4115431_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041815/vocimage/animaux/chameau/camel-4785794_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041815/vocimage/animaux/chameau/animal-6657488_640.jpg"]
}, {
    "id": 219,
    "name": "chat",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chat"
        },
        "nl": {
            "article": "De",
            "mainWord": "kat"
        },
        "en": {
            "article": "A",
            "mainWord": "cat"
        },
        "es": {
            "article": "El",
            "mainWord": "gato"
        },
        "de": {
            "article": "Die",
            "mainWord": "Katze"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690041991/vocimage/animaux/chat/cat-4928270_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041991/vocimage/animaux/chat/cats-paw-1375792_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690041991/vocimage/animaux/chat/cat-2536662_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687015384/vocimage/animaux/chat/chat_2.jpg"]
}, {
    "id": 220,
    "name": "cheval",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cheval"
        },
        "nl": {
            "article": "Het",
            "mainWord": "paard"
        },
        "en": {
            "article": "A",
            "mainWord": "horse"
        },
        "es": {
            "article": "El",
            "mainWord": "caballo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Pferd"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690042417/vocimage/animaux/cheval/horse-7149610_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690042416/vocimage/animaux/cheval/horse-7874312_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690042416/vocimage/animaux/cheval/sunset-3952942_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690042416/vocimage/animaux/cheval/horse-3165771_640.jpg"]
}, {
    "id": 221,
    "name": "chien",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chien"
        },
        "nl": {
            "article": "De",
            "mainWord": "hond"
        },
        "en": {
            "article": "A",
            "mainWord": "dog"
        },
        "es": {
            "article": "El",
            "mainWord": "perro"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hund"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690043485/vocimage/animaux/chien/paw-697837_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690043485/vocimage/animaux/chien/friends-3042751_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690043485/vocimage/animaux/chien/dog-188273_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690043485/vocimage/animaux/chien/dog-606281_640.jpg"]
}, {
    "id": 222,
    "name": "cochon",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cochon"
        },
        "nl": {
            "article": "Het",
            "mainWord": "varken"
        },
        "en": {
            "article": "A",
            "mainWord": "pig"
        },
        "es": {
            "article": "El",
            "mainWord": "cerdo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Schwein"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690103242/vocimage/animaux/cochon/pig-425051_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103242/vocimage/animaux/cochon/piglet-3386356_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103242/vocimage/animaux/cochon/pig-1867180_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687015901/vocimage/animaux/cochon/cochon_2.jpg"]
}, {
    "id": 223,
    "name": "cochon d' inde",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cochon d' inde"
        },
        "nl": {
            "article": "De",
            "mainWord": "cavia"
        },
        "en": {
            "article": "A",
            "mainWord": "guinea pig"
        },
        "es": {
            "article": "El",
            "mainWord": "conejillo de indias"
        },
        "de": {
            "article": "Das",
            "mainWord": "Meerschweinchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690103354/vocimage/animaux/cochon%20d%20inde/guinea-pig-792524_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103354/vocimage/animaux/cochon%20d%20inde/guinea-pig-8084010_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687016116/vocimage/animaux/cochon%20d%20inde/cochon_d_inde_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687016107/vocimage/animaux/cochon%20d%20inde/cochon_d_inde_3.jpg"]
}, {
    "id": 224,
    "name": "coq",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "coq"
        },
        "nl": {
            "article": "De",
            "mainWord": "haan"
        },
        "en": {
            "article": "A",
            "mainWord": "rooster"
        },
        "es": {
            "article": "El",
            "mainWord": "gallo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690103483/vocimage/animaux/coq/faucet-2645271_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103483/vocimage/animaux/coq/cockscomb-7521639_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103483/vocimage/animaux/coq/pile-1651945_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103483/vocimage/animaux/coq/rooster-7288075_640.jpg"]
}, {
    "id": 225,
    "name": "dauphin",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "dauphin"
        },
        "nl": {
            "article": "De",
            "mainWord": "dolfijn"
        },
        "en": {
            "article": "A",
            "mainWord": "dolphin"
        },
        "es": {
            "article": "El",
            "mainWord": "delfín"
        },
        "de": {
            "article": "Der",
            "mainWord": "Delfin"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690103887/vocimage/animaux/dauphin/sculpture-6206946_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690103887/vocimage/animaux/dauphin/dolphin-6881074_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687016210/vocimage/animaux/dauphin/dauphin_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687016207/vocimage/animaux/dauphin/dauphin_2.jpg"]
}, {
    "id": 226,
    "name": "dromadaire",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "dromadaire"
        },
        "nl": {
            "article": "De",
            "mainWord": "dromedaris"
        },
        "en": {
            "article": "A",
            "mainWord": "dromedary"
        },
        "es": {
            "article": "El",
            "mainWord": "dromedario"
        },
        "de": {
            "article": "Das",
            "mainWord": "Dromedar"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690104052/vocimage/animaux/dromadaire/playmobil-6266394_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104052/vocimage/animaux/dromadaire/hairs-630204_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104051/vocimage/animaux/dromadaire/camels-2499965_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690104051/vocimage/animaux/dromadaire/desert-5209678_640.jpg"]
}, {
    "id": 227,
    "name": "hamster",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "hamster"
        },
        "nl": {
            "article": "De",
            "mainWord": "hamster"
        },
        "en": {
            "article": "A",
            "mainWord": "hamster"
        },
        "es": {
            "article": "El",
            "mainWord": "hámster"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hamster"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105073/vocimage/animaux/hamster/hamster-7794393_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105072/vocimage/animaux/hamster/hamster-7757470_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105072/vocimage/animaux/hamster/hamster-5115246_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105072/vocimage/animaux/hamster/hamster-4987120_640.jpg"]
}, {
    "id": 228,
    "name": "hérisson",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "hérisson"
        },
        "nl": {
            "article": "De",
            "mainWord": "egel"
        },
        "en": {
            "article": "A",
            "mainWord": "hedgehog"
        },
        "es": {
            "article": "El",
            "mainWord": "erizo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Igel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105245/vocimage/animaux/herisson/hedgehog-4457982_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105245/vocimage/animaux/herisson/african-1862973_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105245/vocimage/animaux/herisson/costume-7068836_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105245/vocimage/animaux/herisson/hedgehog-5894071_640.jpg"]
}, {
    "id": 229,
    "name": "kangourou",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "kangourou"
        },
        "nl": {
            "article": "De",
            "mainWord": "kangoeroe"
        },
        "en": {
            "article": "A",
            "mainWord": "kangaroo"
        },
        "es": {
            "article": "El",
            "mainWord": "canguro"
        },
        "de": {
            "article": "Das",
            "mainWord": "Känguru"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105618/vocimage/animaux/kangourou/wallaby-4228256_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105549/vocimage/animaux/kangourou/wallabies-4228258_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105549/vocimage/animaux/kangourou/boxing-3849936_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105548/vocimage/animaux/kangourou/wallaby-1658006_640.jpg"]
}, {
    "id": 230,
    "name": "koala",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "koala"
        },
        "nl": {
            "article": "De",
            "mainWord": "koala"
        },
        "en": {
            "article": "A",
            "mainWord": "koala"
        },
        "es": {
            "article": "El",
            "mainWord": "koala"
        },
        "de": {
            "article": "Der",
            "mainWord": "Koala"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105759/vocimage/animaux/koala/australia-4756216_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105759/vocimage/animaux/koala/sydney-opera-house-363244_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105759/vocimage/animaux/koala/bear-1523285_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687016880/vocimage/animaux/koala/koala_2.jpg"]
}, {
    "id": 231,
    "name": "lapin",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "lapin"
        },
        "nl": {
            "article": "Het",
            "mainWord": "konijn"
        },
        "en": {
            "article": "A",
            "mainWord": "rabbit"
        },
        "es": {
            "article": "El",
            "mainWord": "conejo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kaninchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690105873/vocimage/animaux/lapin/australian-shepherd-2208371_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105872/vocimage/animaux/lapin/babies-772439_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105872/vocimage/animaux/lapin/rabbits-339627_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690105872/vocimage/animaux/lapin/grass-3249879_640.jpg"]
}, {
    "id": 232,
    "name": "lion",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "lion"
        },
        "nl": {
            "article": "De",
            "mainWord": "leeuw"
        },
        "en": {
            "article": "A",
            "mainWord": "lion"
        },
        "es": {
            "article": "El",
            "mainWord": "león"
        },
        "de": {
            "article": "Der",
            "mainWord": "Löwe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106068/vocimage/animaux/lion/cat-3809563_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106068/vocimage/animaux/lion/lion-1566179_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106068/vocimage/animaux/lion/lion-2885618_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017155/vocimage/animaux/lion/lion_2.jpg"]
}, {
    "id": 233,
    "name": "loup",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "loup"
        },
        "nl": {
            "article": "De",
            "mainWord": "wolf"
        },
        "en": {
            "article": "A",
            "mainWord": "wolf"
        },
        "es": {
            "article": "El",
            "mainWord": "lobo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Wolf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106120/vocimage/animaux/loup/wolf-1284276_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106120/vocimage/animaux/loup/galaxy-2728187_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017278/vocimage/animaux/loup/loup_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017274/vocimage/animaux/loup/loup_3.jpg"]
}, {
    "id": 234,
    "name": "mouton",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "mouton"
        },
        "nl": {
            "article": "Het",
            "mainWord": "schaap"
        },
        "en": {
            "article": "A",
            "mainWord": "sheep"
        },
        "es": {
            "article": "El",
            "mainWord": "carnero"
        },
        "de": {
            "article": "Der",
            "mainWord": "Schaf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106275/vocimage/animaux/mouton/sheep-5105764_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106275/vocimage/animaux/mouton/salad-754374_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017348/vocimage/animaux/mouton/mouton_3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017344/vocimage/animaux/mouton/mouton_4.jpg"]
}, {
    "id": 235,
    "name": "panda",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "panda"
        },
        "nl": {
            "article": "De",
            "mainWord": "panda"
        },
        "en": {
            "article": "A",
            "mainWord": "panda"
        },
        "es": {
            "article": "El",
            "mainWord": "panda"
        },
        "de": {
            "article": "Der",
            "mainWord": "Panda"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690106788/vocimage/animaux/panda/red-panda-4366264_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106788/vocimage/animaux/panda/pandas-2627426_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690112190/vocimage/animaux/panda/bamboo-20936_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690106788/vocimage/animaux/panda/panda-3828369_640.jpg"]
}, {
    "id": 236,
    "name": "papillon",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "papillon"
        },
        "nl": {
            "article": "De",
            "mainWord": "vlinder"
        },
        "en": {
            "article": "A",
            "mainWord": "butterfly"
        },
        "es": {
            "article": "La",
            "mainWord": "mariposa"
        },
        "de": {
            "article": "Der",
            "mainWord": "Schmetterling"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107159/vocimage/animaux/papillon/caterpillar-7434958_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107159/vocimage/animaux/papillon/chrysalis-140878_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107159/vocimage/animaux/papillon/butterfly-144053_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107159/vocimage/animaux/papillon/fantasy-2049567_640.jpg"]
}, {
    "id": 237,
    "name": "poisson",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "poisson"
        },
        "nl": {
            "article": "De",
            "mainWord": "vis"
        },
        "en": {
            "article": "A",
            "mainWord": "fish"
        },
        "es": {
            "article": "El",
            "mainWord": "pez"
        },
        "de": {
            "article": "Der",
            "mainWord": "Fisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690112428/vocimage/animaux/poisson/fish-2580208_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107416/vocimage/animaux/poisson/lemon-butterflyfish-380037_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107361/vocimage/animaux/poisson/submerged-3235131_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107361/vocimage/animaux/poisson/carp-270376_640.jpg"]
}, {
    "id": 238,
    "name": "renard",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "renard"
        },
        "nl": {
            "article": "De",
            "mainWord": "vos"
        },
        "en": {
            "article": "A",
            "mainWord": "fox"
        },
        "es": {
            "article": "El",
            "mainWord": "zorro"
        },
        "de": {
            "article": "Der",
            "mainWord": "Fuchs"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107600/vocimage/animaux/renard/ai-generated-7466426_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687018522/vocimage/animaux/renard/renard_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687018519/vocimage/animaux/renard/renard_2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687018516/vocimage/animaux/renard/renard_3.jpg"]
}, {
    "id": 239,
    "name": "requin",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "requin"
        },
        "nl": {
            "article": "De",
            "mainWord": "haai"
        },
        "en": {
            "article": "A",
            "mainWord": "shark"
        },
        "es": {
            "article": "El",
            "mainWord": "tiburón"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hai"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107780/vocimage/animaux/requin/whale-shark-281497_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107780/vocimage/animaux/requin/shark-fin-472685_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107780/vocimage/animaux/requin/sharks-1867523_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687018611/vocimage/animaux/requin/requin_4.jpg"]
}, {
    "id": 240,
    "name": "serpent",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "serpent"
        },
        "nl": {
            "article": "De",
            "mainWord": "slang"
        },
        "en": {
            "article": "A",
            "mainWord": "snake"
        },
        "es": {
            "article": "La",
            "mainWord": "serpiente"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schlange"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690107862/vocimage/animaux/serpent/snake-5161215_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107862/vocimage/animaux/serpent/hogwarts-7747098_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690107862/vocimage/animaux/serpent/snake-6704375_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017613/vocimage/animaux/serpent/serpent_2.jpg"]
}, {
    "id": 241,
    "name": "singe",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "singe"
        },
        "nl": {
            "article": "De",
            "mainWord": "aap"
        },
        "en": {
            "article": "A",
            "mainWord": "monkey"
        },
        "es": {
            "article": "El",
            "mainWord": "mono"
        },
        "de": {
            "article": "Der",
            "mainWord": "Affe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690108187/vocimage/animaux/singe/monkey-2790452_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690108068/vocimage/animaux/singe/chimpanzee-718273_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690108068/vocimage/animaux/singe/barbary-macaque-2464195_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017781/vocimage/animaux/singe/singe_4.jpg"]
}, {
    "id": 242,
    "name": "tigre",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tigre"
        },
        "nl": {
            "article": "De",
            "mainWord": "tijger"
        },
        "en": {
            "article": "A",
            "mainWord": "tiger"
        },
        "es": {
            "article": "El",
            "mainWord": "tigre"
        },
        "de": {
            "article": "Der",
            "mainWord": "Tiger"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690109794/vocimage/animaux/tigre/tiger-4437311_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690109725/vocimage/animaux/tigre/tiger-60602_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691353832/vocimage/animaux/tigre/tiger-7601733_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690109449/vocimage/animaux/tigre/tiger-1361079_640.jpg"]
}, {
    "id": 243,
    "name": "zèbre",
    "themeId": 3,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "zèbre"
        },
        "nl": {
            "article": "De",
            "mainWord": "zebra"
        },
        "en": {
            "article": "A",
            "mainWord": "zebra"
        },
        "es": {
            "article": "La",
            "mainWord": "cebra"
        },
        "de": {
            "article": "Das",
            "mainWord": "Zebra"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690110204/vocimage/animaux/zebre/zebra-1289047_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017949/vocimage/animaux/zebre/z%C3%A8bre_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017943/vocimage/animaux/zebre/z%C3%A8bre_3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1687017940/vocimage/animaux/zebre/z%C3%A8bre_4.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}