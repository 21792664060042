import { FETCH_THEMES } from '../actions/themes.js'
export default function(state, action) {
    if (state === undefined) {
        return [];
    }
    switch (action.type) {
        case FETCH_THEMES:
            return action.payload;
        default:
            return state;
    }
}