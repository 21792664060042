import { LOAD_GAME } from '../actions/game.js'
import { CHANGE_IMAGES } from '../actions/image.js'
import { CHOOSE_POSSIBLE_LETTER } from '../actions/letter.js'
import { DISCOVER_LETTER } from '../actions/letter.js'
import { CHOOSE_PLAYER_LETTER } from '../actions/letter.js'
import { REMOVE_PLAYER_WORD } from '../actions/game.js'
import { NEXT_WORD } from '../actions/game.js'
import { SET_FINISHED } from '../actions/game.js'
export default function(state, action) {
    if (state === undefined) {
        return null
    }
    let newState = {};
    switch (action.type) {
        case LOAD_GAME:
            return action.payload;
        case NEXT_WORD:
            newState = {...state};
            newState.wordIds = action.payload.newWordIds;
            newState.word = action.payload.newWord;
            newState.images = action.payload.newImages;
            newState.found = action.payload.newFound;
            newState.possibleLetters = action.payload.newPossibleLetters;
            newState.playerLetters = action.payload.newPlayerLetters;
            newState.wordToFind = action.payload.newWordToFind;
            return newState;
        case CHOOSE_POSSIBLE_LETTER:
            newState = {...state};
            newState.possibleLetters = action.payload.newPossibleLetters;
            newState.playerLetters = action.payload.newPlayerLetters;
            newState.found = action.payload.newFound;
            newState.finished = action.payload.newFinished;
            return newState;
        case DISCOVER_LETTER:
            newState = {...state};
            newState.possibleLetters = action.payload.newPossibleLetters;
            newState.playerLetters = action.payload.newPlayerLetters;
            return newState;
        case CHOOSE_PLAYER_LETTER:
            newState = {...state};
            newState.possibleLetters = action.payload.newPossibleLetters;
            newState.playerLetters = action.payload.newPlayerLetters;
            return newState;
        case REMOVE_PLAYER_WORD:
            newState = {...state};
            newState.possibleLetters = action.payload.newPossibleLetters;
            newState.playerLetters = action.payload.newPlayerLetters;
            return newState;
        case CHANGE_IMAGES:
            newState = {...state};
            newState.images = action.payload
            return newState;
        case SET_FINISHED:
            newState = {...state};
            newState.finished = action.payload
            return newState;
        default:
            return state;
    }
}