import React from 'react';
import Letter from './Letter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChoosePossibleLetter } from '../actions/letter.js';
import { isFullWord } from '../../helpers/letters';

class Possibleletters extends React.Component {

    handleClickPossibleLetter = (possibleLetter) => {
        this.props.ChoosePossibleLetter(possibleLetter, 
                                        this.props.game.found, 
                                        this.props.game.possibleLetters, 
                                        this.props.game.playerLetters, 
                                        this.props.game.wordToFind,
                                        this.props.game.wordIds)
    }

    renderPossibleLetters = () => {
        if (!this.props.game.found && !isFullWord(this.props.game.playerLetters)) {
            return this.props.game.possibleLetters.map(
                possibleLetter => 
                {return <Letter letter={possibleLetter} 
                                key={possibleLetter.position}
                                clickFunction={this.handleClickPossibleLetter} />}
            )
        } else {
            return(<div></div>)
        }
    }

    render() {
        return(
            <div id="possible-letters-container">
                <div className="letters">
                    {this.renderPossibleLetters()}                    
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {game: state.game}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { ChoosePossibleLetter },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Possibleletters);