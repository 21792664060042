import React from 'react';
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 10;

const $accentcolorlight = '#ff8e42';
const $accentcolor = '#ff7c25';
const $accentcolorhover = '#ff6701';
const $accentcolor2 = '#edaa98';
const $accentcolor3 = '#5f2e24';
const $lightcolor = '#f3ede4';
const $darkcolor = '#100808';


class CustomRange extends React.Component {

    constructor(props) {
        super(props);
        this.state={values:[props.currentValue]}
    }

    handleChange = (values) => {
        this.setState({ values });
        this.props.changelevelFunction(values[0]);
    }

    render() {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Range
              values={this.state.values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={values => this.handleChange(values)}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '4px',
                      background: getTrackBackground({
                        values: this.state.values,
                        colors: [$accentcolor, $accentcolor2],  
                        min: MIN,
                        max: MAX
                      }),
                      alignSelf: 'center'
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '84px',
                    width: '84px',
                    borderRadius: '20px',
                    backgroundColor: $lightcolor, 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: `0px 2px 6px ${$accentcolor2}`
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-90px',
                      color: $lightcolor, 
                      fontWeight: 'bold',
                      fontSize: '50px',
                      fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                      padding: '4px',
                      borderRadius: '8px',
                      backgroundColor: $accentcolor 
                    }}
                  >
                    {/* {this.state.values[0].toFixed(1)} */}
                    {this.state.values[0]}
                  </div>
                  <div
                    style={{
                      height: '32px',
                      width: '10px',
                      backgroundColor: isDragged ? $accentcolor : $accentcolor2 
                    }}
                  />
                </div>
              )}
            />
          </div>
        );
    }
}

export default CustomRange;