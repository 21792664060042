import React from 'react';
import Letter from './Letter';
import { translateArticle } from '../../helpers/translations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChoosePlayerLetter } from '../actions/letter.js';

class Playerletters extends React.Component {

    handleClickPlayerLetter = (playerLetter) => {
        this.props.ChoosePlayerLetter(playerLetter, 
                                      this.props.game.found, 
                                      this.props.game.possibleLetters, 
                                      this.props.game.playerLetters)
    }

    renderArticleBloc = () => {
        const translatedArticle = translateArticle(this.props.game.word, this.props.language);
        if (translatedArticle != '') {
            return(
                <div id="article">
                    {translatedArticle}
                </div>
            )
        } else {
            return <div></div>
        }

    }

    renderPlayerLetters = () => {
        return this.props.game.playerLetters.map(
            playerLetter => 
            {return <Letter letter={playerLetter} 
                            key={playerLetter.position}
                            clickFunction={this.handleClickPlayerLetter} />}
        )
    }

    render() {
        return(
            <div id="full-player-letters">
                {this.renderArticleBloc()}
                <div className="letters">
                    {this.renderPlayerLetters()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {game: state.game, 
            language: state.language}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { ChoosePlayerLetter },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Playerletters);