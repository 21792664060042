const indexFirstFreeLetter = (letters) => {
    return letters.findIndex(letter => letter.visible === false);
}

const freeLettersCount = (letters) => {
    return letters.filter(letter => letter.visible === false).length;
}

const isTimeToCheckLetters = (letters) => {
    return freeLettersCount(letters) === 1 ? true : false
}

const isFullWord = (letters) => {
    return freeLettersCount(letters) === 0 ? true : false
}

export {indexFirstFreeLetter, freeLettersCount, isTimeToCheckLetters, isFullWord}