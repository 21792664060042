const words = 
[{
    "id": 2301,
    "name": "Carnaval",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Carnaval"
        },
        "nl": {
            "article": "",
            "mainWord": "Carnaval"
        },
        "en": {
            "article": "",
            "mainWord": "Carnival"
        },
        "es": {
            "article": "",
            "mainWord": "Carnaval"
        },
        "de": {
            "article": "",
            "mainWord": "Karneval"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691229519/vocimage/carnaval/carnaval/streamer-3099550_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229519/vocimage/carnaval/carnaval/parade-4879243_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229519/vocimage/carnaval/carnaval/carnival-1256340_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229519/vocimage/carnaval/carnaval/old-man-4174236_640.jpg"]
}, {
    "id": 2302,
    "name": "crêpe",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "crêpe"
        },
        "nl": {
            "article": "De",
            "mainWord": "pannenkoek"
        },
        "en": {
            "article": "A",
            "mainWord": "crepe"
        },
        "es": {
            "article": "El",
            "mainWord": "crepe"
        },
        "de": {
            "article": "Der",
            "mainWord": "Pfannkuchen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691230082/vocimage/carnaval/crepe/pancakes-2020870_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230082/vocimage/carnaval/crepe/pancakes-2020863_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230082/vocimage/carnaval/crepe/pancakes-282239_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230083/vocimage/carnaval/crepe/pancakes-2372095_640.jpg"]
}, {
    "id": 2303,
    "name": "parade",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "parade"
        },
        "nl": {
            "article": "De",
            "mainWord": "parade"
        },
        "en": {
            "article": "A",
            "mainWord": "parade"
        },
        "es": {
            "article": "El",
            "mainWord": "desfile"
        },
        "de": {
            "article": "Die",
            "mainWord": "Parade"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691230914/vocimage/carnaval/parade/carnival-280407_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230914/vocimage/carnaval/parade/aalst-2107036_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230914/vocimage/carnaval/parade/platzler-1187779_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230914/vocimage/carnaval/parade/mardi-gras-1090720_640.jpg"]
}, {
    "id": 2304,
    "name": "perruque",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "perruque"
        },
        "nl": {
            "article": "De",
            "mainWord": "pruik"
        },
        "en": {
            "article": "A",
            "mainWord": "wig"
        },
        "es": {
            "article": "La",
            "mainWord": "peluca"
        },
        "de": {
            "article": "Die",
            "mainWord": "Perücke"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691231153/vocimage/carnaval/perruque/dog-1776721_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691231153/vocimage/carnaval/perruque/wig-23086_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691231153/vocimage/carnaval/perruque/wigs-2224880_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691231153/vocimage/carnaval/perruque/people-913988_640.jpg"]
}, {
    "id": 2305,
    "name": "arlequin",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "arlequin"
        },
        "nl": {
            "article": "",
            "mainWord": "Harlequin"
        },
        "en": {
            "article": "A",
            "mainWord": "harlequin"
        },
        "es": {
            "article": "El",
            "mainWord": "arlequín"
        },
        "de": {
            "article": "Der",
            "mainWord": "Harlekin"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691229197/vocimage/carnaval/arlequin/harlequin-4477917_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229197/vocimage/carnaval/arlequin/carnival-5156635_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229197/vocimage/carnaval/arlequin/basler-fasnacht-3273346_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229312/vocimage/carnaval/arlequin/arlequin2.jpg"]
}, {
    "id": 2306,
    "name": "beignet",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "beignet"
        },
        "nl": {
            "article": "De",
            "mainWord": "beignet"
        },
        "en": {
            "article": "A",
            "mainWord": "beignet"
        },
        "es": {
            "article": "El",
            "mainWord": "buñuelo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Krapfen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691228821/vocimage/carnaval/beignet/donuts-5961003_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228822/vocimage/carnaval/beignet/donuts-4046579_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228822/vocimage/carnaval/beignet/christmas-314945_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228822/vocimage/carnaval/beignet/doughnut-3504185_640.jpg"]
}, {
    "id": 2307,
    "name": "char",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "char"
        },
        "nl": {
            "article": "De",
            "mainWord": "praalwagen"
        },
        "en": {
            "article": "A",
            "mainWord": "float"
        },
        "es": {
            "article": "La",
            "mainWord": "carroza"
        },
        "de": {
            "article": "Der",
            "mainWord": "Karnevalswagen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691229821/vocimage/carnaval/char/samba-school-4967955_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229821/vocimage/carnaval/char/chaar3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229821/vocimage/carnaval/char/char2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229821/vocimage/carnaval/char/char1.jpg"]
}, {
    "id": 2308,
    "name": "clown",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "clown"
        },
        "nl": {
            "article": "De",
            "mainWord": "clown"
        },
        "en": {
            "article": "A",
            "mainWord": "clown"
        },
        "es": {
            "article": "El",
            "mainWord": "payaso"
        },
        "de": {
            "article": "Der",
            "mainWord": "Clown"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691229991/vocimage/carnaval/clown/ball-706757_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229991/vocimage/carnaval/clown/clowns-1810364_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229991/vocimage/carnaval/clown/clowns-circus-2278906_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691229992/vocimage/carnaval/clown/hula-162558_640.jpg"]
}, {
    "id": 2309,
    "name": "déguisement",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "déguisement"
        },
        "nl": {
            "article": "De",
            "mainWord": "vermomming"
        },
        "en": {
            "article": "A",
            "mainWord": "disguise costume"
        },
        "es": {
            "article": "El",
            "mainWord": "disfraz"
        },
        "de": {
            "article": "Das",
            "mainWord": "Kostüm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691230370/vocimage/carnaval/deguisemnent/costume-1965383_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230370/vocimage/carnaval/deguisemnent/child-913077_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230370/vocimage/carnaval/deguisemnent/renaissance-367236_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230370/vocimage/carnaval/deguisemnent/easter-bunny-1259561_640.jpg"]
}, {
    "id": 2310,
    "name": "Gille",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "Gille"
        },
        "nl": {
            "article": "De",
            "mainWord": "Gille"
        },
        "en": {
            "article": "A",
            "mainWord": "Gille"
        },
        "es": {
            "article": "El",
            "mainWord": "Gille"
        },
        "de": {
            "article": "Der",
            "mainWord": "Gille"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691230510/vocimage/carnaval/gille/gilles-695678_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230510/vocimage/carnaval/gille/gille2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230510/vocimage/carnaval/gille/gille3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230513/vocimage/carnaval/gille/gille1.jpg"]
}, {
    "id": 2311,
    "name": "maquillage",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "maquillage"
        },
        "nl": {
            "article": "De",
            "mainWord": "makeup"
        },
        "en": {
            "article": "A",
            "mainWord": "make-up"
        },
        "es": {
            "article": "El",
            "mainWord": "maquillaje"
        },
        "de": {
            "article": "Das",
            "mainWord": "Makeup"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691230636/vocimage/carnaval/maquillage/masked-ball-1176144_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230636/vocimage/carnaval/maquillage/child-3197091_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230636/vocimage/carnaval/maquillage/ai-generated-8007808_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691230636/vocimage/carnaval/maquillage/disguise-8166618_640.jpg"]
}, {
    "id": 2312,
    "name": "masque",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "masque"
        },
        "nl": {
            "article": "Het",
            "mainWord": "masker"
        },
        "en": {
            "article": "A",
            "mainWord": "mask"
        },
        "es": {
            "article": "La",
            "mainWord": "máscara"
        },
        "de": {
            "article": "Die",
            "mainWord": "Maske"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691228835/vocimage/carnaval/masque/masque_.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228835/vocimage/carnaval/masque/venice-3403354_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228835/vocimage/carnaval/masque/masque8.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228835/vocimage/carnaval/masque/fasnet-4874054_640.jpg"]
}, {
    "id": 2313,
    "name": "confettis",
    "themeId": 24,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "confettis"
        },
        "nl": {
            "article": "De",
            "mainWord": "confetti"
        },
        "en": {
            "article": "",
            "mainWord": "Confetti"
        },
        "es": {
            "article": "Los",
            "mainWord": "confettis"
        },
        "de": {
            "article": "Das",
            "mainWord": "Konfetti"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691228793/vocimage/carnaval/confettis/carnival-3076952_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228793/vocimage/carnaval/confettis/confetti-985152_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228793/vocimage/carnaval/confettis/woman-6318447_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691228793/vocimage/carnaval/confettis/wig-3128515_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}