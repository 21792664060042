const translatedWord = (word, language) => {
    switch (language.name) {
        case 'nl':
            return word.translations.nl.mainWord
        case 'fr':
            return word.translations.fr.mainWord
        case 'en':
            return word.translations.en.mainWord
        case 'es':
            return word.translations.es.mainWord
        case 'de':
            return word.translations.de.mainWord
        default:
            return word.translations.nl.mainWord
    }
}

const translateArticle = (word, language) => {
    if (word.translations === undefined) {
        return ''
    }
    switch (language.name) {
        case 'nl':
            return word.translations.nl.article
        case 'fr':
            return word.translations.fr.article
        case 'en':
            return word.translations.en.article
        case 'es':
            return word.translations.es.article
        case 'de':
            return word.translations.de.article
        default:
            return word.translations.nl.article
    }
}

export {translatedWord, translateArticle};

