import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard } from '@fortawesome/free-solid-svg-icons';


class Wallet extends React.Component {

    renderIcon = () => {
        switch (this.props.sizeicon) {
            case 1:                
                return <FontAwesomeIcon icon={faHatWizard}/>
            case 2:
                return <FontAwesomeIcon icon={faHatWizard} size="2x"/>
            case 3:
                return <FontAwesomeIcon icon={faHatWizard} size="3x"/>
            default:
                return <FontAwesomeIcon icon={faHatWizard}/>
        }
    }

    render() {
        return(
            <div className="flex-container-align">
                {this.renderIcon()}
                <span className="badge badge-dark">{this.props.wallet}</span>    
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {wallet: state.wallet}
}


export default connect(mapStateToProps) (Wallet);