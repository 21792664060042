const words = 
[{
    "id": 1501,
    "name": "Acheter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Acheter"
        },
        "nl": {
            "article": "",
            "mainWord": "Kopen"
        },
        "en": {
            "article": "To",
            "mainWord": "buy"
        },
        "es": {
            "article": "",
            "mainWord": "Comprar"
        },
        "de": {
            "article": "",
            "mainWord": "Kaufen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083311/vocimage/verbes/acheter/shopping-1761233__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083311/vocimage/verbes/acheter/woman-169286__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083311/vocimage/verbes/acheter/shopping-1165437__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083579/vocimage/verbes/acheter/online-4275963_640.jpg"]
}, {
    "id": 1502,
    "name": "Aider",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Aider"
        },
        "nl": {
            "article": "",
            "mainWord": "Helpen"
        },
        "en": {
            "article": "To",
            "mainWord": "help"
        },
        "es": {
            "article": "",
            "mainWord": "Ayudar"
        },
        "de": {
            "article": "",
            "mainWord": "Helfen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691084430/vocimage/verbes/aider/aider1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084430/vocimage/verbes/aider/wheelchair-2090900_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084430/vocimage/verbes/aider/hand-838975_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084430/vocimage/verbes/aider/help.jpg"]
}, {
    "id": 1503,
    "name": "Aimer",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Aimer"
        },
        "nl": {
            "article": "",
            "mainWord": "Houden van"
        },
        "en": {
            "article": "To",
            "mainWord": "love"
        },
        "es": {
            "article": "",
            "mainWord": "Querer"
        },
        "de": {
            "article": "",
            "mainWord": "Lieben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083384/vocimage/verbes/aimer/brothers-457234__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084574/vocimage/verbes/aimer/outdoors-3360206_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084574/vocimage/verbes/aimer/heart-3147976_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084574/vocimage/verbes/aimer/facebook-3428719_640.jpg"]
}, {
    "id": 1504,
    "name": "Apprendre",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Apprendre"
        },
        "nl": {
            "article": "",
            "mainWord": "Leren"
        },
        "en": {
            "article": "To",
            "mainWord": "learn"
        },
        "es": {
            "article": "",
            "mainWord": "Aprender"
        },
        "de": {
            "article": "",
            "mainWord": "Lernen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083291/vocimage/verbes/apprendre/child-558798__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084655/vocimage/verbes/apprendre/school-1782427_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084655/vocimage/verbes/apprendre/students-99506_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084717/vocimage/verbes/apprendre/teacher-4784916_640.jpg"]
}, {
    "id": 1505,
    "name": "Boire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Boire"
        },
        "nl": {
            "article": "",
            "mainWord": "Drinken"
        },
        "en": {
            "article": "To",
            "mainWord": "drink"
        },
        "es": {
            "article": "",
            "mainWord": "Beber"
        },
        "de": {
            "article": "",
            "mainWord": "Trinken"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083480/vocimage/verbes/boire/cat-2596030__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083480/vocimage/verbes/boire/little-102283__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084861/vocimage/verbes/boire/flow-3528344_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084861/vocimage/verbes/boire/girl-7008608_640.jpg"]
}, {
    "id": 1506,
    "name": "Chanter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Chanter"
        },
        "nl": {
            "article": "",
            "mainWord": "Zingen"
        },
        "en": {
            "article": "To",
            "mainWord": "sing"
        },
        "es": {
            "article": "",
            "mainWord": "Cantar"
        },
        "de": {
            "article": "",
            "mainWord": "Singen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082804/vocimage/verbes/chanter/music-594275__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082805/vocimage/verbes/chanter/girl-15754__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082805/vocimage/verbes/chanter/prairie-dog-1470659__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691084904/vocimage/verbes/chanter/small-bird-2224946_640.jpg"]
}, {
    "id": 1507,
    "name": "Coller",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Coller"
        },
        "nl": {
            "article": "",
            "mainWord": "Plakken"
        },
        "en": {
            "article": "To",
            "mainWord": "stick"
        },
        "es": {
            "article": "",
            "mainWord": "Pegar"
        },
        "de": {
            "article": "",
            "mainWord": "Kleben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083160/vocimage/verbes/coller/repair-891422__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083160/vocimage/verbes/coller/sticky-notes-1455911__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085014/vocimage/verbes/coller/wood-glue-596161_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085014/vocimage/verbes/coller/paste-4964886_640.jpg"]
}, {
    "id": 1508,
    "name": "Colorier",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Colorier"
        },
        "nl": {
            "article": "",
            "mainWord": "Kleuren"
        },
        "en": {
            "article": "To",
            "mainWord": "colour"
        },
        "es": {
            "article": "",
            "mainWord": "Colorear"
        },
        "de": {
            "article": "",
            "mainWord": "Färben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083350/vocimage/verbes/colorier/paint-2759213__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083350/vocimage/verbes/colorier/coloring-book-for-adults-1396827__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083350/vocimage/verbes/colorier/crayon-3670544__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085076/vocimage/verbes/colorier/cat-1682664_640.jpg"]
}, {
    "id": 1509,
    "name": "Comprendre",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Comprendre"
        },
        "nl": {
            "article": "",
            "mainWord": "Begrijpen"
        },
        "en": {
            "article": "To",
            "mainWord": "understand"
        },
        "es": {
            "article": "",
            "mainWord": "Entender"
        },
        "de": {
            "article": "",
            "mainWord": "Verstehen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691085348/vocimage/verbes/comprendre/comprendre.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085349/vocimage/verbes/comprendre/comprendre1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085349/vocimage/verbes/comprendre/dog-4663670_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085437/vocimage/verbes/comprendre/comprendre5.png"]
}, {
    "id": 1510,
    "name": "Conduire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Conduire"
        },
        "nl": {
            "article": "",
            "mainWord": "Rijden"
        },
        "en": {
            "article": "To",
            "mainWord": "drive"
        },
        "es": {
            "article": "",
            "mainWord": "Conducir"
        },
        "de": {
            "article": "",
            "mainWord": "Fahren"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083129/vocimage/verbes/conduire/land-rover-89210__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083129/vocimage/verbes/conduire/bumper-car-2369930__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085522/vocimage/verbes/conduire/drive-863123_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085523/vocimage/verbes/conduire/driving-2732934_640.jpg"]
}, {
    "id": 1511,
    "name": "Construire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Construire"
        },
        "nl": {
            "article": "",
            "mainWord": "Bouwen"
        },
        "en": {
            "article": "To",
            "mainWord": "build"
        },
        "es": {
            "article": "",
            "mainWord": "Construir"
        },
        "de": {
            "article": "",
            "mainWord": "Bauen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083490/vocimage/verbes/construire/new-home-1664272__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083490/vocimage/verbes/construire/construction-257326__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085735/vocimage/verbes/construire/lego-516557_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085735/vocimage/verbes/construire/mason-2376325_640.jpg"]
}, {
    "id": 1512,
    "name": "Couper",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Couper"
        },
        "nl": {
            "article": "",
            "mainWord": "Knippen"
        },
        "en": {
            "article": "To",
            "mainWord": "cut"
        },
        "es": {
            "article": "",
            "mainWord": "Cortar"
        },
        "de": {
            "article": "",
            "mainWord": "Schneiden"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083326/vocimage/verbes/couper/hairdressing-1516352__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083326/vocimage/verbes/couper/cord-4088111__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085887/vocimage/verbes/couper/hairdresser-3173438_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085887/vocimage/verbes/couper/scissors-2759721_640.jpg"]
}, {
    "id": 1513,
    "name": "Cuisiner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Cuisiner"
        },
        "nl": {
            "article": "",
            "mainWord": "Koken"
        },
        "en": {
            "article": "To",
            "mainWord": "cook"
        },
        "es": {
            "article": "",
            "mainWord": "Cocinar"
        },
        "de": {
            "article": "",
            "mainWord": "Kochen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691085988/vocimage/verbes/cuisiner/restaurant-1284351_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085988/vocimage/verbes/cuisiner/woman-3723444_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085988/vocimage/verbes/cuisiner/chef-5993951_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691085989/vocimage/verbes/cuisiner/baking-1951256_640.jpg"]
}, {
    "id": 1514,
    "name": "Danser",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Danser"
        },
        "nl": {
            "article": "",
            "mainWord": "Dansen"
        },
        "en": {
            "article": "To",
            "mainWord": "dance"
        },
        "es": {
            "article": "",
            "mainWord": "Bailar"
        },
        "de": {
            "article": "",
            "mainWord": "Tanzen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691086063/vocimage/verbes/danser/ease-3134828_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086063/vocimage/verbes/danser/dancers-7731738_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086063/vocimage/verbes/danser/dancer-5576002_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086063/vocimage/verbes/danser/couple-7938190_640.jpg"]
}, {
    "id": 1515,
    "name": "Descendre",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Descendre"
        },
        "nl": {
            "article": "",
            "mainWord": "Afgaan"
        },
        "en": {
            "article": "To",
            "mainWord": "go down"
        },
        "es": {
            "article": "",
            "mainWord": "Bajar"
        },
        "de": {
            "article": "",
            "mainWord": "Nach unten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691145101/vocimage/verbes/descendre/sea-2880381_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145253/vocimage/verbes/descendre/person-1218190_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145254/vocimage/verbes/descendre/mountain-bike-1748297_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145254/vocimage/verbes/descendre/to-climb-the-stairs-3855_640.jpg"]
}, {
    "id": 1516,
    "name": "Dessiner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Dessiner"
        },
        "nl": {
            "article": "",
            "mainWord": "Tekenen"
        },
        "en": {
            "article": "To",
            "mainWord": "draw"
        },
        "es": {
            "article": "",
            "mainWord": "Dibujar"
        },
        "de": {
            "article": "",
            "mainWord": "Zeichnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082978/vocimage/verbes/dessiner/oekaki-2009817__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086216/vocimage/verbes/dessiner/art-256895_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086216/vocimage/verbes/dessiner/pencil-1209528_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086257/vocimage/verbes/dessiner/street-chalk-1761052_640.jpg"]
}, {
    "id": 1517,
    "name": "Donner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Donner"
        },
        "nl": {
            "article": "",
            "mainWord": "Geven"
        },
        "en": {
            "article": "To",
            "mainWord": "give"
        },
        "es": {
            "article": "",
            "mainWord": "Dar"
        },
        "de": {
            "article": "",
            "mainWord": "Geben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691086356/vocimage/verbes/donner/friends-3282275_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086356/vocimage/verbes/donner/hand-1549224_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086356/vocimage/verbes/donner/succulents-2347550_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086378/vocimage/verbes/donner/paloma-3713731_640.jpg"]
}, {
    "id": 1518,
    "name": "Dormir",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Dormir"
        },
        "nl": {
            "article": "",
            "mainWord": "Slapen"
        },
        "en": {
            "article": "To",
            "mainWord": "sleep"
        },
        "es": {
            "article": "",
            "mainWord": "Dormir"
        },
        "de": {
            "article": "",
            "mainWord": "Schlafen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691086469/vocimage/verbes/dormir/sleeping-1311784_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086469/vocimage/verbes/dormir/fox-1284512_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086469/vocimage/verbes/dormir/cat-3971068_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086469/vocimage/verbes/dormir/newborn-6177485_640.jpg"]
}, {
    "id": 1519,
    "name": "Ecouter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Ecouter"
        },
        "nl": {
            "article": "",
            "mainWord": "Luisteren"
        },
        "en": {
            "article": "To",
            "mainWord": "listen"
        },
        "es": {
            "article": "",
            "mainWord": "Escuchar"
        },
        "de": {
            "article": "",
            "mainWord": "Hören"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083223/vocimage/verbes/ecouter/sculpture-3365574__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086579/vocimage/verbes/ecouter/event-852833_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086579/vocimage/verbes/ecouter/girl-3231703_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086579/vocimage/verbes/ecouter/samsung-605439_640.jpg"]
}, {
    "id": 1520,
    "name": "Ecrire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Ecrire"
        },
        "nl": {
            "article": "",
            "mainWord": "Schrijven"
        },
        "en": {
            "article": "To",
            "mainWord": "write"
        },
        "es": {
            "article": "",
            "mainWord": "Escribir"
        },
        "de": {
            "article": "",
            "mainWord": "Schreiben"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691086639/vocimage/verbes/ecrire/man-5710164_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086639/vocimage/verbes/ecrire/notebook-1840276_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086639/vocimage/verbes/ecrire/pen-1743189_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086640/vocimage/verbes/ecrire/study-1412778_640.jpg"]
}, {
    "id": 1521,
    "name": "Etudier",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Etudier"
        },
        "nl": {
            "article": "",
            "mainWord": "Studeren"
        },
        "en": {
            "article": "To",
            "mainWord": "study"
        },
        "es": {
            "article": "",
            "mainWord": "Estudiar"
        },
        "de": {
            "article": "",
            "mainWord": "Studieren"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083505/vocimage/verbes/etudier/studying-951818__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083505/vocimage/verbes/etudier/people-2557396__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083505/vocimage/verbes/etudier/girl-3718526__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691086749/vocimage/verbes/etudier/woman-4118058_640.jpg"]
}, {
    "id": 1522,
    "name": "Fermer",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Fermer"
        },
        "nl": {
            "article": "",
            "mainWord": "Sluiten"
        },
        "en": {
            "article": "To",
            "mainWord": "close"
        },
        "es": {
            "article": "",
            "mainWord": "Cerrar"
        },
        "de": {
            "article": "",
            "mainWord": "Schließen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691087013/vocimage/verbes/fermer/close-up-4521072_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087014/vocimage/verbes/fermer/fermer.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087014/vocimage/verbes/fermer/lock-1516241_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087140/vocimage/verbes/fermer/fermer1.jpg"]
}, {
    "id": 1523,
    "name": "Gagner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Gagner"
        },
        "nl": {
            "article": "",
            "mainWord": "Winnen"
        },
        "en": {
            "article": "To",
            "mainWord": "win"
        },
        "es": {
            "article": "",
            "mainWord": "Ganar"
        },
        "de": {
            "article": "",
            "mainWord": "Gewinnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691087257/vocimage/verbes/gagner/children-593313_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087257/vocimage/verbes/gagner/champions-1411861_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087257/vocimage/verbes/gagner/cyclist-4369865_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087304/vocimage/verbes/gagner/victory-863742_640.jpg"]
}, {
    "id": 1524,
    "name": "Grimper",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Grimper"
        },
        "nl": {
            "article": "",
            "mainWord": "Klimmen"
        },
        "en": {
            "article": "To",
            "mainWord": "klimb"
        },
        "es": {
            "article": "",
            "mainWord": "Trepar"
        },
        "de": {
            "article": "",
            "mainWord": "Klettern"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083339/vocimage/verbes/grimper/human-3315329__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083339/vocimage/verbes/grimper/cat-1134615__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083339/vocimage/verbes/grimper/bear-79838__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083471/vocimage/verbes/grimper/girl-1186895__340.jpg"]
}, {
    "id": 1525,
    "name": "Habiter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Habiter"
        },
        "nl": {
            "article": "",
            "mainWord": "Wonen"
        },
        "en": {
            "article": "To",
            "mainWord": "live"
        },
        "es": {
            "article": "",
            "mainWord": "Vivir"
        },
        "de": {
            "article": "",
            "mainWord": "Wohnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082825/vocimage/verbes/habiter/hotel-exterior-2862818__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087643/vocimage/verbes/habiter/real-estate-6688945_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087643/vocimage/verbes/habiter/habitants.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087643/vocimage/verbes/habiter/at-home-1001368_640.jpg"]
}, {
    "id": 1526,
    "name": "Interroger",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Interroger"
        },
        "nl": {
            "article": "",
            "mainWord": "Vragen"
        },
        "en": {
            "article": "To",
            "mainWord": "ask"
        },
        "es": {
            "article": "",
            "mainWord": "Preguntar"
        },
        "de": {
            "article": "",
            "mainWord": "Erkundigen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082867/vocimage/verbes/interroger/faq-2639667__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087932/vocimage/verbes/interroger/turn-on-2925962_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087932/vocimage/verbes/interroger/primate-460871_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087932/vocimage/verbes/interroger/student.jpg"]
}, {
    "id": 1527,
    "name": "Jouer",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Jouer"
        },
        "nl": {
            "article": "",
            "mainWord": "Spelen"
        },
        "en": {
            "article": "To",
            "mainWord": "play"
        },
        "es": {
            "article": "",
            "mainWord": "Jugar"
        },
        "de": {
            "article": "",
            "mainWord": "Spielen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083094/vocimage/verbes/jouer/baby-84552__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083094/vocimage/verbes/jouer/water-fight-442257__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083094/vocimage/verbes/jouer/cat-4052454__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691087974/vocimage/verbes/jouer/children-5833685_640.jpg"]
}, {
    "id": 1528,
    "name": "Laver",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Laver"
        },
        "nl": {
            "article": "",
            "mainWord": "Wassen"
        },
        "en": {
            "article": "To",
            "mainWord": "wash"
        },
        "es": {
            "article": "",
            "mainWord": "Lavar"
        },
        "de": {
            "article": "",
            "mainWord": "Waschen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082856/vocimage/verbes/laver/cat-2641789__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144330/vocimage/verbes/laver/water-flow-2464417_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144330/vocimage/verbes/laver/washing-car-1397382_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144354/vocimage/verbes/laver/feet-1868670_640.jpg"]
}, {
    "id": 1529,
    "name": "Lire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Lire"
        },
        "nl": {
            "article": "",
            "mainWord": "Lezen"
        },
        "en": {
            "article": "To",
            "mainWord": "read"
        },
        "es": {
            "article": "",
            "mainWord": "Leer"
        },
        "de": {
            "article": "",
            "mainWord": "Lesen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083262/vocimage/verbes/lire/girl-3528292__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144427/vocimage/verbes/lire/boy-5731001_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144427/vocimage/verbes/lire/reading-925589_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144427/vocimage/verbes/lire/child-3046494_640.jpg"]
}, {
    "id": 1530,
    "name": "Manger",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Manger"
        },
        "nl": {
            "article": "",
            "mainWord": "Eten"
        },
        "en": {
            "article": "To",
            "mainWord": "eat"
        },
        "es": {
            "article": "",
            "mainWord": "Comer"
        },
        "de": {
            "article": "",
            "mainWord": "Essen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083454/vocimage/verbes/manger/social-work-2356009__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083454/vocimage/verbes/manger/horse-snout-3316368__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144478/vocimage/verbes/manger/french-fries-1851143_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144479/vocimage/verbes/manger/girl-846357_640.jpg"]
}, {
    "id": 1531,
    "name": "Marcher",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Marcher"
        },
        "nl": {
            "article": "",
            "mainWord": "Lopen"
        },
        "en": {
            "article": "To",
            "mainWord": "walk"
        },
        "es": {
            "article": "",
            "mainWord": "Andar"
        },
        "de": {
            "article": "",
            "mainWord": "Gehen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083247/vocimage/verbes/marcher/balloons-388973__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083247/vocimage/verbes/marcher/woman-892309__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083247/vocimage/verbes/marcher/adult-1867702__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144517/vocimage/verbes/marcher/legs-2635038_640.jpg"]
}, {
    "id": 1532,
    "name": "Monter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Monter"
        },
        "nl": {
            "article": "",
            "mainWord": "Opgaan"
        },
        "en": {
            "article": "To",
            "mainWord": "go up"
        },
        "es": {
            "article": "",
            "mainWord": "Subir"
        },
        "de": {
            "article": "",
            "mainWord": "Hinaufgehen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083181/vocimage/verbes/monter/young-people-4527035__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083181/vocimage/verbes/monter/escalator-2252517__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144644/vocimage/verbes/monter/woman-6950384_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691144644/vocimage/verbes/monter/children-748327_640.jpg"]
}, {
    "id": 1533,
    "name": "Nager",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Nager"
        },
        "nl": {
            "article": "",
            "mainWord": "Zwemmen"
        },
        "en": {
            "article": "To",
            "mainWord": "swim"
        },
        "es": {
            "article": "",
            "mainWord": "Nadar"
        },
        "de": {
            "article": "",
            "mainWord": "Schwimmen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082773/vocimage/verbes/nager/swimming-821622__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145400/vocimage/verbes/nager/swimming-97509_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145400/vocimage/verbes/nager/staniel-cay-171908_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145400/vocimage/verbes/nager/girls-1284419_640.jpg"]
}, {
    "id": 1534,
    "name": "Ouvrir",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Ouvrir"
        },
        "nl": {
            "article": "",
            "mainWord": "Openen"
        },
        "en": {
            "article": "To",
            "mainWord": "open"
        },
        "es": {
            "article": "",
            "mainWord": "Abrir"
        },
        "de": {
            "article": "",
            "mainWord": "Öffnen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083197/vocimage/verbes/ouvrir/mediterranean-2059092__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145640/vocimage/verbes/ouvrir/chest-4507343_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145640/vocimage/verbes/ouvrir/door-2390293_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145640/vocimage/verbes/ouvrir/tools-2836961_640.jpg"]
}, {
    "id": 1535,
    "name": "Parler",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Parler"
        },
        "nl": {
            "article": "",
            "mainWord": "Spreken"
        },
        "en": {
            "article": "To",
            "mainWord": "speak"
        },
        "es": {
            "article": "",
            "mainWord": "Hablar"
        },
        "de": {
            "article": "",
            "mainWord": "Sprechen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083046/vocimage/verbes/parler/woman-420750__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083046/vocimage/verbes/parler/women-2586042__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145856/vocimage/verbes/parler/anishia-davis-876169_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691145856/vocimage/verbes/parler/obama-356133_640.jpg"]
}, {
    "id": 1536,
    "name": "Patiner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Patiner"
        },
        "nl": {
            "article": "",
            "mainWord": "Schaatsen"
        },
        "en": {
            "article": "To",
            "mainWord": "skate"
        },
        "es": {
            "article": "",
            "mainWord": "Patinar"
        },
        "de": {
            "article": "",
            "mainWord": "Sclitschuhlaufen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083115/vocimage/verbes/patiner/roll-5078985__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083115/vocimage/verbes/patiner/rotkappchen-3833915__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083115/vocimage/verbes/patiner/roller-skates-4032563__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691149303/vocimage/verbes/patiner/ice-skating-235547_640.jpg"]
}, {
    "id": 1537,
    "name": "Pleurer",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Pleurer"
        },
        "nl": {
            "article": "",
            "mainWord": "Huilen"
        },
        "en": {
            "article": "To",
            "mainWord": "cry"
        },
        "es": {
            "article": "",
            "mainWord": "Llorar"
        },
        "de": {
            "article": "",
            "mainWord": "Weinen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083395/vocimage/verbes/pleurer/cry-2010585__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146056/vocimage/verbes/pleurer/people-2566201_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146056/vocimage/verbes/pleurer/crying-baby-2708380_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146071/vocimage/verbes/pleurer/puppet-3650108_640.jpg"]
}, {
    "id": 1538,
    "name": "Prendre",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Prendre"
        },
        "nl": {
            "article": "",
            "mainWord": "Nemen"
        },
        "en": {
            "article": "To",
            "mainWord": "take"
        },
        "es": {
            "article": "",
            "mainWord": "Coger"
        },
        "de": {
            "article": "",
            "mainWord": "Nehmen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691146434/vocimage/verbes/prendre/prendre3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146434/vocimage/verbes/prendre/hand-3408591_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146434/vocimage/verbes/prendre/prendre.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146434/vocimage/verbes/prendre/prendre1.jpg"]
}, {
    "id": 1539,
    "name": "Regarder",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Regarder"
        },
        "nl": {
            "article": "",
            "mainWord": "Kijken"
        },
        "en": {
            "article": "To",
            "mainWord": "watch"
        },
        "es": {
            "article": "",
            "mainWord": "Mirar"
        },
        "de": {
            "article": "",
            "mainWord": "Schauen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083366/vocimage/verbes/regarder/woman-1151562__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083366/vocimage/verbes/regarder/adult-3086304__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146555/vocimage/verbes/regarder/virtual-reality-4490469_1920.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146555/vocimage/verbes/regarder/meerkat-1097581_640.jpg"]
}, {
    "id": 1540,
    "name": "Rire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Rire"
        },
        "nl": {
            "article": "",
            "mainWord": "Lachen"
        },
        "en": {
            "article": "To",
            "mainWord": "laugh"
        },
        "es": {
            "article": "",
            "mainWord": "Reír"
        },
        "de": {
            "article": "",
            "mainWord": "Lachen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083300/vocimage/verbes/rire/laughter-1369402__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146646/vocimage/verbes/rire/couple-1838940_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146646/vocimage/verbes/rire/boy-1854308_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146646/vocimage/verbes/rire/horse-1333937_640.jpg"]
}, {
    "id": 1541,
    "name": "S' asseoir",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "S' asseoir"
        },
        "nl": {
            "article": "",
            "mainWord": "Zitten"
        },
        "en": {
            "article": "To",
            "mainWord": "sit"
        },
        "es": {
            "article": "",
            "mainWord": "Sentarse"
        },
        "de": {
            "article": "",
            "mainWord": "Sich setzen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082794/vocimage/verbes/s%20asseoir/baby-3386242__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146833/vocimage/verbes/s%20asseoir/girl-1906187_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146833/vocimage/verbes/s%20asseoir/dog-4231267_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146833/vocimage/verbes/s%20asseoir/child-3858368_640.jpg"]
}, {
    "id": 1542,
    "name": "Sauter",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Sauter"
        },
        "nl": {
            "article": "",
            "mainWord": "Springen"
        },
        "en": {
            "article": "To",
            "mainWord": "jump"
        },
        "es": {
            "article": "",
            "mainWord": "Saltar"
        },
        "de": {
            "article": "",
            "mainWord": "Springen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083036/vocimage/verbes/sauter/horse-4561007__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083036/vocimage/verbes/sauter/bermuda-triangle-2389714__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146958/vocimage/verbes/sauter/woman-332278_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691146958/vocimage/verbes/sauter/dog-3577109_640.jpg"]
}, {
    "id": 1543,
    "name": "Se taire",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Se taire"
        },
        "nl": {
            "article": "",
            "mainWord": "Zwijgen"
        },
        "en": {
            "article": "To",
            "mainWord": "be quiet"
        },
        "es": {
            "article": "",
            "mainWord": "Callarse"
        },
        "de": {
            "article": "",
            "mainWord": "Schweigen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082760/vocimage/verbes/se%20taire/person-976759__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147173/vocimage/verbes/se%20taire/fantasy-3131323_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147173/vocimage/verbes/se%20taire/woman-777861_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147174/vocimage/verbes/se%20taire/human-730204_640.jpg"]
}, {
    "id": 1544,
    "name": "Téléphoner",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Téléphoner"
        },
        "nl": {
            "article": "",
            "mainWord": "Opbellen"
        },
        "en": {
            "article": "To",
            "mainWord": "call"
        },
        "es": {
            "article": "",
            "mainWord": "Llamar"
        },
        "de": {
            "article": "",
            "mainWord": "Anrufen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083209/vocimage/verbes/telephoner/woman-3083379__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083209/vocimage/verbes/telephoner/budapest-1280521__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083209/vocimage/verbes/telephoner/phone-booth-5011981__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147258/vocimage/verbes/telephoner/receptionists-5975962_640.jpg"]
}, {
    "id": 1545,
    "name": "Tirer",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Tirer"
        },
        "nl": {
            "article": "",
            "mainWord": "Trekken"
        },
        "en": {
            "article": "To",
            "mainWord": "pull"
        },
        "es": {
            "article": "",
            "mainWord": "Tirar"
        },
        "de": {
            "article": "",
            "mainWord": "Ziehen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082952/vocimage/verbes/tirer/tug-of-war-2665148__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082952/vocimage/verbes/tirer/dog-4771474__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082952/vocimage/verbes/tirer/chest-2652011__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082952/vocimage/verbes/tirer/pulling-640095__340.jpg"]
}, {
    "id": 1546,
    "name": "Tomber",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Tomber"
        },
        "nl": {
            "article": "",
            "mainWord": "Vallen"
        },
        "en": {
            "article": "To",
            "mainWord": "fall"
        },
        "es": {
            "article": "",
            "mainWord": "Caer"
        },
        "de": {
            "article": "",
            "mainWord": "Fallen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082920/vocimage/verbes/tomber/hahn-1185703__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082920/vocimage/verbes/tomber/falling-335295__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147478/vocimage/verbes/tomber/tomber1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147478/vocimage/verbes/tomber/tomber.jpg"]
}, {
    "id": 1547,
    "name": "Travailler",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Travailler"
        },
        "nl": {
            "article": "",
            "mainWord": "Werken"
        },
        "en": {
            "article": "To",
            "mainWord": "work"
        },
        "es": {
            "article": "",
            "mainWord": "Trabajar"
        },
        "de": {
            "article": "",
            "mainWord": "Arbeiten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691082838/vocimage/verbes/travailler/welder-3018425__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691082838/vocimage/verbes/travailler/housebuilding-3102324__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691083440/vocimage/verbes/travailler/master-5022331__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147520/vocimage/verbes/travailler/student-849822_640.jpg"]
}, {
    "id": 1548,
    "name": "Voler",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Voler"
        },
        "nl": {
            "article": "",
            "mainWord": "Vliegen"
        },
        "en": {
            "article": "To",
            "mainWord": "fly"
        },
        "es": {
            "article": "",
            "mainWord": "Volar"
        },
        "de": {
            "article": "",
            "mainWord": "Fliegen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691147783/vocimage/verbes/voler/drone-1866742_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147783/vocimage/verbes/voler/brimstone-721514_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147783/vocimage/verbes/voler/seagull-591350_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147783/vocimage/verbes/voler/airplane-3702676_640.jpg"]
}, {
    "id": 1549,
    "name": "Voyager",
    "themeId": 16,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Voyager"
        },
        "nl": {
            "article": "",
            "mainWord": "Reizen"
        },
        "en": {
            "article": "To",
            "mainWord": "travel"
        },
        "es": {
            "article": "",
            "mainWord": "Viajar"
        },
        "de": {
            "article": "",
            "mainWord": "Reisen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691083143/vocimage/verbes/voyager/airport-2373727__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147992/vocimage/verbes/voyager/arches-national-park-1846759_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147992/vocimage/verbes/voyager/globe-trotter-1828079_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691147993/vocimage/verbes/voyager/kermit-3179209_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}