import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { logger } from 'redux-logger';
import reduxPromise from 'redux-promise';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';

import '../assets/stylesheets/application.scss';
import App from './containers/App';

import languagesReducer from './reducers/languages_reducer.js'
import languageReducer from './reducers/language_reducer.js'
import themesReducer from './reducers/themes_reducer.js'
import themesidsReducer from './reducers/themesids_reducer.js'
import levelReducer from './reducers/level_reducer.js'
import maxwordsReducer from './reducers/maxwords_reducer.js'
import playReducer from './reducers/play_reducer.js'
import gameReducer from './reducers/game_reducer.js'
import walletReducer from './reducers/wallet_reducer.js'

// State and reducers
const reducers = combineReducers({
  languages: languagesReducer,
  language: languageReducer,
  themes: themesReducer,
  themesids: themesidsReducer,
  level: levelReducer,
  maxwords: maxwordsReducer,
  play: playReducer,
  game: gameReducer,
  wallet: walletReducer
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middlewares = null;
if (process.env.NODE_ENV === `development`) {
  middlewares = composeEnhancers(applyMiddleware(reduxPromise, logger));
} else {
  middlewares = composeEnhancers(applyMiddleware(reduxPromise)); 
}

const initialState = {
  languages: [],
  language: {
    id: 2,
    name: 'nl',
    translations: {
        fr: 'néerlandais',
        nl: 'nederlands',
        en: 'dutch',
        es: 'holandés',
        de: 'niederländisch'
    }
  }, 
  themes: [],
  themesids: [],
  level: 0,
  maxwords: 3,
  play: false, 
  game: { wordIds: [], 
          word: {}, 
          images: [], 
          possibleLetters: [], 
          playerLetters: [], 
          wordToFind: '',
          found: false,
          finished: true
        },
  wallet: 40
};

let store = createStore(persistedReducer, initialState, middlewares);
let persistor = persistStore(store);

const root = document.getElementById('root');
if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    root
  );
}