import { SELECT_THEME } from '../actions/theme.js'
import { DESELECT_THEME } from '../actions/theme.js'
import { SELECT_ALL_THEME } from '../actions/themes.js'
import { DESELECT_ALL_THEME } from '../actions/themes.js'
export default function(state, action) {
    if (state === undefined) {
        return [];
    }
    let newthemesids = [];
    switch (action.type) {        
        case SELECT_THEME:
            newthemesids = [...state, action.payload];
            return newthemesids;
        case DESELECT_THEME:
            newthemesids = state.filter(id => id != action.payload)
            return newthemesids;
        case SELECT_ALL_THEME:
            action.payload.forEach(theme => {newthemesids.push(theme.id)})
            return newthemesids
        case DESELECT_ALL_THEME:
            return []
        default:
            return state;
    }
}