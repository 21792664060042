export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';

export function fetchLanguages() {
    return {
        type: FETCH_LANGUAGES,
        payload: [
            {
                id: 1,
                name: 'fr',
                translations: {
                    fr: 'français',
                    nl: 'frans',
                    en: 'french',
                    es: 'francés',
                    de: 'französisch'
                }
            },
            {
                id: 2,
                name: 'nl',
                translations: {
                    fr: 'néerlandais',
                    nl: 'nederlands',
                    en: 'dutch',
                    es: 'holandés',
                    de: 'niederländisch'
                }
            },
            {
                id: 3,
                name: 'en',
                translations: {
                    fr: 'anglais',
                    nl: 'engels',
                    en: 'english',
                    es: 'ingles',
                    de: 'englisch'
                }
            },
            {
                id: 4,
                name: 'es',
                translations: {
                    fr: 'espagnol',
                    nl: 'spaans',
                    en: 'spanish',
                    es: 'espanol',
                    de: 'spanisch'
                }
            },
            {
                id: 5,
                name: 'de',
                translations: {
                    fr: 'allemand',
                    nl: 'duitse',
                    en: 'german',
                    es: 'alemán',
                    de: 'deutsche'
                }
            }
            ]
    }
}