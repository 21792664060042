const randomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
}

const shuffle = (array) => {
    let j;
    let temp;
    array.forEach((item, i) => {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    })
}

const stringWithoutWhiteSpace = (letters) => {
    return letters.filter(letter => letter != ' ').join('')
}

const shuffleLetters = (wordLetters, wordToFind) => {
    shuffle(wordLetters);
    let counter = 0;
    let isIncluded = stringWithoutWhiteSpace(wordLetters).includes(wordToFind);
    while (counter < 3 && isIncluded) {
        counter += 1;
        shuffle(wordLetters);
        isIncluded = stringWithoutWhiteSpace(wordLetters).includes(wordToFind);
    }
}

export {randomItem, shuffle, shuffleLetters, stringWithoutWhiteSpace};