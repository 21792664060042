import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { discoverLetter } from '../actions/letter.js';
import { decreaseWallet } from '../actions/wallet.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { freeLettersCount } from '../../helpers/letters';
import Wallet from './Wallet.jsx';

class Status extends React.Component {

    help = () => {
        if (freeLettersCount(this.props.game.playerLetters) > 1) {            
            const letterDiscoveringPrice = 15;
            if (this.props.wallet >= letterDiscoveringPrice) {
                if (window.confirm("La découverte d'une lettre vous coûtera 15 chapeaux. Acceptez-vous ?")) {
                    const resultDiscoverLetter = this.props.discoverLetter(this.props.game.possibleLetters, this.props.game.playerLetters, this.props.game.wordToFind);
                    if (resultDiscoverLetter.error) {
                        alert("Désolé. La lettre de la première case vide a déjà été choisie mais n'est pas à la bonne place. ")
                    } else {
                        this.props.decreaseWallet(letterDiscoveringPrice);
                    }
                } 
            } else {
                alert("Désolé...Vous n'avez pas assez de chapeaux !");
            }
        }
    }

    render() {
        return(
            <div id="remaining-words-counter">
                <h2 className="flex-container-space-around">
                    <div className="flex-container-align">
                        <FontAwesomeIcon icon={faFlagCheckered} size="2x" />
                        <span className="badge badge-dark">{this.props.game.wordIds.length}</span>
                    </div>
                    <div className="flex-container-align">
                        <Wallet sizeicon={2} />
                        <FontAwesomeIcon icon={faMagic} size="2x" className="app-main-color" onClick={this.help} />
                    </div>
                </h2>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {game: state.game, wallet: state.wallet}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { discoverLetter, decreaseWallet },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Status);