import { INCREASE_WALLET } from '../actions/wallet.js'
import { DECREASE_WALLET } from '../actions/wallet.js'
export default function(state, action) {
    if (state === undefined) {
        return null
    }
    let newState = {};
    switch (action.type) {
        case INCREASE_WALLET:
            newState = state;
            newState += action.payload
            return newState;
        case DECREASE_WALLET:
            newState = state;
            newState -= action.payload
            return newState;
        default:
            return state;
    }
}