export const FETCH_THEMES = 'FETCH_THEMES';
export const SELECT_ALL_THEME = 'SELECT_ALL_THEME';
export const DESELECT_ALL_THEME = 'DESELECT_ALL_THEME';

export function fetchThemes() {
    return {
        type: FETCH_THEMES,
        payload: [
            {
                id: 1,
                startIndex: 0,
                name: "maison"
            },
            {
                id: 2,
                startIndex: 100,
                name: "école"
            },
            {
                id: 3,
                startIndex: 200,
                name: "animaux"
            },
            {
                id: 4,
                startIndex: 300,
                name: "couleurs"
            },
            {
                id: 5,
                startIndex: 400,
                name: "nombres"
            },
            {
                id: 6,
                startIndex: 500,
                name: "vêtements"
            },
            {
                id: 7,
                startIndex: 600,
                name: "calendrier"
            },
            {
                id: 8,
                startIndex: 700,
                name: "métiers"
            },
            {
                id: 9,
                startIndex: 800,
                name: "transports"
            },
            {
                id: 10,
                startIndex: 900,
                name: "alimentation"
            },
            {
                id: 11,
                startIndex: 1000,
                name: "loisirs"
            },
            {
                id: 12,
                startIndex: 1100,
                name: "corps"
            },
            {
                id: 13,
                startIndex: 1200,
                name: "météo"
            },
            {
                id: 14,
                startIndex: 1300,
                name: "famille"
            },
            {
                id: 15,
                startIndex: 1400,
                name: "humeurs"
            },
            {
                id: 16,
                startIndex: 1500,
                name: "verbes"
            },
            {
                id: 17,
                startIndex: 1600,
                name: "campagne"
            },
            {
                id: 18,
                startIndex: 1700,
                name: "ville"
            },
            {
                id: 19,
                startIndex: 1800,
                name: "mer"
            },
            {
                id: 20,
                startIndex: 1900,
                name: "salutations"
            },
            {
                id: 21,
                startIndex: 2000,
                name: "montagne"
            },
            {
                id: 22,
                startIndex: 2100,
                name: "objets"
            },
            {
                id: 23,
                startIndex: 2200,
                name: "Pays"
            },
            {
                id: 24,
                startIndex: 2300,
                name: "Carnaval"
            },
            {
                id: 25,
                startIndex: 2400,
                name: "Halloween"
            },
            {
                id: 26,
                startIndex: 2500,
                name: "Pâques"
            },
            {
                id: 27,
                startIndex: 2600,
                name: "Noël"
            }
            // ,
            // {
            //     id: 1001,
            //     startIndex: 100000,
            //     name: "autres"
            // }
            ].sort((a, b) => a.name.localeCompare(b.name))           
    }
}

export function selectAllTheme(allThemes) {
    return {
        type: SELECT_ALL_THEME,
        payload: allThemes
    }
}

export function deselectAllTheme() {
    return {
        type: DESELECT_ALL_THEME,
        payload: []
    }
}