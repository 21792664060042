const words = 
[{
    "id": 1201,
    "name": "Froid",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Froid"
        },
        "nl": {
            "article": "",
            "mainWord": "Koud"
        },
        "en": {
            "article": "",
            "mainWord": "Cold"
        },
        "es": {
            "article": "El",
            "mainWord": "frío"
        },
        "de": {
            "article": "",
            "mainWord": "kalt"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970638/vocimage/meteo/froid/child-5943325_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970638/vocimage/meteo/froid/woman-1166842_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970537/vocimage/meteo/froid/chihuahua-633962__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970537/vocimage/meteo/froid/cold-2722002__340.jpg"]
}, {
    "id": 1202,
    "name": "Degrés",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Degrés"
        },
        "nl": {
            "article": "",
            "mainWord": "Graden"
        },
        "en": {
            "article": "",
            "mainWord": "Degrees"
        },
        "es": {
            "article": "",
            "mainWord": "Grados"
        },
        "de": {
            "article": "",
            "mainWord": "Grad"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970522/vocimage/meteo/degres/thermometer-1569669_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970480/vocimage/meteo/degres/time-of-2353382__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970480/vocimage/meteo/degres/thermometer-789898__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970480/vocimage/meteo/degres/maspalomas-5026584__340.jpg"]
}, {
    "id": 1203,
    "name": "Chaud",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Chaud"
        },
        "nl": {
            "article": "",
            "mainWord": "Warm"
        },
        "en": {
            "article": "",
            "mainWord": "Hot"
        },
        "es": {
            "article": "El",
            "mainWord": "calor"
        },
        "de": {
            "article": "",
            "mainWord": "heiß"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690971466/vocimage/meteo/chaud/1519780-plusieurs-departements-du-sud-ouest-sont-places-en-vigilance-orange-canicule-illustration.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690971465/vocimage/meteo/chaud/shower-7758677_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690971465/vocimage/meteo/chaud/heat-3571028_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690971465/vocimage/meteo/chaud/Heatwaves.jpg"]
}, {
    "id": 1204,
    "name": "nuage",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "nuage"
        },
        "nl": {
            "article": "De",
            "mainWord": "wolk"
        },
        "en": {
            "article": "The",
            "mainWord": "cloud"
        },
        "es": {
            "article": "La",
            "mainWord": "nube"
        },
        "de": {
            "article": "die",
            "mainWord": "Wolke"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970456/vocimage/meteo/nuage/tree-736875_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970456/vocimage/meteo/nuage/cloud-5055011_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970404/vocimage/meteo/nuage/field-533541__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970404/vocimage/meteo/nuage/sky-49520__340.jpg"]
}, {
    "id": 1205,
    "name": "brouillard",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "brouillard"
        },
        "nl": {
            "article": "De",
            "mainWord": "mist"
        },
        "en": {
            "article": "The",
            "mainWord": "fog"
        },
        "es": {
            "article": "La",
            "mainWord": "niebla"
        },
        "de": {
            "article": "der",
            "mainWord": "Nebel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970380/vocimage/meteo/brouillard/wolves-1341881_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970380/vocimage/meteo/brouillard/landscape-5591270_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970380/vocimage/meteo/brouillard/nature-3787200_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970242/vocimage/meteo/brouillard/dawn-3208158__340.jpg"]
}, {
    "id": 1206,
    "name": "gel",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "gel"
        },
        "nl": {
            "article": "De",
            "mainWord": "vorst"
        },
        "en": {
            "article": "The",
            "mainWord": "frost"
        },
        "es": {
            "article": "El",
            "mainWord": "hielo"
        },
        "de": {
            "article": "der",
            "mainWord": "Frost"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970219/vocimage/meteo/gel/pasqueflower-3840163_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970219/vocimage/meteo/gel/ice-1997289_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970151/vocimage/meteo/gel/soap-bubble-3187617__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970151/vocimage/meteo/gel/frost-on-grass-1358926__340.jpg"]
}, {
    "id": 1207,
    "name": "grêle",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "grêle"
        },
        "nl": {
            "article": "De",
            "mainWord": "hagel"
        },
        "en": {
            "article": "The",
            "mainWord": "hail"
        },
        "es": {
            "article": "El",
            "mainWord": "granizo"
        },
        "de": {
            "article": "der",
            "mainWord": "Hagel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970136/vocimage/meteo/grele/hailstones-1338886_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970093/vocimage/meteo/grele/hail-2239832__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970092/vocimage/meteo/grele/hail-3174126__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970092/vocimage/meteo/grele/snow-1362624__340.jpg"]
}, {
    "id": 1208,
    "name": "lune",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "lune"
        },
        "nl": {
            "article": "De",
            "mainWord": "maan"
        },
        "en": {
            "article": "The",
            "mainWord": "moon"
        },
        "es": {
            "article": "La",
            "mainWord": "luna"
        },
        "de": {
            "article": "der",
            "mainWord": "Mond"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970069/vocimage/meteo/lune/tree-736877_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970069/vocimage/meteo/lune/astronaut-4106766_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970029/vocimage/meteo/lune/moon-1527501__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970029/vocimage/meteo/lune/moon-2762111__340.jpg"]
}, {
    "id": 1209,
    "name": "pluie",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "pluie"
        },
        "nl": {
            "article": "De",
            "mainWord": "regen"
        },
        "en": {
            "article": "The",
            "mainWord": "rain"
        },
        "es": {
            "article": "La",
            "mainWord": "lluvia"
        },
        "de": {
            "article": "der",
            "mainWord": "Regen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970015/vocimage/meteo/pluie/adult-1867665_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690970015/vocimage/meteo/pluie/rain-4996916_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969945/vocimage/meteo/pluie/woman-1807533__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969945/vocimage/meteo/pluie/rain-791893__340_1.jpg"]
}, {
    "id": 1210,
    "name": "arc-en-ciel",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "arc-en-ciel"
        },
        "nl": {
            "article": "De",
            "mainWord": "regenboog"
        },
        "en": {
            "article": "The",
            "mainWord": "rainbow"
        },
        "es": {
            "article": "El",
            "mainWord": "arco iris"
        },
        "de": {
            "article": "der",
            "mainWord": "Regenbogen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969918/vocimage/meteo/arc%20en%20ciel/rainbow-4285843_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969918/vocimage/meteo/arc%20en%20ciel/rainbow-7381976_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969918/vocimage/meteo/arc%20en%20ciel/rainbow-3320571_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969745/vocimage/meteo/arc%20en%20ciel/rainbow-436183__340.jpg"]
}, {
    "id": 1211,
    "name": "ciel",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "ciel"
        },
        "nl": {
            "article": "De",
            "mainWord": "hemel"
        },
        "en": {
            "article": "The",
            "mainWord": "sky"
        },
        "es": {
            "article": "El",
            "mainWord": "cielo"
        },
        "de": {
            "article": "der",
            "mainWord": "Himmel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969729/vocimage/meteo/ciel/aurora-1190254_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969729/vocimage/meteo/ciel/sun-3588618_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969638/vocimage/meteo/ciel/star-trails-2234343__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969638/vocimage/meteo/ciel/forest-231066__340.jpg"]
}, {
    "id": 1212,
    "name": "neige",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "neige"
        },
        "nl": {
            "article": "De",
            "mainWord": "sneeuw"
        },
        "en": {
            "article": "The",
            "mainWord": "snow"
        },
        "es": {
            "article": "La",
            "mainWord": "nieve"
        },
        "de": {
            "article": "der",
            "mainWord": "Schnee"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969625/vocimage/meteo/neige/winter-landscape-636634__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969619/vocimage/meteo/neige/wintry-2068298__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969619/vocimage/meteo/neige/iceland-1979445__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969619/vocimage/meteo/neige/white-horse-3010129__340.jpg"]
}, {
    "id": 1213,
    "name": "tempête",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "tempête"
        },
        "nl": {
            "article": "De",
            "mainWord": "storm"
        },
        "en": {
            "article": "The",
            "mainWord": "storm"
        },
        "es": {
            "article": "La",
            "mainWord": "borrasca"
        },
        "de": {
            "article": "der",
            "mainWord": "Sturm"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969605/vocimage/meteo/tempete/storm-567664_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969444/vocimage/meteo/tempete/key-west-81664__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969444/vocimage/meteo/tempete/water-3119563__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969444/vocimage/meteo/tempete/sea-3190417__340.jpg"]
}, {
    "id": 1214,
    "name": "soleil",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "soleil"
        },
        "nl": {
            "article": "De",
            "mainWord": "zon"
        },
        "en": {
            "article": "The",
            "mainWord": "sun"
        },
        "es": {
            "article": "El",
            "mainWord": "sol"
        },
        "de": {
            "article": "die",
            "mainWord": "Sonne"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969423/vocimage/meteo/soleil/earth-1756274_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969423/vocimage/meteo/soleil/village-2090495_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969337/vocimage/meteo/soleil/sun-3130638__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969337/vocimage/meteo/soleil/prairie-679014__340.jpg"]
}, {
    "id": 1215,
    "name": "orage",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "orage"
        },
        "nl": {
            "article": "Het",
            "mainWord": "onweer"
        },
        "en": {
            "article": "The",
            "mainWord": "thunderstorm"
        },
        "es": {
            "article": "La",
            "mainWord": "tormenta"
        },
        "de": {
            "article": "das",
            "mainWord": "Gewitter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690970836/vocimage/meteo/orage/thunderstorm-1768742_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969311/vocimage/meteo/orage/ruins-2184025__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969310/vocimage/meteo/orage/flash-3087117__340_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969310/vocimage/meteo/orage/lightning-1056419__340.jpg"]
}, {
    "id": 1216,
    "name": "météo",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "météo"
        },
        "nl": {
            "article": "Het",
            "mainWord": "weerbericht"
        },
        "en": {
            "article": "The",
            "mainWord": "weather"
        },
        "es": {
            "article": "El",
            "mainWord": "tiempo"
        },
        "de": {
            "article": "der",
            "mainWord": "Wetterbericht"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690969279/vocimage/meteo/meteo/winter-5940505_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690969279/vocimage/meteo/meteo/situ-060921-1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690968908/vocimage/meteo/meteo/meteosat-193760__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690968908/vocimage/meteo/meteo/weather-3345746__340.jpg"]
}, {
    "id": 1217,
    "name": "vent",
    "themeId": 13,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "vent"
        },
        "nl": {
            "article": "De",
            "mainWord": "wind"
        },
        "en": {
            "article": "The",
            "mainWord": "wind"
        },
        "es": {
            "article": "El",
            "mainWord": "viento"
        },
        "de": {
            "article": "der",
            "mainWord": "Wind"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690968891/vocimage/meteo/vent/child-2887483_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690968812/vocimage/meteo/vent/anemometer-4947916__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690968756/vocimage/meteo/vent/windmills-1838788__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690968756/vocimage/meteo/vent/dog-4868210__340.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}