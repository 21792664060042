export const INCREASE_WALLET = 'INCREASE_WALLET';
export const DECREASE_WALLET = 'DECREASE_WALLET';

export function increaseWallet(value) {
    return {
        type: INCREASE_WALLET,
        payload: value
    }
}

export function decreaseWallet(value) {
    return {
        type: DECREASE_WALLET,
        payload: value
    }
}