import React from 'react';
import Options from '../containers/Options';
import Myle from './Myle';

class Footer extends React.Component {

    renderPixabayLogo = () => {
        const backgroundUrl = "https://pixabay.com/static/img/logo.png";
        return(
            <div>                
                {/* <div id="pixabay-logo" style={{backgroundImage: `url(${backgroundUrl})`}}></div>  */}
                <Myle />
            </div>
        )
    }

    render() {
        return(
            <div id="play-bottom">
                <Options />
                {this.renderPixabayLogo()}
            </div>                     
        )
    }
}

export default Footer;