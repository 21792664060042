import React from 'react';
import { connect } from 'react-redux';
import Image from '../components/Image';

class Images extends React.Component {

    renderImages = () => {
        return this.props.game.images.map(
            (image, index) => { return  <Image image={image} key={index}/> }
        )
    }

    render() {
        if (this.props.game.images.length == 4) {
            return(
                <div className="images">
                {this.renderImages()}
            </div>
            )
        } else {
            return(
                <div></div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {game: state.game}
}

export default connect(mapStateToProps) (Images);