export const CHANGE_IMAGES = 'CHANGE_IMAGES';
import {shuffle} from '../../helpers/math';

const fillImages = (data) => {
    const images = []
    data.hits.forEach(hit => 
        images.push(hit.webformatURL)
    );
    shuffle(images);
    document.body.style.cursor  = 'default';
    return images
}

export function changeImages(word, API_KEY) {
    let images = [];
    if (word.images.length == 4) {
        images = [...word.images];
        shuffle(images);
        return {
            type: CHANGE_IMAGES,
            payload: images
        }
    } else {
        document.body.style.cursor  = 'wait';
        const url = `https://pixabay.com/api/?key=${API_KEY}&q=${word.name}&per_page=4&lang=fr&safesearch=true`;
        const images = fetch(url)
                        .then(response => response.json())
                        .then(data => fillImages(data));
        return {
            type: CHANGE_IMAGES,
            payload: images
        };    
    }
}