const words = 
[{
    "id": 1601,
    "name": "animal",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "animal"
        },
        "nl": {
            "article": "Het",
            "mainWord": "dier"
        },
        "en": {
            "article": "An",
            "mainWord": "animal"
        },
        "es": {
            "article": "El",
            "mainWord": "animal"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tier"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690198810/vocimage/campagne/animal/donkey-1268897_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198810/vocimage/campagne/animal/rough-collie-5631188_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198810/vocimage/campagne/animal/cat-7240997_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198810/vocimage/campagne/animal/ireland-1985088_640.jpg"]
}, {
    "id": 1602,
    "name": "arbre",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "arbre"
        },
        "nl": {
            "article": "De",
            "mainWord": "boom"
        },
        "en": {
            "article": "A",
            "mainWord": "tree"
        },
        "es": {
            "article": "El",
            "mainWord": "árbol"
        },
        "de": {
            "article": "Der",
            "mainWord": "Baum"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690198877/vocimage/campagne/arbre/tree-569275_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198877/vocimage/campagne/arbre/tree-3097419_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198877/vocimage/campagne/arbre/tree-99852_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198877/vocimage/campagne/arbre/tree-1959267_640.jpg"]
}, {
    "id": 1603,
    "name": "étang",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "étang"
        },
        "nl": {
            "article": "De",
            "mainWord": "vijver"
        },
        "en": {
            "article": "A",
            "mainWord": "pond"
        },
        "es": {
            "article": "El",
            "mainWord": "estanque"
        },
        "de": {
            "article": "Der",
            "mainWord": "Teich"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690200348/vocimage/campagne/etang/wildlife-7945330_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200348/vocimage/campagne/etang/pond-555812_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200348/vocimage/campagne/etang/ducks-8092519_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200348/vocimage/campagne/etang/frog-2525994_640.jpg"]
}, {
    "id": 1604,
    "name": "herbe",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "herbe"
        },
        "nl": {
            "article": "Het",
            "mainWord": "gras"
        },
        "en": {
            "article": "The",
            "mainWord": "grass"
        },
        "es": {
            "article": "La",
            "mainWord": "hierba"
        },
        "de": {
            "article": "Das",
            "mainWord": "Gras"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690200705/vocimage/campagne/herbe/girl-lying-on-the-grass-1741487_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200705/vocimage/campagne/herbe/grass-534873_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200705/vocimage/campagne/herbe/lawn-mower-938555_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200705/vocimage/campagne/herbe/drop-of-water-351778_1280.jpg"]
}, {
    "id": 1605,
    "name": "insecte",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "insecte"
        },
        "nl": {
            "article": "Het",
            "mainWord": "insect"
        },
        "en": {
            "article": "An",
            "mainWord": "insect"
        },
        "es": {
            "article": "El",
            "mainWord": "insecto"
        },
        "de": {
            "article": "Das",
            "mainWord": "Insekt"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690200981/vocimage/campagne/insecte/ant-453348_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200981/vocimage/campagne/insecte/insect-hotel-883096_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200981/vocimage/campagne/insecte/butterfly-354528_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200981/vocimage/campagne/insecte/ladybugs-1593406_640.jpg"]
}, {
    "id": 1606,
    "name": "barrière",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "barrière"
        },
        "nl": {
            "article": "Het",
            "mainWord": "hek"
        },
        "en": {
            "article": "A",
            "mainWord": "fence"
        },
        "es": {
            "article": "La",
            "mainWord": "valla"
        },
        "de": {
            "article": "Der",
            "mainWord": "Zaun"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199203/vocimage/campagne/barriere/cathedral-grove-4914053_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198981/vocimage/campagne/barriere/wooden-gate-3682960_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198981/vocimage/campagne/barriere/fence-1670087_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198981/vocimage/campagne/barriere/fence-posts-2331414_640.jpg"]
}, {
    "id": 1607,
    "name": "campagne",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "campagne"
        },
        "nl": {
            "article": "Het",
            "mainWord": "platteland"
        },
        "en": {
            "article": "The",
            "mainWord": "countryside"
        },
        "es": {
            "article": "La",
            "mainWord": "campiña"
        },
        "de": {
            "article": "Die",
            "mainWord": "ländlische Gegend"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199335/vocimage/campagne/campagne/field-3379250_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199335/vocimage/campagne/campagne/bicycle-788733_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199335/vocimage/campagne/campagne/hut-6750482_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199335/vocimage/campagne/campagne/wheat-2391348_640.jpg"]
}, {
    "id": 1608,
    "name": "ferme",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "ferme"
        },
        "nl": {
            "article": "De",
            "mainWord": "boerderij"
        },
        "en": {
            "article": "A",
            "mainWord": "farm"
        },
        "es": {
            "article": "La",
            "mainWord": "finca"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bauernhof"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690200532/vocimage/campagne/ferme/farm-yard-1656820_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200532/vocimage/campagne/ferme/dairy-farm-4019962_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200532/vocimage/campagne/ferme/texas-1403012_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200532/vocimage/campagne/ferme/soil-386749_640.jpg"]
}, {
    "id": 1609,
    "name": "fleur",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "fleur"
        },
        "nl": {
            "article": "De",
            "mainWord": "bloem"
        },
        "en": {
            "article": "A",
            "mainWord": "flower"
        },
        "es": {
            "article": "La",
            "mainWord": "flor"
        },
        "de": {
            "article": "Die",
            "mainWord": "Blume"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690200606/vocimage/campagne/fleur/flower-100263_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200606/vocimage/campagne/fleur/hand-1549399_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200606/vocimage/campagne/fleur/flowers-174817_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690200606/vocimage/campagne/fleur/marguerite-729510_1280.jpg"]
}, {
    "id": 1610,
    "name": "maison",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "maison"
        },
        "nl": {
            "article": "Het",
            "mainWord": "huis"
        },
        "en": {
            "article": "A",
            "mainWord": "house"
        },
        "es": {
            "article": "La",
            "mainWord": "casa"
        },
        "de": {
            "article": "Das",
            "mainWord": "Haus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201101/vocimage/campagne/maison/shutters-669296_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201079/vocimage/campagne/maison/holiday-home-177401_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201079/vocimage/campagne/maison/casting-horn-2368494_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201079/vocimage/campagne/maison/wyoming-4083816_640.jpg"]
}, {
    "id": 1611,
    "name": "prairie",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "prairie"
        },
        "nl": {
            "article": "De",
            "mainWord": "weide"
        },
        "en": {
            "article": "A",
            "mainWord": "meadow"
        },
        "es": {
            "article": "El",
            "mainWord": "prado"
        },
        "de": {
            "article": "Die",
            "mainWord": "Wiese"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201596/vocimage/campagne/prairie/cow-7200409_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201596/vocimage/campagne/prairie/sheep-4461377_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201596/vocimage/campagne/prairie/little-girl-2516582_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201595/vocimage/campagne/prairie/tree-3398108_640.jpg"]
}, {
    "id": 1612,
    "name": "rivière",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "rivière"
        },
        "nl": {
            "article": "De",
            "mainWord": "rivier"
        },
        "en": {
            "article": "A",
            "mainWord": "river"
        },
        "es": {
            "article": "El",
            "mainWord": "arroyo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Fluss"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201763/vocimage/campagne/riviere/caucasus-5302236_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201763/vocimage/campagne/riviere/children-1822662_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201763/vocimage/campagne/riviere/stream-5429113_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201763/vocimage/campagne/riviere/bach-7289941_640.jpg"]
}, {
    "id": 1613,
    "name": "ruche",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "ruche"
        },
        "nl": {
            "article": "De",
            "mainWord": "bijenkorf"
        },
        "en": {
            "article": "A",
            "mainWord": "hive"
        },
        "es": {
            "article": "La",
            "mainWord": "colmena"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bienenstock"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201917/vocimage/campagne/ruche/bees-326337_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201917/vocimage/campagne/ruche/bees-7996596_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201917/vocimage/campagne/ruche/beekeeping-4232534_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201917/vocimage/campagne/ruche/beekeeper-4426003_640.jpg"]
}, {
    "id": 1614,
    "name": "bois",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bois"
        },
        "nl": {
            "article": "Het",
            "mainWord": "bos"
        },
        "en": {
            "article": "The",
            "mainWord": "woods"
        },
        "es": {
            "article": "El",
            "mainWord": "bosque"
        },
        "de": {
            "article": "Der",
            "mainWord": "Wald"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199176/vocimage/campagne/bois/trees-3294681_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199176/vocimage/campagne/bois/snow-3260088_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199176/vocimage/campagne/bois/forest-868715_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199176/vocimage/campagne/bois/fall-3193305_640.jpg"]
}, {
    "id": 1615,
    "name": "champ",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "champ"
        },
        "nl": {
            "article": "Het",
            "mainWord": "veld"
        },
        "en": {
            "article": "A",
            "mainWord": "field"
        },
        "es": {
            "article": "El",
            "mainWord": "campo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Feld"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199449/vocimage/campagne/champ/wheat-640960_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199448/vocimage/campagne/champ/hay-bales-1930612_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199448/vocimage/campagne/champ/sunflower-3550693_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199448/vocimage/campagne/champ/rape-blossom-502973_640.jpg"]
}, {
    "id": 1616,
    "name": "cimetière",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cimetière"
        },
        "nl": {
            "article": "Het",
            "mainWord": "kerkhof"
        },
        "en": {
            "article": "A",
            "mainWord": "churchyard"
        },
        "es": {
            "article": "El",
            "mainWord": "cementerio"
        },
        "de": {
            "article": "Der",
            "mainWord": "Friedhof"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199721/vocimage/campagne/cimetiere/tombstone-2254390_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199721/vocimage/campagne/cimetiere/cemetery-4653166_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199721/vocimage/campagne/cimetiere/graveyard-300076_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199720/vocimage/campagne/cimetiere/cementerio-948048_640.jpg"]
}, {
    "id": 1617,
    "name": "coq",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "coq"
        },
        "nl": {
            "article": "De",
            "mainWord": "haan"
        },
        "en": {
            "article": "A",
            "mainWord": "rooster"
        },
        "es": {
            "article": "El",
            "mainWord": "gallo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Hahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690199845/vocimage/campagne/coq/chicken-3547113_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199845/vocimage/campagne/coq/chicken-647226_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199845/vocimage/campagne/coq/rooster-1867562_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690199845/vocimage/campagne/coq/rooster-4125926_640.jpg"]
}, {
    "id": 1618,
    "name": "jardin",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jardin"
        },
        "nl": {
            "article": "De",
            "mainWord": "tuin"
        },
        "en": {
            "article": "A",
            "mainWord": "garden"
        },
        "es": {
            "article": "El",
            "mainWord": "jardín"
        },
        "de": {
            "article": "Der",
            "mainWord": "Garten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201277/vocimage/campagne/jardin/country-house-640711_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201031/vocimage/campagne/jardin/bird-3503421_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201030/vocimage/campagne/jardin/maine-3994041_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201030/vocimage/campagne/jardin/girl-1863906_640.jpg"]
}, {
    "id": 1619,
    "name": "moulin",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "moulin"
        },
        "nl": {
            "article": "De",
            "mainWord": "molen"
        },
        "en": {
            "article": "A",
            "mainWord": "mill"
        },
        "es": {
            "article": "El",
            "mainWord": "molino"
        },
        "de": {
            "article": "Die",
            "mainWord": "Mühle"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201238/vocimage/campagne/moulin/wheel-2694541_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201238/vocimage/campagne/moulin/mill-1121210_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201238/vocimage/campagne/moulin/windmills-1850214_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201238/vocimage/campagne/moulin/mill-892913_640.jpg"]
}, {
    "id": 1620,
    "name": "potager",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "potager"
        },
        "nl": {
            "article": "Het",
            "mainWord": "moestuin"
        },
        "en": {
            "article": "A",
            "mainWord": "vegetable garden"
        },
        "es": {
            "article": "La",
            "mainWord": "huerta"
        },
        "de": {
            "article": "Der",
            "mainWord": "Gemüsegarten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690201259/vocimage/campagne/potager/country-house-2699713_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201259/vocimage/campagne/potager/garden-3276321_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201259/vocimage/campagne/potager/pumpkins-2878159_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690201259/vocimage/campagne/potager/equipment-2047314_640.jpg"]
}, {
    "id": 1621,
    "name": "sentier",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sentier"
        },
        "nl": {
            "article": "Het",
            "mainWord": "pad"
        },
        "en": {
            "article": "A",
            "mainWord": "footpath"
        },
        "es": {
            "article": "El",
            "mainWord": "sendero"
        },
        "de": {
            "article": "Der",
            "mainWord": "Feldweg"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690202074/vocimage/campagne/sentier/hiking-2540189_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202073/vocimage/campagne/sentier/pathway-2289978_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202073/vocimage/campagne/sentier/walking-1868652_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202073/vocimage/campagne/sentier/path-402906_640.jpg"]
}, {
    "id": 1622,
    "name": "verger",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "verger"
        },
        "nl": {
            "article": "De",
            "mainWord": "boomgaard"
        },
        "en": {
            "article": "An",
            "mainWord": "orchard"
        },
        "es": {
            "article": "Un",
            "mainWord": "huerto frutal"
        },
        "de": {
            "article": "Der",
            "mainWord": "Obstgarten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690198351/vocimage/campagne/verger/apple-970352_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198351/vocimage/campagne/verger/cherries-162896_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198351/vocimage/campagne/verger/jam-4521022_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690198351/vocimage/campagne/verger/vintage-4579557_640.jpg"]
}, {
    "id": 1623,
    "name": "village",
    "themeId": 17,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "village"
        },
        "nl": {
            "article": "Het",
            "mainWord": "dorp"
        },
        "en": {
            "article": "A",
            "mainWord": "village"
        },
        "es": {
            "article": "El",
            "mainWord": "pueblo"
        },
        "de": {
            "article": "Das",
            "mainWord": "Dorf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690202473/vocimage/campagne/village/burglauer-92711_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202473/vocimage/campagne/village/village-3735180_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202473/vocimage/campagne/village/city-2965705_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690202473/vocimage/campagne/village/gordes-533375_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}