import { SELECT_LANGUAGE } from '../actions/language.js'
export default function(state, action) {
    if (state === undefined) {
        return null
    }
    switch (action.type) {
        case SELECT_LANGUAGE:
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}
