const words = 
[{
    "id": 101,
    "name": "Allemand",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Allemand"
        },
        "nl": {
            "article": "",
            "mainWord": "Duits"
        },
        "en": {
            "article": "",
            "mainWord": "German"
        },
        "es": {
            "article": "",
            "mainWord": "Alemán"
        },
        "de": {
            "article": "",
            "mainWord": "Deutsch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690565055/vocimage/ecole/allemand/allemand3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690565055/vocimage/ecole/allemand/allemand1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690565055/vocimage/ecole/allemand/allemand2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690565055/vocimage/ecole/allemand/allemand4.jpg"]
}, {
    "id": 102,
    "name": "Anglais",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Anglais"
        },
        "nl": {
            "article": "",
            "mainWord": "Engels"
        },
        "en": {
            "article": "",
            "mainWord": "English"
        },
        "es": {
            "article": "",
            "mainWord": "Inglés"
        },
        "de": {
            "article": "",
            "mainWord": "Englisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690564264/vocimage/ecole/anglais/Anglais1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564264/vocimage/ecole/anglais/anglais.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564264/vocimage/ecole/anglais/english-2724442_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564265/vocimage/ecole/anglais/grammar.png"]
}, {
    "id": 103,
    "name": "Espagnol",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Espagnol"
        },
        "nl": {
            "article": "",
            "mainWord": "Spaans"
        },
        "en": {
            "article": "",
            "mainWord": "Spanish"
        },
        "es": {
            "article": "",
            "mainWord": "Español"
        },
        "de": {
            "article": "",
            "mainWord": "Spanisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690564762/vocimage/ecole/espagnol/cours-espagnol.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564763/vocimage/ecole/espagnol/espagnol.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564763/vocimage/ecole/espagnol/espagnol1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690564763/vocimage/ecole/espagnol/espagnol2.png"]
}, {
    "id": 104,
    "name": "Français",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Français"
        },
        "nl": {
            "article": "",
            "mainWord": "Frans"
        },
        "en": {
            "article": "",
            "mainWord": "French"
        },
        "es": {
            "article": "",
            "mainWord": "Francés"
        },
        "de": {
            "article": "",
            "mainWord": "Französich"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690561848/vocimage/ecole/francais/francais2.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690561848/vocimage/ecole/francais/francais4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690561848/vocimage/ecole/francais/francais3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690561848/vocimage/ecole/francais/francais1.jpg"]
}, {
    "id": 105,
    "name": "Géographie",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Géographie"
        },
        "nl": {
            "article": "",
            "mainWord": "Aardrijkskunde"
        },
        "en": {
            "article": "",
            "mainWord": "Geography"
        },
        "es": {
            "article": "",
            "mainWord": "Geografía"
        },
        "de": {
            "article": "",
            "mainWord": "Geographie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690544910/vocimage/ecole/geographie/globe-2371898_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690544910/vocimage/ecole/geographie/teaching-928637_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690544910/vocimage/ecole/geographie/europe-3483539_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690544910/vocimage/ecole/geographie/history-7147774_640.jpg"]
}, {
    "id": 106,
    "name": "Histoire",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Histoire"
        },
        "nl": {
            "article": "",
            "mainWord": "Geschiedenis"
        },
        "en": {
            "article": "",
            "mainWord": "History"
        },
        "es": {
            "article": "",
            "mainWord": "Historia"
        },
        "de": {
            "article": "",
            "mainWord": "Geschichte"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542995/vocimage/ecole/histoire/acropolis-2725918_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542995/vocimage/ecole/histoire/library-4410128_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542995/vocimage/ecole/histoire/templar-4298112_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542995/vocimage/ecole/histoire/museum-5082786_640.jpg"]
}, {
    "id": 107,
    "name": "école",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "école"
        },
        "nl": {
            "article": "De",
            "mainWord": "school"
        },
        "en": {
            "article": "A",
            "mainWord": "school"
        },
        "es": {
            "article": "La",
            "mainWord": "escuela"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schule"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536465/vocimage/ecole/ecole/entry-to-school-2454153__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536465/vocimage/ecole/ecole/school-bus-4910419__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540174/vocimage/ecole/ecole/university-2704306_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540349/vocimage/ecole/ecole/school-class-401519_640.jpg"]
}, {
    "id": 108,
    "name": "étudiant",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "étudiant"
        },
        "nl": {
            "article": "De",
            "mainWord": "student"
        },
        "en": {
            "article": "A",
            "mainWord": "student"
        },
        "es": {
            "article": "El",
            "mainWord": "estudiante"
        },
        "de": {
            "article": "Der",
            "mainWord": "Student"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536156/vocimage/ecole/etudiant/girl-3718526__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537908/vocimage/ecole/etudiant/harrypotter-5377964_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537930/vocimage/ecole/etudiant/students-1177710_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538055/vocimage/ecole/etudiant/ai-generated-7827608_640.jpg"]
}, {
    "id": 109,
    "name": "bibliothèque",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "bibliothèque"
        },
        "nl": {
            "article": "De",
            "mainWord": "bibliotheek"
        },
        "en": {
            "article": "A",
            "mainWord": "library"
        },
        "es": {
            "article": "La",
            "mainWord": "biblioteca"
        },
        "de": {
            "article": "Die",
            "mainWord": "Bibliothek"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542468/vocimage/ecole/bibliotheque/library-869061_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542468/vocimage/ecole/bibliotheque/books-2596809_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542468/vocimage/ecole/bibliotheque/the-shelf-2784896_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542474/vocimage/ecole/bibliotheque/books-1539528_640.jpg"]
}, {
    "id": 110,
    "name": "calculatrice",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "calculatrice"
        },
        "nl": {
            "article": "De",
            "mainWord": "rekenmachine"
        },
        "en": {
            "article": "A",
            "mainWord": "calculator"
        },
        "es": {
            "article": "La",
            "mainWord": "calculadora"
        },
        "de": {
            "article": "Der",
            "mainWord": "Rechner"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538359/vocimage/ecole/calculatrice/calculator-4087501_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538359/vocimage/ecole/calculatrice/calculator-2718848_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538897/vocimage/ecole/calculatrice/calculator-820330_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538897/vocimage/ecole/calculatrice/calculator-1680905_640.jpg"]
}, {
    "id": 111,
    "name": "chaise",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chaise"
        },
        "nl": {
            "article": "De",
            "mainWord": "stoel"
        },
        "en": {
            "article": "A",
            "mainWord": "chair"
        },
        "es": {
            "article": "La",
            "mainWord": "silla"
        },
        "de": {
            "article": "Der",
            "mainWord": "Stuhl"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536390/vocimage/ecole/chaise/classroom-824120__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536449/vocimage/ecole/chaise/stadium-1750794__340_-_Copie.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536449/vocimage/ecole/chaise/class-1986501__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538067/vocimage/ecole/chaise/classroom-470680_640.jpg"]
}, {
    "id": 112,
    "name": "classe",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "classe"
        },
        "nl": {
            "article": "De",
            "mainWord": "klas"
        },
        "en": {
            "article": "A",
            "mainWord": "classroom"
        },
        "es": {
            "article": "El",
            "mainWord": "aula"
        },
        "de": {
            "article": "Die",
            "mainWord": "Klasse"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536137/vocimage/ecole/classe/classroom-2787754__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536138/vocimage/ecole/classe/classroom-2093744__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536138/vocimage/ecole/classe/classroom-4919803__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536376/vocimage/ecole/classe/cameroon-104485__340.jpg"]
}, {
    "id": 113,
    "name": "colle",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "colle"
        },
        "nl": {
            "article": "De",
            "mainWord": "lijm"
        },
        "en": {
            "article": "The",
            "mainWord": "glue"
        },
        "es": {
            "article": "El",
            "mainWord": "pegamento"
        },
        "de": {
            "article": "Der",
            "mainWord": "Leim"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536436/vocimage/ecole/colle/tinkering-1177332__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536436/vocimage/ecole/colle/pencil-4188778__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539683/vocimage/ecole/colle/glue-stick-574016_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539683/vocimage/ecole/colle/repair-891422_640.jpg"]
}, {
    "id": 114,
    "name": "cour",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "cour"
        },
        "nl": {
            "article": "De",
            "mainWord": "speelplaats"
        },
        "en": {
            "article": "A",
            "mainWord": "playground"
        },
        "es": {
            "article": "El",
            "mainWord": "patio de recreo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Spielplatz"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542442/vocimage/ecole/cour/blue-199261_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542442/vocimage/ecole/cour/playground-99509_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542443/vocimage/ecole/cour/hopscotch-3878606_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542443/vocimage/ecole/cour/high-school-5251586_640.jpg"]
}, {
    "id": 115,
    "name": "craie",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "craie"
        },
        "nl": {
            "article": "Het",
            "mainWord": "krijt"
        },
        "en": {
            "article": "A",
            "mainWord": "chalk"
        },
        "es": {
            "article": "La",
            "mainWord": "tiza"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kreide"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538267/vocimage/ecole/craie/chalk-1551571_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538268/vocimage/ecole/craie/background-1869471_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539825/vocimage/ecole/craie/street-painting-4250094_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539825/vocimage/ecole/craie/chalk-791173_1280.jpg"]
}, {
    "id": 116,
    "name": "feuille",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "feuille"
        },
        "nl": {
            "article": "Het",
            "mainWord": "blad"
        },
        "en": {
            "article": "A",
            "mainWord": "sheet"
        },
        "es": {
            "article": "La",
            "mainWord": "hoja"
        },
        "de": {
            "article": "Das",
            "mainWord": "Blatt"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542636/vocimage/ecole/feuille/post-it-notes-2836842_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542636/vocimage/ecole/feuille/fall-1646799_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542636/vocimage/ecole/feuille/notes-2582071_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542637/vocimage/ecole/feuille/pen-2211444_640.jpg"]
}, {
    "id": 117,
    "name": "gomme",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "gomme"
        },
        "nl": {
            "article": "De",
            "mainWord": "gom"
        },
        "en": {
            "article": "An",
            "mainWord": "eraser"
        },
        "es": {
            "article": "La",
            "mainWord": "goma"
        },
        "de": {
            "article": "Der",
            "mainWord": "Radiergummi"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538170/vocimage/ecole/gomme/eraser-3822402_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538173/vocimage/ecole/gomme/desktop-3288394_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540431/vocimage/ecole/gomme/office-utensils-701301_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540431/vocimage/ecole/gomme/pencil-1037609_640.jpg"]
}, {
    "id": 118,
    "name": "poubelle",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "poubelle"
        },
        "nl": {
            "article": "De",
            "mainWord": "vuilnisbak"
        },
        "en": {
            "article": "A",
            "mainWord": "bin"
        },
        "es": {
            "article": "La",
            "mainWord": "basura"
        },
        "de": {
            "article": "Der",
            "mainWord": "Mülleimer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536415/vocimage/ecole/poubelle/park-4337477__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536415/vocimage/ecole/poubelle/bike-986633__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536415/vocimage/ecole/poubelle/seagull-2425509__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536416/vocimage/ecole/poubelle/waste-4965944__340.jpg"]
}, {
    "id": 119,
    "name": "règle",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "règle"
        },
        "nl": {
            "article": "De",
            "mainWord": "lat"
        },
        "en": {
            "article": "A",
            "mainWord": "ruler"
        },
        "es": {
            "article": "La",
            "mainWord": "regla"
        },
        "de": {
            "article": "Das",
            "mainWord": "Lineal"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536404/vocimage/ecole/regle/ruler-3759480__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537987/vocimage/ecole/regle/pencil-3744152_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541039/vocimage/ecole/regle/blueprint-964629_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541039/vocimage/ecole/regle/pencils-4966995_640.jpg"]
}, {
    "id": 120,
    "name": "salle de gym",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "salle de gym"
        },
        "nl": {
            "article": "De",
            "mainWord": "turnzaal"
        },
        "en": {
            "article": "A",
            "mainWord": "gymnasium"
        },
        "es": {
            "article": "El",
            "mainWord": "gimnasio"
        },
        "de": {
            "article": "Die",
            "mainWord": "Sporthalle"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542074/vocimage/ecole/salle%20de%20gym/old-gym-in-hot-springs-3572774_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542075/vocimage/ecole/salle%20de%20gym/gym-1336355_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542075/vocimage/ecole/salle%20de%20gym/team-538078_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542081/vocimage/ecole/salle%20de%20gym/woman-694816_1280.jpg"]
}, {
    "id": 121,
    "name": "tablette",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "tablette"
        },
        "nl": {
            "article": "De",
            "mainWord": "tablet"
        },
        "en": {
            "article": "A",
            "mainWord": "tablet"
        },
        "es": {
            "article": "La",
            "mainWord": "tableta"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tablet"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690541316/vocimage/ecole/tablette/homework-3235100_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541401/vocimage/ecole/tablette/tablet-1910018_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541401/vocimage/ecole/tablette/ipad-1140444_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541456/vocimage/ecole/tablette/ipad-907577_640.jpg"]
}, {
    "id": 122,
    "name": "banc",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "banc"
        },
        "nl": {
            "article": "De",
            "mainWord": "bank"
        },
        "en": {
            "article": "A",
            "mainWord": "desk"
        },
        "es": {
            "article": "El",
            "mainWord": "escritorio"
        },
        "de": {
            "article": "Die",
            "mainWord": "Bank"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536121/vocimage/ecole/banc/school-desk-1670830__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537845/vocimage/ecole/banc/teaching-7467305_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537862/vocimage/ecole/banc/classroom-5510382_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690537973/vocimage/ecole/banc/school-1073571_640.jpg"]
}, {
    "id": 123,
    "name": "cahier",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cahier"
        },
        "nl": {
            "article": "Het",
            "mainWord": "schrift"
        },
        "en": {
            "article": "A",
            "mainWord": "notebook"
        },
        "es": {
            "article": "El",
            "mainWord": "cuaderno"
        },
        "de": {
            "article": "Das",
            "mainWord": "Heft"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538327/vocimage/ecole/cahier/notebooks-4845882_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538757/vocimage/ecole/cahier/boy-2349223_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538757/vocimage/ecole/cahier/notebook-1130742_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538826/vocimage/ecole/cahier/office-2607615_640.jpg"]
}, {
    "id": 124,
    "name": "cartable",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "cartable"
        },
        "nl": {
            "article": "De",
            "mainWord": "boekentas"
        },
        "en": {
            "article": "A",
            "mainWord": "school bag"
        },
        "es": {
            "article": "La",
            "mainWord": "mochila"
        },
        "de": {
            "article": "Der",
            "mainWord": "Schulranzen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536356/vocimage/ecole/cartable/person-907723__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536356/vocimage/ecole/cartable/teddy-828506__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539179/vocimage/ecole/cartable/student-1666118_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539180/vocimage/ecole/cartable/friday-forfuture-4635080_640.jpg"]
}, {
    "id": 125,
    "name": "classeur",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "classeur"
        },
        "nl": {
            "article": "De",
            "mainWord": "map"
        },
        "en": {
            "article": "A",
            "mainWord": "folder"
        },
        "es": {
            "article": "La",
            "mainWord": "carpeta"
        },
        "de": {
            "article": "Der",
            "mainWord": "Ordner"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690539306/vocimage/ecole/classeur/filing-cabinet-1205044_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539306/vocimage/ecole/classeur/filing-cabinet-1205068_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539306/vocimage/ecole/classeur/binder-532220_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539306/vocimage/ecole/classeur/planner-2641215_640.jpg"]
}, {
    "id": 126,
    "name": "compas",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "compas"
        },
        "nl": {
            "article": "De",
            "mainWord": "passer"
        },
        "en": {
            "article": "A",
            "mainWord": "compass"
        },
        "es": {
            "article": "El",
            "mainWord": "compás"
        },
        "de": {
            "article": "Der",
            "mainWord": "Zirkel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538317/vocimage/ecole/compas/to-paint-1772747_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538317/vocimage/ecole/compas/book-2595654_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539758/vocimage/ecole/compas/pair-of-compasses-6563844_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539758/vocimage/ecole/compas/architecture-5583021_640.jpg"]
}, {
    "id": 127,
    "name": "crayon",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "crayon"
        },
        "nl": {
            "article": "Het",
            "mainWord": "potlood"
        },
        "en": {
            "article": "A",
            "mainWord": "pencil"
        },
        "es": {
            "article": "El",
            "mainWord": "lápiz"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bleistift"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538083/vocimage/ecole/crayon/pencil-3744153_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539941/vocimage/ecole/crayon/kids-1093758_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539941/vocimage/ecole/crayon/blank-4857293_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690539941/vocimage/ecole/crayon/office-4843532_640.jpg"]
}, {
    "id": 128,
    "name": "crayon de couleur",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "crayon de couleur"
        },
        "nl": {
            "article": "Het",
            "mainWord": "kleurpotlood"
        },
        "en": {
            "article": "A",
            "mainWord": "colored pencil"
        },
        "es": {
            "article": "El",
            "mainWord": "lápiz de color"
        },
        "de": {
            "article": "Der",
            "mainWord": "Farbstift"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536326/vocimage/ecole/crayon%20de%20couleur/school-times-3599180__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536326/vocimage/ecole/crayon%20de%20couleur/colored-pencils-2934857__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536326/vocimage/ecole/crayon%20de%20couleur/colored-pencils-1506589__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538122/vocimage/ecole/crayon%20de%20couleur/pencils-3697668_1280.jpg"]
}, {
    "id": 129,
    "name": "feutre",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "feutre"
        },
        "nl": {
            "article": "De",
            "mainWord": "stift"
        },
        "en": {
            "article": "A",
            "mainWord": "marker"
        },
        "es": {
            "article": "El",
            "mainWord": "rotulador"
        },
        "de": {
            "article": "Der",
            "mainWord": "Filzstift"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536306/vocimage/ecole/feutre/colored-pencils-175263__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538567/vocimage/ecole/feutre/color-4792886_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538567/vocimage/ecole/feutre/pens-3693522_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538568/vocimage/ecole/feutre/highlighter-5427199_640.jpg"]
}, {
    "id": 130,
    "name": "journal de classe",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "journal de classe"
        },
        "nl": {
            "article": "De",
            "mainWord": "schoolagenda"
        },
        "en": {
            "article": "A",
            "mainWord": "diary"
        },
        "es": {
            "article": "El",
            "mainWord": "diario"
        },
        "de": {
            "article": "Das",
            "mainWord": "Tagebuch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536280/vocimage/ecole/journal%20de%20classe/organizer-791939__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538419/vocimage/ecole/journal%20de%20classe/agenda-2241986_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538426/vocimage/ecole/journal%20de%20classe/home-office-5105229_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540583/vocimage/ecole/journal%20de%20classe/agenda-582976_640.jpg"]
}, {
    "id": 131,
    "name": "livre",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "livre"
        },
        "nl": {
            "article": "De",
            "mainWord": "boek"
        },
        "en": {
            "article": "A",
            "mainWord": "book"
        },
        "es": {
            "article": "El",
            "mainWord": "libro"
        },
        "de": {
            "article": "Das",
            "mainWord": "Buch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536171/vocimage/ecole/livre/reading-925589__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538203/vocimage/ecole/livre/read-1997581_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538216/vocimage/ecole/livre/to-read-the-book-2784895_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540672/vocimage/ecole/livre/book-6348668_640.jpg"]
}, {
    "id": 132,
    "name": "plumier",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "plumier"
        },
        "nl": {
            "article": "De",
            "mainWord": "pennenzak"
        },
        "en": {
            "article": "A",
            "mainWord": "pencil case"
        },
        "es": {
            "article": "El",
            "mainWord": "estuche"
        },
        "de": {
            "article": "Die",
            "mainWord": "Mappe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536261/vocimage/ecole/plumier/school-1328420__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536261/vocimage/ecole/plumier/pencil-case-932143__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536261/vocimage/ecole/plumier/bag-1986556__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540880/vocimage/ecole/plumier/pencil-box-5136816_640.jpg"]
}, {
    "id": 133,
    "name": "professeur",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "professeur"
        },
        "nl": {
            "article": "De",
            "mainWord": "leraar"
        },
        "en": {
            "article": "A",
            "mainWord": "teacher"
        },
        "es": {
            "article": "El",
            "mainWord": "profesor"
        },
        "de": {
            "article": "Der",
            "mainWord": "Lehrer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536105/vocimage/ecole/professeur/teacher-4784917__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540974/vocimage/ecole/professeur/kids-2685070_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540975/vocimage/ecole/professeur/school-1782427_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540975/vocimage/ecole/professeur/robot-6405858_640.jpg"]
}, {
    "id": 134,
    "name": "réfectoire",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "réfectoire"
        },
        "nl": {
            "article": "De",
            "mainWord": "eetzaal"
        },
        "en": {
            "article": "A",
            "mainWord": "dining hall"
        },
        "es": {
            "article": "El",
            "mainWord": "refectorio"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kantine"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690542206/vocimage/ecole/refectoire/food-3808953_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542206/vocimage/ecole/refectoire/tables-461863_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542206/vocimage/ecole/refectoire/meal-1260819_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690542208/vocimage/ecole/refectoire/school-5956716_640.jpg"]
}, {
    "id": 135,
    "name": "stylo-bille",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "stylo-bille"
        },
        "nl": {
            "article": "De",
            "mainWord": "balpen"
        },
        "en": {
            "article": "A",
            "mainWord": "ballpoint"
        },
        "es": {
            "article": "El",
            "mainWord": "bolígrafo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Kugelschreiber"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690541130/vocimage/ecole/stylo%20bille/street-map-2679271_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541130/vocimage/ecole/stylo%20bille/paper-3406864_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541130/vocimage/ecole/stylo%20bille/ballpoint-pen-6527860_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541130/vocimage/ecole/stylo%20bille/hand-226358_640.jpg"]
}, {
    "id": 136,
    "name": "stylo-plume",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "stylo-plume"
        },
        "nl": {
            "article": "De",
            "mainWord": "vulpen"
        },
        "en": {
            "article": "A",
            "mainWord": "fountain pen"
        },
        "es": {
            "article": "La",
            "mainWord": "pluma"
        },
        "de": {
            "article": "Der",
            "mainWord": "Füllfederhalter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536247/vocimage/ecole/stylo%20plume/feather-3237961__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538230/vocimage/ecole/stylo%20plume/ink-pen-865998_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538230/vocimage/ecole/stylo%20plume/pen-2683078_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541198/vocimage/ecole/stylo%20plume/ink-pen-4211403_640.jpg"]
}, {
    "id": 137,
    "name": "tableau",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tableau"
        },
        "nl": {
            "article": "Het",
            "mainWord": "bord"
        },
        "en": {
            "article": "A",
            "mainWord": "blackboard"
        },
        "es": {
            "article": "La",
            "mainWord": "pizarra"
        },
        "de": {
            "article": "Die",
            "mainWord": "Tafel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538149/vocimage/ecole/tableau/to-learn-3968765_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538149/vocimage/ecole/tableau/steinbach-56642_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541291/vocimage/ecole/tableau/math-1547018_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690541291/vocimage/ecole/tableau/school-926213_1280.jpg"]
}, {
    "id": 138,
    "name": "taille-crayon",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "taille-crayon"
        },
        "nl": {
            "article": "De",
            "mainWord": "slijper"
        },
        "en": {
            "article": "A",
            "mainWord": "pencil sharpener"
        },
        "es": {
            "article": "El",
            "mainWord": "sacapuntas"
        },
        "de": {
            "article": "Der",
            "mainWord": "Spitzer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536233/vocimage/ecole/taille%20crayons/pencils-1365337__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536233/vocimage/ecole/taille%20crayons/pencil-sharpener-3122007__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538010/vocimage/ecole/taille%20crayons/pencil-5055481_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538025/vocimage/ecole/taille%20crayons/school-2184097_1280.jpg"]
}, {
    "id": 139,
    "name": "ciseaux",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "ciseaux"
        },
        "nl": {
            "article": "De",
            "mainWord": "schaar"
        },
        "en": {
            "article": "The",
            "mainWord": "scissors"
        },
        "es": {
            "article": "Las",
            "mainWord": "tijeras"
        },
        "de": {
            "article": "Die",
            "mainWord": "Schere"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690536197/vocimage/ecole/ciseaux/cord-4088055__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536197/vocimage/ecole/ciseaux/school-supplies-2690530__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690536197/vocimage/ecole/ciseaux/schultute-1499049__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538602/vocimage/ecole/ciseaux/back-to-school-930381_640.jpg"]
}, {
    "id": 140,
    "name": "ordinateur",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "ordinateur"
        },
        "nl": {
            "article": "De",
            "mainWord": "computer"
        },
        "en": {
            "article": "A",
            "mainWord": "computer"
        },
        "es": {
            "article": "El",
            "mainWord": "ordenador"
        },
        "de": {
            "article": "Der",
            "mainWord": "Computer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690538286/vocimage/ecole/ordinateur/laptop-2561181_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538293/vocimage/ecole/ordinateur/laptop-2594846_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690538301/vocimage/ecole/ordinateur/e-learning-3734521_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690540744/vocimage/ecole/ordinateur/children-1311506_640.jpg"]
}, {
    "id": 141,
    "name": "Mathématiques",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Mathématiques"
        },
        "nl": {
            "article": "",
            "mainWord": "Wiskunde"
        },
        "en": {
            "article": "",
            "mainWord": "Mathematics"
        },
        "es": {
            "article": "",
            "mainWord": "Matemáticas"
        },
        "de": {
            "article": "",
            "mainWord": "Mathematik"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690545312/vocimage/ecole/mathematiques/to-learn-2300141_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545312/vocimage/ecole/mathematiques/to-learn-3701963_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545312/vocimage/ecole/mathematiques/geometry-1023846_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545323/vocimage/ecole/mathematiques/mathematics-757566_640.jpg"]
}, {
    "id": 142,
    "name": "Néerlandais",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Néerlandais"
        },
        "nl": {
            "article": "",
            "mainWord": "Nederlands"
        },
        "en": {
            "article": "",
            "mainWord": "Dutch"
        },
        "es": {
            "article": "",
            "mainWord": "Neerlandés"
        },
        "de": {
            "article": "",
            "mainWord": "Niederländisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690563749/vocimage/ecole/neerlandais/ndls3.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690563749/vocimage/ecole/neerlandais/ndls4.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690563749/vocimage/ecole/neerlandais/ndls1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690563749/vocimage/ecole/neerlandais/ndls2.jpg"]
}, {
    "id": 143,
    "name": "Sciences",
    "themeId": 2,
    "translations": {
        "fr": {
            "article": "",
            "mainWord": "Sciences"
        },
        "nl": {
            "article": "",
            "mainWord": "Wetenschappen"
        },
        "en": {
            "article": "",
            "mainWord": "Sciences"
        },
        "es": {
            "article": "",
            "mainWord": "Ciencias"
        },
        "de": {
            "article": "",
            "mainWord": "Wissenschaft"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690545629/vocimage/ecole/sciences/chemistry-5632651_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545629/vocimage/ecole/sciences/dna-7090994_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545629/vocimage/ecole/sciences/heart-2607178_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690545629/vocimage/ecole/sciences/microscope-5593459_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}