export const CHOOSE_POSSIBLE_LETTER = 'CHOOSE_POSSIBLE_LETTER';
export const CHOOSE_PLAYER_LETTER = 'CHOOSE_PLAYER_LETTER';
export const DISCOVER_LETTER = 'DISCOVER_LETTER';

import {indexFirstFreeLetter, isTimeToCheckLetters, isFullWord, freeLettersCount} from '../../helpers/letters'

const possibleLetterToPlayerLetter = (possibleLetter, indexFirstFreePlayerLetter, newPlayerLetters, newPossibleLetters) => {
    const playerLetter = {...possibleLetter};
    playerLetter.afterWhiteSpace = newPlayerLetters[indexFirstFreePlayerLetter].afterWhiteSpace;
    newPlayerLetters[indexFirstFreePlayerLetter] = playerLetter;

    const newPossibleLetter  = {...possibleLetter};
    newPossibleLetter.visible = false;       
    newPossibleLetters[newPossibleLetter.position] = newPossibleLetter;
}

export function ChoosePossibleLetter(possibleLetter, found, possibleLetters, playerLetters, wordToFind, wordIds) {
    let newPlayerLetters = [...playerLetters];
    let newPossibleLetters = [...possibleLetters];
    let newFound = false;
    let newFinished = false;
    if (!found && possibleLetter.visible && !possibleLetter.locked && !isFullWord(playerLetters) ) {
        const checkPlayerLetters = isTimeToCheckLetters(playerLetters);
        const indexFirstFreePlayerLetter = indexFirstFreeLetter(playerLetters);
        possibleLetterToPlayerLetter(possibleLetter, indexFirstFreePlayerLetter, newPlayerLetters, newPossibleLetters);                
        if (checkPlayerLetters) {
            const playerWordArray = newPlayerLetters.filter(letter => letter.visible === true).map(letter => letter.letter);
            const playerWord = playerWordArray.join('');
            if (wordToFind.toLowerCase() === playerWord.toLowerCase()) {
                newFound = true;
                if (wordIds.length == 1) {
                    newFinished = true;
                }
            }
        }
    }               
    return {
        type: CHOOSE_POSSIBLE_LETTER,
        payload: {newPossibleLetters, newPlayerLetters, newFound, newFinished}
    }    
}

export function discoverLetter(possibleLetters, playerLetters, wordToFind) {
    let newPlayerLetters = [...playerLetters];
    let newPossibleLetters = [...possibleLetters];
    let error = true;
    if (freeLettersCount(playerLetters) > 1) {
        const indexFirstFreePlayerLetter = indexFirstFreeLetter(playerLetters);
        const letterToDiscover = wordToFind[indexFirstFreePlayerLetter];   
        const possibleLetter = possibleLetters.find(item => item.visible && !item.locked && item.letter == letterToDiscover);
        if (possibleLetter != undefined) {            
            possibleLetterToPlayerLetter(possibleLetter, indexFirstFreePlayerLetter, newPlayerLetters, newPossibleLetters);
            newPlayerLetters[indexFirstFreePlayerLetter].locked = true;
            newPossibleLetters[possibleLetter.position].locked = true;
            error = false;        
        }
    }               
    return {
        type: DISCOVER_LETTER,
        payload: {newPossibleLetters, newPlayerLetters},
        error: error
    }    
}

export function ChoosePlayerLetter(playerLetter, found, possibleLetters, playerLetters) {
    let newPlayerLetters = [...playerLetters];
    let newPossibleLetters = [...possibleLetters];
    if (!found && playerLetter.visible && !playerLetter.locked) {
        const indexLetter = playerLetters.indexOf(playerLetter);
        if (indexLetter > -1) {
            newPossibleLetters[playerLetter.position].visible = true;

            newPlayerLetters[indexLetter].letter = '';
            newPlayerLetters[indexLetter].visible = false;
            newPlayerLetters[indexLetter].position = indexLetter + 10000;

        }                
    }
    return {
        type: CHOOSE_PLAYER_LETTER,
        payload: {newPossibleLetters, newPlayerLetters}
    }    
}