const words = 
[{
    "id": 1301,
    "name": "frère",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "frère"
        },
        "nl": {
            "article": "De",
            "mainWord": "broer"
        },
        "en": {
            "article": "The",
            "mainWord": "brother"
        },
        "es": {
            "article": "El",
            "mainWord": "hermano"
        },
        "de": {
            "article": "Der",
            "mainWord": "Bruder"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728570/vocimage/famille/frere/adorable-2178857__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728632/vocimage/famille/frere/brothers-and-sisters-692822__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729735/vocimage/famille/frere/brothers-1633653_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729735/vocimage/famille/frere/mario-1557973_640.jpg"]
}, {
    "id": 1302,
    "name": "père",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "père"
        },
        "nl": {
            "article": "De",
            "mainWord": "vader"
        },
        "en": {
            "article": "The",
            "mainWord": "father"
        },
        "es": {
            "article": "El",
            "mainWord": "padre"
        },
        "de": {
            "article": "Der",
            "mainWord": "Vater"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728621/vocimage/famille/pere/father-1063355__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728621/vocimage/famille/pere/people-2557510__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730406/vocimage/famille/pere/girl-1641215_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730459/vocimage/famille/pere/child-5489290_640.jpg"]
}, {
    "id": 1303,
    "name": "famille",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "famille"
        },
        "nl": {
            "article": "De",
            "mainWord": "familie"
        },
        "en": {
            "article": "The",
            "mainWord": "family"
        },
        "es": {
            "article": "La",
            "mainWord": "familia"
        },
        "de": {
            "article": "Die",
            "mainWord": "Familie"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728597/vocimage/famille/famille/sibling-1023003__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728597/vocimage/famille/famille/hands-4114905__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728597/vocimage/famille/famille/family-1016311__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728597/vocimage/famille/famille/elephant-4120822__340.jpg"]
}, {
    "id": 1304,
    "name": "sœur",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "sœur"
        },
        "nl": {
            "article": "De",
            "mainWord": "zus"
        },
        "en": {
            "article": "The",
            "mainWord": "sister"
        },
        "es": {
            "article": "La",
            "mainWord": "hermana"
        },
        "de": {
            "article": "Die",
            "mainWord": "schwester"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728580/vocimage/famille/soeur/children-1545118__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728580/vocimage/famille/soeur/sisters-1109596__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728581/vocimage/famille/soeur/sisters-2210314__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730473/vocimage/famille/soeur/girls-462072_640.jpg"]
}, {
    "id": 1305,
    "name": "mère",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "mère"
        },
        "nl": {
            "article": "De",
            "mainWord": "moeder"
        },
        "en": {
            "article": "The",
            "mainWord": "mother"
        },
        "es": {
            "article": "La",
            "mainWord": "madre"
        },
        "de": {
            "article": "Die",
            "mainWord": "Mutter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728547/vocimage/famille/mere/baby-1851485__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730010/vocimage/famille/mere/adult-1807500_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730231/vocimage/famille/mere/maternity-1542584_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730237/vocimage/famille/mere/family-4447538_640.jpg"]
}, {
    "id": 1306,
    "name": "parents",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "parents"
        },
        "nl": {
            "article": "De",
            "mainWord": "ouders"
        },
        "en": {
            "article": "The",
            "mainWord": "parents"
        },
        "es": {
            "article": "Los",
            "mainWord": "padres"
        },
        "de": {
            "article": "Die",
            "mainWord": "Eltern"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690730392/vocimage/famille/parents/family-2610205_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730393/vocimage/famille/parents/family-1866868_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730393/vocimage/famille/parents/family-5074441_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690730393/vocimage/famille/parents/family-6002641_640.jpg"]
}, {
    "id": 1307,
    "name": "grand-père",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "grand-père"
        },
        "nl": {
            "article": "De",
            "mainWord": "grootvader"
        },
        "en": {
            "article": "The",
            "mainWord": "grandfather"
        },
        "es": {
            "article": "El",
            "mainWord": "abuelo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Großevater"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728510/vocimage/famille/grand%20pere/grandpa-2043587__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728516/vocimage/famille/grand%20pere/man-2806396__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728516/vocimage/famille/grand%20pere/person-3500815__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729995/vocimage/famille/grand%20pere/boy-373441_640.jpg"]
}, {
    "id": 1308,
    "name": "grand-mère",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "grand-mère"
        },
        "nl": {
            "article": "De",
            "mainWord": "grootmoeder"
        },
        "en": {
            "article": "The",
            "mainWord": "grandmother"
        },
        "es": {
            "article": "La",
            "mainWord": "abuela"
        },
        "de": {
            "article": "Die",
            "mainWord": "Großemutter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728486/vocimage/famille/grand%20mere/birthday-3807315__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728491/vocimage/famille/grand%20mere/grandma-3628304__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728495/vocimage/famille/grand%20mere/for-reading-796374__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729894/vocimage/famille/grand%20mere/girl-1054307_640.jpg"]
}, {
    "id": 1309,
    "name": "fille",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "fille"
        },
        "nl": {
            "article": "De",
            "mainWord": "dochter"
        },
        "en": {
            "article": "The",
            "mainWord": "daughter"
        },
        "es": {
            "article": "La",
            "mainWord": "hija"
        },
        "de": {
            "article": "Die",
            "mainWord": "tochter"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728469/vocimage/famille/fille/baby-1151348__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728470/vocimage/famille/fille/child-1073638__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690728991/vocimage/famille/fille/father-1808749_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729043/vocimage/famille/fille/kids-4345109_640.jpg"]
}, {
    "id": 1310,
    "name": "fils",
    "themeId": 14,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "fils"
        },
        "nl": {
            "article": "De",
            "mainWord": "zoon"
        },
        "en": {
            "article": "The",
            "mainWord": "son"
        },
        "es": {
            "article": "El",
            "mainWord": "hijo"
        },
        "de": {
            "article": "Der",
            "mainWord": "sohn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690728461/vocimage/famille/fils/foot-race-776446__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729589/vocimage/famille/fils/boy-5439291_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729589/vocimage/famille/fils/father-2212092_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690729589/vocimage/famille/fils/baby-4338995_640.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}