const words = 
[{
    "id": 2001,
    "name": "alpinisme",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "alpinisme"
        },
        "nl": {
            "article": "De",
            "mainWord": "bergsport"
        },
        "en": {
            "article": "",
            "mainWord": "Climbing"
        },
        "es": {
            "article": "El",
            "mainWord": "alpinismo"
        },
        "de": {
            "article": "der",
            "mainWord": "Bergsport"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000611/vocimage/montagne/alpinisme/climb-2125148__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000757/vocimage/montagne/alpinisme/mountains-7846880_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000757/vocimage/montagne/alpinisme/mountain-climbing-2124113_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000757/vocimage/montagne/alpinisme/matterhorn-968_640.jpg"]
}, {
    "id": 2002,
    "name": "avalanche",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "avalanche"
        },
        "nl": {
            "article": "De",
            "mainWord": "lawine"
        },
        "en": {
            "article": "An",
            "mainWord": "avalanche"
        },
        "es": {
            "article": "La",
            "mainWord": "avalancha"
        },
        "de": {
            "article": "die",
            "mainWord": "Lawine"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001018/vocimage/montagne/avalanche/avalanche-16182__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001018/vocimage/montagne/avalanche/avalanche-4967568__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001018/vocimage/montagne/avalanche/mountain-4254821__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001018/vocimage/montagne/avalanche/avalanche-2518679__340.jpg"]
}, {
    "id": 2003,
    "name": "falaise",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "falaise"
        },
        "nl": {
            "article": "De",
            "mainWord": "klif"
        },
        "en": {
            "article": "A",
            "mainWord": "cliff"
        },
        "es": {
            "article": "El",
            "mainWord": "acantilado"
        },
        "de": {
            "article": "die",
            "mainWord": "Klippe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000905/vocimage/montagne/falaise/landscape-4895932__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000905/vocimage/montagne/falaise/landscape-4988078__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000905/vocimage/montagne/falaise/iceland-413700__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000985/vocimage/montagne/falaise/cliff-1840442_640.jpg"]
}, {
    "id": 2004,
    "name": "montagne",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "montagne"
        },
        "nl": {
            "article": "De",
            "mainWord": "berg"
        },
        "en": {
            "article": "The",
            "mainWord": "mountain"
        },
        "es": {
            "article": "La",
            "mainWord": "montaña"
        },
        "de": {
            "article": "der",
            "mainWord": "Berg"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000461/vocimage/montagne/montagne/mountains-3959204__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000519/vocimage/montagne/montagne/mountain-landscape-2031539_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000519/vocimage/montagne/montagne/mountains-736886_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000519/vocimage/montagne/montagne/ama-dablam-2064522_640.jpg"]
}, {
    "id": 2005,
    "name": "neige",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "neige"
        },
        "nl": {
            "article": "De",
            "mainWord": "sneeuw"
        },
        "en": {
            "article": "The",
            "mainWord": "snow"
        },
        "es": {
            "article": "La",
            "mainWord": "nieve"
        },
        "de": {
            "article": "der",
            "mainWord": "Schnee"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001164/vocimage/montagne/neige/snowflakes-1236247__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001164/vocimage/montagne/neige/winter-260817__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001164/vocimage/montagne/neige/white-horse-3010129__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001234/vocimage/montagne/neige/snow-17854_1280.jpg"]
}, {
    "id": 2006,
    "name": "patinoire",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "patinoire"
        },
        "nl": {
            "article": "De",
            "mainWord": "ijsbaan"
        },
        "en": {
            "article": "A",
            "mainWord": "rink"
        },
        "es": {
            "article": "La",
            "mainWord": "pista de patinaje"
        },
        "de": {
            "article": "die",
            "mainWord": "Eisbahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000785/vocimage/montagne/patinoire/indoors-3133818__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000785/vocimage/montagne/patinoire/curling-882649__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000810/vocimage/montagne/patinoire/skating-3817358__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000810/vocimage/montagne/patinoire/skating-4840119__340.jpg"]
}, {
    "id": 2007,
    "name": "randonnée",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "randonnée"
        },
        "nl": {
            "article": "De",
            "mainWord": "tocht"
        },
        "en": {
            "article": "",
            "mainWord": "Hiking"
        },
        "es": {
            "article": "La",
            "mainWord": "caminata"
        },
        "de": {
            "article": "die",
            "mainWord": "Wanderung"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001260/vocimage/montagne/randonnee/children-4355469__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001260/vocimage/montagne/randonnee/trekking-245311__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001260/vocimage/montagne/randonnee/hiking-1312226__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001260/vocimage/montagne/randonnee/adventure-5062314__340.jpg"]
}, {
    "id": 2008,
    "name": "vallée",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "vallée"
        },
        "nl": {
            "article": "De",
            "mainWord": "vallei"
        },
        "en": {
            "article": "A",
            "mainWord": "valley"
        },
        "es": {
            "article": "El",
            "mainWord": "valle"
        },
        "de": {
            "article": "das",
            "mainWord": "Tal"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001291/vocimage/montagne/valee/italy-2064518__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001291/vocimage/montagne/valee/mountain-3846170__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001370/vocimage/montagne/valee/valley-63564_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001370/vocimage/montagne/valee/mountains-192987_640.jpg"]
}, {
    "id": 2009,
    "name": "chalet",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "chalet"
        },
        "nl": {
            "article": "Het",
            "mainWord": "chalet"
        },
        "en": {
            "article": "A",
            "mainWord": "chalet"
        },
        "es": {
            "article": "El",
            "mainWord": "chalet"
        },
        "de": {
            "article": "die",
            "mainWord": "Berghütte"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691002076/vocimage/montagne/chalet/small-wooden-house-906912_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002076/vocimage/montagne/chalet/house-2151102_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002076/vocimage/montagne/chalet/snow-3084112_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002077/vocimage/montagne/chalet/chalet-977681_640.jpg"]
}, {
    "id": 2010,
    "name": "glacier",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "glacier"
        },
        "nl": {
            "article": "De",
            "mainWord": "ijslaag"
        },
        "en": {
            "article": "A",
            "mainWord": "glacier"
        },
        "es": {
            "article": "El",
            "mainWord": "glaciar"
        },
        "de": {
            "article": "der",
            "mainWord": "Gletscher"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691002267/vocimage/montagne/glacier/nature-1463830_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002267/vocimage/montagne/glacier/glacier-1740874_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002267/vocimage/montagne/glacier/man-1869194_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002268/vocimage/montagne/glacier/greenland-81241_640.jpg"]
}, {
    "id": 2011,
    "name": "lac",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "lac"
        },
        "nl": {
            "article": "Het",
            "mainWord": "meer"
        },
        "en": {
            "article": "A",
            "mainWord": "lake"
        },
        "es": {
            "article": "El",
            "mainWord": "lago"
        },
        "de": {
            "article": "der",
            "mainWord": "See"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001577/vocimage/montagne/lac/sunrise-1634197__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001577/vocimage/montagne/lac/alberta-2297204__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001577/vocimage/montagne/lac/travel-1749508__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001577/vocimage/montagne/lac/ducks-1980180__340.jpg"]
}, {
    "id": 2012,
    "name": "patinage",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "patinage"
        },
        "nl": {
            "article": "",
            "mainWord": "Schaatsen"
        },
        "en": {
            "article": "",
            "mainWord": "Ice skating"
        },
        "es": {
            "article": "El",
            "mainWord": "patinaje"
        },
        "de": {
            "article": "",
            "mainWord": "Schlittschuhlaufen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001591/vocimage/montagne/patinage/skates-2001797__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001591/vocimage/montagne/patinage/figure-skating-3198861__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001591/vocimage/montagne/patinage/ice-skating-235547__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001659/vocimage/montagne/patinage/skates-3623008_640.jpg"]
}, {
    "id": 2013,
    "name": "refuge",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "refuge"
        },
        "nl": {
            "article": "De",
            "mainWord": "berghut"
        },
        "en": {
            "article": "A",
            "mainWord": "refuge"
        },
        "es": {
            "article": "El",
            "mainWord": "refugio"
        },
        "de": {
            "article": "der",
            "mainWord": "Berghorst"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000599/vocimage/montagne/refuge/cottage-324814__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000599/vocimage/montagne/refuge/tree-3095683__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000599/vocimage/montagne/refuge/winter-3833527__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001947/vocimage/montagne/refuge/fiescheralp-3214980__340.jpg"]
}, {
    "id": 2014,
    "name": "rocher",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "rocher"
        },
        "nl": {
            "article": "De",
            "mainWord": "rots"
        },
        "en": {
            "article": "A",
            "mainWord": "rock"
        },
        "es": {
            "article": "",
            "mainWord": "Una roca"
        },
        "de": {
            "article": "der",
            "mainWord": "Felsen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001708/vocimage/montagne/rocher/rocks-4353305__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001708/vocimage/montagne/rocher/rocks-316748__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001823/vocimage/montagne/rocher/stone-2715446_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001829/vocimage/montagne/rocher/cliff-6376745_640.jpg"]
}, {
    "id": 2015,
    "name": "sac à dos",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sac à dos"
        },
        "nl": {
            "article": "De",
            "mainWord": "rugzak"
        },
        "en": {
            "article": "A",
            "mainWord": "backpack"
        },
        "es": {
            "article": "La",
            "mainWord": "mochila"
        },
        "de": {
            "article": "der",
            "mainWord": "Rucksack"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691002497/vocimage/montagne/sac%20a%20dos/climbing-2446337_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002497/vocimage/montagne/sac%20a%20dos/backpack-1868720_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002497/vocimage/montagne/sac%20a%20dos/hiking-6154252_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691002497/vocimage/montagne/sac%20a%20dos/hike-1312226_640.jpg"]
}, {
    "id": 2016,
    "name": "sommet",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sommet"
        },
        "nl": {
            "article": "De",
            "mainWord": "top"
        },
        "en": {
            "article": "The",
            "mainWord": "top"
        },
        "es": {
            "article": "La",
            "mainWord": "cumbre"
        },
        "de": {
            "article": "Der",
            "mainWord": "Gipfel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001855/vocimage/montagne/sommet/adventure-1850912__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001855/vocimage/montagne/sommet/sky-3149114__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001897/vocimage/montagne/sommet/rough-horn-2146181_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001897/vocimage/montagne/sommet/schilthorn-3033448_640.jpg"]
}, {
    "id": 2017,
    "name": "téléphérique",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "téléphérique"
        },
        "nl": {
            "article": "De",
            "mainWord": "kabelbaan"
        },
        "en": {
            "article": "A",
            "mainWord": "cableway"
        },
        "es": {
            "article": "El",
            "mainWord": "teleférico"
        },
        "de": {
            "article": "die",
            "mainWord": "Drahtseilbahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691000839/vocimage/montagne/telepherique/ski-3201021__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000839/vocimage/montagne/telepherique/kreuzjochbahn-4488952__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000840/vocimage/montagne/telepherique/sugarloaf-mountain-1679285__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691000888/vocimage/montagne/telepherique/mountains-7608306_640.jpg"]
}, {
    "id": 2018,
    "name": "télésiège",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "télésiège"
        },
        "nl": {
            "article": "De",
            "mainWord": "skilift"
        },
        "en": {
            "article": "A",
            "mainWord": "chairlift"
        },
        "es": {
            "article": "El",
            "mainWord": "telesilla"
        },
        "de": {
            "article": "der",
            "mainWord": "Skilift"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001055/vocimage/montagne/telesiege/transport-1504672__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001055/vocimage/montagne/telesiege/chairlift-2087108__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001055/vocimage/montagne/telesiege/skiing-589593__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001125/vocimage/montagne/telesiege/chairlift-5476093_640.jpg"]
}, {
    "id": 2019,
    "name": "traîneau",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "traîneau"
        },
        "nl": {
            "article": "De",
            "mainWord": "slee"
        },
        "en": {
            "article": "A",
            "mainWord": "sleigh"
        },
        "es": {
            "article": "El",
            "mainWord": "trineo"
        },
        "de": {
            "article": "der",
            "mainWord": "Schlitten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001148/vocimage/montagne/traineau/russia-1070159__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001148/vocimage/montagne/traineau/lapland-4688371__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001148/vocimage/montagne/traineau/christmas-sleigh-3878600__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001148/vocimage/montagne/traineau/huskies-273409__340.jpg"]
}, {
    "id": 2020,
    "name": "village",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "village"
        },
        "nl": {
            "article": "Het",
            "mainWord": "dorp"
        },
        "en": {
            "article": "A",
            "mainWord": "village"
        },
        "es": {
            "article": "El",
            "mainWord": "pueblo"
        },
        "de": {
            "article": "das",
            "mainWord": "Dorf"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691164641/vocimage/ville/village/village-5121386__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691164641/vocimage/ville/village/greece-3384386__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691168319/vocimage/ville/village/canary-1123102_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691168319/vocimage/ville/village/shirakawa-go-3968395_640.jpg"]
}, {
    "id": 2021,
    "name": "skis",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "skis"
        },
        "nl": {
            "article": "",
            "mainWord": "Ski's"
        },
        "en": {
            "article": "",
            "mainWord": "Skis"
        },
        "es": {
            "article": "Los",
            "mainWord": "esquíes"
        },
        "de": {
            "article": "die",
            "mainWord": "Skis"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001681/vocimage/montagne/skis/skis-1785285__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001681/vocimage/montagne/skis/skis-584600__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001682/vocimage/montagne/skis/classic-4550937__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001682/vocimage/montagne/skis/skis-3303653__340.jpg"]
}, {
    "id": 2022,
    "name": "sports d'hiver",
    "themeId": 21,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "sports d'hiver"
        },
        "nl": {
            "article": "De",
            "mainWord": "wintersport"
        },
        "en": {
            "article": "",
            "mainWord": "Winter sports"
        },
        "es": {
            "article": "Los",
            "mainWord": "deportes de invierno"
        },
        "de": {
            "article": "der",
            "mainWord": "Wintersport"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691001405/vocimage/montagne/sports%20hiver/snow-3048637__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001453/vocimage/montagne/sports%20hiver/ski-race-2246889_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001453/vocimage/montagne/sports%20hiver/snow-3066167_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691001545/vocimage/montagne/sports%20hiver/shirakawa-go-3968395__340.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}