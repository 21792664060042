import React from 'react';
import allWords from '../../data/allWords';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadGame } from '../actions/game.js';
import { changeImages } from '../actions/image.js';
import Images from './Images';
import Testools from './Testools';

class Test extends React.Component {

    componentWillMount() {
        if (this.props.game.finished) {
            const words = allWords();
            const maxwords = 0; //testApp : to test all words
            this.props.loadGame(words, this.props.themesids, maxwords, this.props.language, this.props.level);
        }
    }

    componentDidUpdate() {
        if (this.props.game.images.length == 0) {           
            this.props.changeImages(this.props.game.word, process.env.PIXABAY_API_KEY);
        }
    }

    render() {
        return(
            <div className="container-vocimage">
                <Images />
                <Testools />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        game: state.game,        
        themesids: state.themesids,
        maxwords: state.maxwords,
        language: state.language,
        level: state.level
        }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { 
      loadGame,
      changeImages,
    },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Test);