const words = 
[{
    "id": 801,
    "name": "pied",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "A",
            "mainWord": "pied"
        },
        "nl": {
            "article": "Te",
            "mainWord": "voet"
        },
        "en": {
            "article": "On",
            "mainWord": "foot"
        },
        "es": {
            "article": "A",
            "mainWord": "pie"
        },
        "de": {
            "article": "zu",
            "mainWord": "Fuss"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076872/vocimage/transports/a%20pied/man-6276765_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076872/vocimage/transports/a%20pied/mother-1077334_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076872/vocimage/transports/a%20pied/boy-909554_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076872/vocimage/transports/a%20pied/girl-447701_640.jpg"]
}, {
    "id": 802,
    "name": "aéroport",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "aéroport"
        },
        "nl": {
            "article": "De",
            "mainWord": "luchthaven"
        },
        "en": {
            "article": "An",
            "mainWord": "airport"
        },
        "es": {
            "article": "El",
            "mainWord": "aeropuerto"
        },
        "de": {
            "article": "der",
            "mainWord": "Flughafen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076739/vocimage/transports/aeroport/airport-1890943__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076991/vocimage/transports/aeroport/airport-1822133_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076991/vocimage/transports/aeroport/airport-5387490_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076991/vocimage/transports/aeroport/airport-3511342_640.jpg"]
}, {
    "id": 803,
    "name": "ambulance",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "ambulance"
        },
        "nl": {
            "article": "De",
            "mainWord": "ambulance"
        },
        "en": {
            "article": "An",
            "mainWord": "ambulance"
        },
        "es": {
            "article": "La",
            "mainWord": "ambulancia"
        },
        "de": {
            "article": "der",
            "mainWord": "Krankenwagen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076732/vocimage/transports/ambulance/medical-emergency-1057706__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077080/vocimage/transports/ambulance/ambulance-970037_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077080/vocimage/transports/ambulance/traffic-lights-49698_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077080/vocimage/transports/ambulance/ambulance-2901017_640.jpg"]
}, {
    "id": 804,
    "name": "arrêt",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "arrêt"
        },
        "nl": {
            "article": "De",
            "mainWord": "halte"
        },
        "en": {
            "article": "A",
            "mainWord": "bus stop"
        },
        "es": {
            "article": "La",
            "mainWord": "parada"
        },
        "de": {
            "article": "die",
            "mainWord": "Haltestelle"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076723/vocimage/transports/arret/caribbean-4544297__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076723/vocimage/transports/arret/bus-stop-207302__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076723/vocimage/transports/arret/bus-stop-72171__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076723/vocimage/transports/arret/foliage-4514886__340.jpg"]
}, {
    "id": 805,
    "name": "avion",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "avion"
        },
        "nl": {
            "article": "Het",
            "mainWord": "vliegtuig"
        },
        "en": {
            "article": "A",
            "mainWord": "plane"
        },
        "es": {
            "article": "El",
            "mainWord": "avión"
        },
        "de": {
            "article": "das",
            "mainWord": "Flugzeug"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076699/vocimage/transports/avion/aircraft-994943__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076700/vocimage/transports/avion/aircraft-547105__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077134/vocimage/transports/avion/aircraft-63028_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077134/vocimage/transports/avion/night-flight-2307018_640.jpg"]
}, {
    "id": 806,
    "name": "hélicoptère",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "L'",
            "mainWord": "hélicoptère"
        },
        "nl": {
            "article": "De",
            "mainWord": "helikopter"
        },
        "en": {
            "article": "A",
            "mainWord": "helicopter"
        },
        "es": {
            "article": "El",
            "mainWord": "helicóptero"
        },
        "de": {
            "article": "der",
            "mainWord": "Hubschrauber"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076679/vocimage/transports/helicoptere/aircraft-933442__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076679/vocimage/transports/helicoptere/rescue-helicopter-61009__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076679/vocimage/transports/helicoptere/helicopter-1169967__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077568/vocimage/transports/helicoptere/aviation-1839167_640.jpg"]
}, {
    "id": 807,
    "name": "fusée",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "fusée"
        },
        "nl": {
            "article": "De",
            "mainWord": "raket"
        },
        "en": {
            "article": "A",
            "mainWord": "rocket"
        },
        "es": {
            "article": "La",
            "mainWord": "nave"
        },
        "de": {
            "article": "die",
            "mainWord": "Rakete"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076666/vocimage/transports/fusee/rocket-630461__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077379/vocimage/transports/fusee/space-shuttle-992_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077379/vocimage/transports/fusee/rocket-launch-693266_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077412/vocimage/transports/fusee/rocket-launch-67643_640.jpg"]
}, {
    "id": 808,
    "name": "gare",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "gare"
        },
        "nl": {
            "article": "Het",
            "mainWord": "station"
        },
        "en": {
            "article": "A",
            "mainWord": "station"
        },
        "es": {
            "article": "La",
            "mainWord": "estación"
        },
        "de": {
            "article": "der",
            "mainWord": "Bahnhof"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076655/vocimage/transports/gare/train-3384786__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076655/vocimage/transports/gare/train-station-1868256__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077511/vocimage/transports/gare/rail-4320077_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077511/vocimage/transports/gare/station-4170704_640.jpg"]
}, {
    "id": 809,
    "name": "moto",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "moto"
        },
        "nl": {
            "article": "De",
            "mainWord": "motorfiets"
        },
        "en": {
            "article": "A",
            "mainWord": "motorbike"
        },
        "es": {
            "article": "La",
            "mainWord": "moto"
        },
        "de": {
            "article": "das",
            "mainWord": "Motorrad"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076647/vocimage/transports/moto/harley-davidson-4113065__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077720/vocimage/transports/moto/motorcycle-1084694_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077720/vocimage/transports/moto/biker-407123_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077721/vocimage/transports/moto/dirt-bike-1868996_640.jpg"]
}, {
    "id": 810,
    "name": "voiture",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "voiture"
        },
        "nl": {
            "article": "De",
            "mainWord": "auto"
        },
        "en": {
            "article": "A",
            "mainWord": "car"
        },
        "es": {
            "article": "El",
            "mainWord": "coche"
        },
        "de": {
            "article": "das",
            "mainWord": "Auto"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076711/vocimage/transports/voiture/car-1859759__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078417/vocimage/transports/voiture/car-race-438467_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078417/vocimage/transports/voiture/car-1544342_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078418/vocimage/transports/voiture/fiat-500-4322521_640.jpg"]
}, {
    "id": 811,
    "name": "bateau",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bateau"
        },
        "nl": {
            "article": "De",
            "mainWord": "boot"
        },
        "en": {
            "article": "A",
            "mainWord": "boat"
        },
        "es": {
            "article": "El",
            "mainWord": "barco"
        },
        "de": {
            "article": "das",
            "mainWord": "Boot"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691077237/vocimage/transports/bateau/cruise-1578528_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077237/vocimage/transports/bateau/paper-boat-3361172_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077237/vocimage/transports/bateau/yacht-3480913_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077237/vocimage/transports/bateau/buildings-5582974_640.jpg"]
}, {
    "id": 812,
    "name": "bus",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bus"
        },
        "nl": {
            "article": "De",
            "mainWord": "bus"
        },
        "en": {
            "article": "A",
            "mainWord": "bus"
        },
        "es": {
            "article": "El",
            "mainWord": "autobús"
        },
        "de": {
            "article": "der",
            "mainWord": "Bus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076619/vocimage/transports/bus/back-to-school-183533__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076619/vocimage/transports/bus/collective-435584__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076619/vocimage/transports/bus/london-590114__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077306/vocimage/transports/bus/bus-1263266_640.jpg"]
}, {
    "id": 813,
    "name": "camion",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "camion"
        },
        "nl": {
            "article": "De",
            "mainWord": "vrachtwagen"
        },
        "en": {
            "article": "A",
            "mainWord": "lorry"
        },
        "es": {
            "article": "El",
            "mainWord": "camión"
        },
        "de": {
            "article": "der",
            "mainWord": "Lastkraftwagen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076605/vocimage/transports/camion/truck-4349523__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076605/vocimage/transports/camion/formula-1-2701335__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076605/vocimage/transports/camion/highway-3392100__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076605/vocimage/transports/camion/truck-766800__340.jpg"]
}, {
    "id": 814,
    "name": "métro",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "métro"
        },
        "nl": {
            "article": "De",
            "mainWord": "metro"
        },
        "en": {
            "article": "The",
            "mainWord": "underground"
        },
        "es": {
            "article": "El",
            "mainWord": "metro"
        },
        "de": {
            "article": "die",
            "mainWord": "U-Bahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076591/vocimage/transports/metro/london-94268__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076591/vocimage/transports/metro/metro-2611115__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076591/vocimage/transports/metro/vienna-5103169__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077671/vocimage/transports/metro/subway-2599115_640.jpg"]
}, {
    "id": 815,
    "name": "parking",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "parking"
        },
        "nl": {
            "article": "De",
            "mainWord": "parking"
        },
        "en": {
            "article": "A",
            "mainWord": "parking"
        },
        "es": {
            "article": "El",
            "mainWord": "parking"
        },
        "de": {
            "article": "das",
            "mainWord": "Parkhaus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076571/vocimage/transports/parking/electric-car-2783573__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076571/vocimage/transports/parking/multi-storey-car-park-2705368__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077852/vocimage/transports/parking/parking-spot-4615361_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077855/vocimage/transports/parking/parking-427955_640.jpg"]
}, {
    "id": 816,
    "name": "port",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "port"
        },
        "nl": {
            "article": "De",
            "mainWord": "haven"
        },
        "en": {
            "article": "A",
            "mainWord": "harbour"
        },
        "es": {
            "article": "El",
            "mainWord": "puerto"
        },
        "de": {
            "article": "der",
            "mainWord": "Hafen"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076562/vocimage/transports/port/blue-hour-1753707__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076562/vocimage/transports/port/ship-2418712__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076562/vocimage/transports/port/greece-2736502__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691077896/vocimage/transports/port/crane-3109757_640.jpg"]
}, {
    "id": 817,
    "name": "sous-marin",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "sous-marin"
        },
        "nl": {
            "article": "De",
            "mainWord": "onderzeeër"
        },
        "en": {
            "article": "A",
            "mainWord": "submarine"
        },
        "es": {
            "article": "El",
            "mainWord": "submarino"
        },
        "de": {
            "article": "das",
            "mainWord": "U-Boot"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691078060/vocimage/transports/sous%20marin/submarine-3997192_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078060/vocimage/transports/sous%20marin/submarine-140781_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078060/vocimage/transports/sous%20marin/submarine-85915_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078244/vocimage/transports/sous%20marin/submarine-3999365_640.jpg"]
}, {
    "id": 818,
    "name": "taxi",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "taxi"
        },
        "nl": {
            "article": "De",
            "mainWord": "taxi"
        },
        "en": {
            "article": "A",
            "mainWord": "cab"
        },
        "es": {
            "article": "El",
            "mainWord": "taxi"
        },
        "de": {
            "article": "das",
            "mainWord": "Taxi"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076497/vocimage/transports/taxi/newyork-993613__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076497/vocimage/transports/taxi/london-2878425__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076497/vocimage/transports/taxi/nyc-taxi-439740__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076545/vocimage/transports/taxi/asia-2179107__340.jpg"]
}, {
    "id": 819,
    "name": "tracteur",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tracteur"
        },
        "nl": {
            "article": "De",
            "mainWord": "tractor"
        },
        "en": {
            "article": "A",
            "mainWord": "tractor"
        },
        "es": {
            "article": "El",
            "mainWord": "tractor"
        },
        "de": {
            "article": "der",
            "mainWord": "Traktor"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076461/vocimage/transports/tracteur/tractor-5030721__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076462/vocimage/transports/tracteur/tractor-5091577__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076462/vocimage/transports/tracteur/nature-5028760__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076462/vocimage/transports/tracteur/idyllic-5054084__340.jpg"]
}, {
    "id": 820,
    "name": "train",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "train"
        },
        "nl": {
            "article": "De",
            "mainWord": "trein"
        },
        "en": {
            "article": "A",
            "mainWord": "train"
        },
        "es": {
            "article": "El",
            "mainWord": "tren"
        },
        "de": {
            "article": "der",
            "mainWord": "Zug"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076447/vocimage/transports/train/train-143847__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076447/vocimage/transports/train/locomotive-222174__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076447/vocimage/transports/train/train-1728537__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076447/vocimage/transports/train/tgv-3898070__340.jpg"]
}, {
    "id": 821,
    "name": "tram",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "tram"
        },
        "nl": {
            "article": "De",
            "mainWord": "tram"
        },
        "en": {
            "article": "A",
            "mainWord": "tram"
        },
        "es": {
            "article": "El",
            "mainWord": "tranvía"
        },
        "de": {
            "article": "die",
            "mainWord": "Straßenbahn"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076291/vocimage/transports/tram/trolley-2203329__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076292/vocimage/transports/tram/downtown-3456648__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078292/vocimage/transports/tram/metro-8059215_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691078343/vocimage/transports/tram/portugal-4828134_640.jpg"]
}, {
    "id": 822,
    "name": "vélo",
    "themeId": 9,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "vélo"
        },
        "nl": {
            "article": "De",
            "mainWord": "fiets"
        },
        "en": {
            "article": "A",
            "mainWord": "bicycle"
        },
        "es": {
            "article": "La",
            "mainWord": "bicicleta"
        },
        "de": {
            "article": "das",
            "mainWord": "Fahrrad"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1691076273/vocimage/transports/velo/woman-2711279__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076273/vocimage/transports/velo/bicycle-1872682__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076273/vocimage/transports/velo/bicycle-1838972__340.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1691076273/vocimage/transports/velo/bicycle-788733__340.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}