import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';


class Myle extends React.Component {
    render() {
        return(
            <h3 className="text-center">                    
                Jeu créé par <a href="https://www.myle.be" target="_blank">MYLE</a>
                <div id="social-network">
                    <a href="https://www.facebook.com/MYLE-Make-Your-Life-Easier-110150860356428/" target="_blank">
                        <FontAwesomeIcon icon={faFacebook} size="2x" className="app-main-color"/>
                    </a>
                </div>
            </h3>
        )
    }
}

export default Myle;