import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePlay } from '../actions/play.js';

class Playbutton extends React.Component {

    handleClickPlay = () => {
        if (this.props.themesids.length > 0) {
            this.props.changePlay(true);
        } else {
            alert('Sélectionnez un thème !')
        }
    }

    render() {
        return(
            <div className="flex-container">
                <button id="play" className="btn btn-primary" onClick={this.handleClickPlay}>Jouer</button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {themesids: state.themesids}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { changePlay },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Playbutton);