import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectLanguage } from '../actions/language.js';

class Language extends React.Component {

    handleChangeLanguage = (event) => {
        this.props.selectLanguage(this.props.languages, event.target.value);
    }

    render() {
        const {ln} = this.props;
        return(
            <div className="radiobtn">
            <input  type="radio" 
                    id={ln.name}
                    name="language" 
                    value={ln.name} 
                    onChange={this.handleChangeLanguage} 
                    checked={this.props.language.name === ln.name} />
            <label htmlFor={ln.name}>{ln.translations.fr}</label>
        </div>

        )
    }
}

function mapStateToProps(state) {
    return {languages: state.languages, 
            language: state.language}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { selectLanguage },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Language);