import React from 'react';
import Letter from '../containers/Letter';

class Header extends React.Component {
    renderTitleApp = (word) => {
        let possibleLetter = {};
        return word.split('').map(
            (letter, index) => {   
                possibleLetter = {letter: letter, position: index, visible: true, afterWhiteSpace: false, locked: false}
                return <Letter letter={possibleLetter} 
                                       key={index} />}
        )
    }

    render() {
        return(
            <div id="introduction">
                <h1 className="app-title">
                    {this.renderTitleApp("VOC")}
                </h1>
                <h1 className="app-title">
                    {this.renderTitleApp("IMAGE")}
                </h1>
                <h2>
                    Devine le mot représenté par les images
                </h2>
            </div>
        )
    }
}

export default Header;