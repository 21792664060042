const words = 
[{
    "id": 1,
    "name": "armoire",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "armoire"
        },
        "nl": {
            "article": "De",
            "mainWord": "kast"
        },
        "en": {
            "article": "A",
            "mainWord": "cupboard"
        },
        "es": {
            "article": "El",
            "mainWord": "armario"
        },
        "de": {
            "article": "Der",
            "mainWord": "Schrank"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803133/vocimage/maison/armoire/kitchen-cabinet-1817373_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803133/vocimage/maison/armoire/armoire.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802925/vocimage/maison/armoire/towels-923505_960_720_1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1686817683/vocimage/maison/armoire/cupboard-2160186_960_720.jpg"]
}, {
    "id": 2,
    "name": "escalier",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "L’",
            "mainWord": "escalier"
        },
        "nl": {
            "article": "De",
            "mainWord": "trap"
        },
        "en": {
            "article": "The",
            "mainWord": "stairs"
        },
        "es": {
            "article": "La",
            "mainWord": "escalera"
        },
        "de": {
            "article": "Die",
            "mainWord": "Treppe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803233/vocimage/maison/escalier/house-7609267_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803175/vocimage/maison/escalier/staircase-274614_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803160/vocimage/maison/escalier/stairs-1229149_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803159/vocimage/maison/escalier/kid-4363118_960_720.jpg"]
}, {
    "id": 3,
    "name": "baignoire",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "baignoire"
        },
        "nl": {
            "article": "Het",
            "mainWord": "bad"
        },
        "en": {
            "article": "A",
            "mainWord": "bathroom"
        },
        "es": {
            "article": "La",
            "mainWord": "bañera"
        },
        "de": {
            "article": "Die",
            "mainWord": "Badewane"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803287/vocimage/maison/baignoire/bathroom-6686057_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803287/vocimage/maison/baignoire/plant-7498330_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803245/vocimage/maison/baignoire/tub-4907937_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803245/vocimage/maison/baignoire/spa-4200983_960_720.jpg"]
}, {
    "id": 4,
    "name": "chaise",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chaise"
        },
        "nl": {
            "article": "De",
            "mainWord": "stoel"
        },
        "en": {
            "article": "A",
            "mainWord": "chair"
        },
        "es": {
            "article": "La",
            "mainWord": "silla"
        },
        "de": {
            "article": "Der",
            "mainWord": "Stuhl"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803358/vocimage/maison/chaise/chair-4788242_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803348/vocimage/maison/chaise/beach-815303_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803301/vocimage/maison/chaise/pink-chair-889695_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803301/vocimage/maison/chaise/deckchairs-355596_960_720.jpg"]
}, {
    "id": 5,
    "name": "chambre",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "chambre"
        },
        "nl": {
            "article": "De",
            "mainWord": "slaapkamer"
        },
        "en": {
            "article": "A",
            "mainWord": "bedroom"
        },
        "es": {
            "article": "La",
            "mainWord": "habitación"
        },
        "de": {
            "article": "Das",
            "mainWord": "Schlafzimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690804772/vocimage/maison/chambre/children-4508017_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804772/vocimage/maison/chambre/bedroom-349699_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804772/vocimage/maison/chambre/interior-design-4467768_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804474/vocimage/maison/chambre/nursery-1078923_960_720.jpg"]
}, {
    "id": 6,
    "name": "cuisine",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "cuisine"
        },
        "nl": {
            "article": "De",
            "mainWord": "keuken"
        },
        "en": {
            "article": "A",
            "mainWord": "kitchen"
        },
        "es": {
            "article": "La",
            "mainWord": "cocina"
        },
        "de": {
            "article": "Die",
            "mainWord": "Kuche"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803491/vocimage/maison/cuisine/kitchen-2400367_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803399/vocimage/maison/cuisine/dough-943245_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803390/vocimage/maison/cuisine/kitchen-2165756_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803379/vocimage/maison/cuisine/woman-1979272_960_720.jpg"]
}, {
    "id": 7,
    "name": "douche",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "douche"
        },
        "nl": {
            "article": "De",
            "mainWord": "douche"
        },
        "en": {
            "article": "A",
            "mainWord": "shower"
        },
        "es": {
            "article": "La",
            "mainWord": "ducha"
        },
        "de": {
            "article": "Die",
            "mainWord": "Dusche"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803578/vocimage/maison/douche/horse-2182617_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803578/vocimage/maison/douche/shower-389260_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803510/vocimage/maison/douche/elephant-3060526_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803510/vocimage/maison/douche/shower-1502736_960_720.jpg"]
}, {
    "id": 8,
    "name": "fenêtre",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "fenêtre"
        },
        "nl": {
            "article": "Het",
            "mainWord": "raam"
        },
        "en": {
            "article": "A",
            "mainWord": "window"
        },
        "es": {
            "article": "La",
            "mainWord": "ventana"
        },
        "de": {
            "article": "Das",
            "mainWord": "Fenster"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803596/vocimage/maison/fenetre/cat-4985135_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803595/vocimage/maison/fenetre/auto-3091234_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803595/vocimage/maison/fenetre/city-4991094_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803595/vocimage/maison/fenetre/shutters-669296_960_720.jpg"]
}, {
    "id": 9,
    "name": "lampe",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "lampe"
        },
        "nl": {
            "article": "De",
            "mainWord": "lamp"
        },
        "en": {
            "article": "A",
            "mainWord": "lamp"
        },
        "es": {
            "article": "La",
            "mainWord": "lámpara"
        },
        "de": {
            "article": "Die",
            "mainWord": "Lampe"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803732/vocimage/maison/lampe/christmas-2594038_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803625/vocimage/maison/lampe/lamp-2490_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803625/vocimage/maison/lampe/boy-1822614_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803612/vocimage/maison/lampe/bazaar-1853361_960_720.jpg"]
}, {
    "id": 10,
    "name": "maison",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "maison"
        },
        "nl": {
            "article": "Het",
            "mainWord": "huis"
        },
        "en": {
            "article": "A",
            "mainWord": "house"
        },
        "es": {
            "article": "La",
            "mainWord": "casa"
        },
        "de": {
            "article": "Das",
            "mainWord": "Haus"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690804244/vocimage/maison/maison/house-1836070_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804244/vocimage/maison/maison/architecture-1477099_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804094/vocimage/maison/maison/belgium-2723175_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804094/vocimage/maison/maison/house-4028391_960_720.jpg"]
}, {
    "id": 11,
    "name": "porte",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "porte"
        },
        "nl": {
            "article": "De",
            "mainWord": "deur"
        },
        "en": {
            "article": "A",
            "mainWord": "door"
        },
        "es": {
            "article": "La",
            "mainWord": "puerta"
        },
        "de": {
            "article": "Die",
            "mainWord": "Tür"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690804079/vocimage/maison/porte/porte1.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803940/vocimage/maison/porte/porte.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803807/vocimage/maison/porte/door-1587863_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803807/vocimage/maison/porte/door-681835_960_720.jpg"]
}, {
    "id": 12,
    "name": "radio",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "radio"
        },
        "nl": {
            "article": "De",
            "mainWord": "radio"
        },
        "en": {
            "article": "A",
            "mainWord": "radio"
        },
        "es": {
            "article": "La",
            "mainWord": "radio"
        },
        "de": {
            "article": "Das",
            "mainWord": "Radio"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690803772/vocimage/maison/radio/radio-4245029_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803772/vocimage/maison/radio/radio-2588503_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803772/vocimage/maison/radio/doctor-1740044_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690803758/vocimage/maison/radio/vintage-1216720_960_720.jpg"]
}, {
    "id": 13,
    "name": "salle à manger",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "salle à manger"
        },
        "nl": {
            "article": "De",
            "mainWord": "eetkamer"
        },
        "en": {
            "article": "A",
            "mainWord": "dining room"
        },
        "es": {
            "article": "El",
            "mainWord": "comedor"
        },
        "de": {
            "article": "Das",
            "mainWord": "Esszimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690804378/vocimage/maison/salle%20a%20manger/salle-%C3%A0-manger-scandinave-espace-blanc-et-lumineux.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804264/vocimage/maison/salle%20a%20manger/cutlery-1313930_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804263/vocimage/maison/salle%20a%20manger/interior-3575818_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804263/vocimage/maison/salle%20a%20manger/table-1930862_960_720.jpg"]
}, {
    "id": 14,
    "name": "salle de bain",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "salle de bain"
        },
        "nl": {
            "article": "De",
            "mainWord": "badkamer"
        },
        "en": {
            "article": "A",
            "mainWord": "bathroom"
        },
        "es": {
            "article": "El",
            "mainWord": "cuarto de baño"
        },
        "de": {
            "article": "Das",
            "mainWord": "Badezimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690805018/vocimage/maison/salle%20de%20bain/bathtub-3622540_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804981/vocimage/maison/salle%20de%20bain/bathroom-1336165_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804455/vocimage/maison/salle%20de%20bain/rubber-duckies-14614_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804455/vocimage/maison/salle%20de%20bain/bathroom-2094716_960_720.jpg"]
}, {
    "id": 15,
    "name": "salle de jeux",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "salle de jeux"
        },
        "nl": {
            "article": "De",
            "mainWord": "speelkamer"
        },
        "en": {
            "article": "A",
            "mainWord": "playroom"
        },
        "es": {
            "article": "El",
            "mainWord": "cuarto de juegos"
        },
        "de": {
            "article": "Das",
            "mainWord": "Spielzimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690804439/vocimage/maison/salle%20de%20jeux/toddler-room-569199_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804398/vocimage/maison/salle%20de%20jeux/balls-3288123_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804398/vocimage/maison/salle%20de%20jeux/gym-700349_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690804398/vocimage/maison/salle%20de%20jeux/toys-1210114_960_720.jpg"]
}, {
    "id": 16,
    "name": "table",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "table"
        },
        "nl": {
            "article": "De",
            "mainWord": "tafel"
        },
        "en": {
            "article": "A",
            "mainWord": "table"
        },
        "es": {
            "article": "La",
            "mainWord": "mesa"
        },
        "de": {
            "article": "Der",
            "mainWord": "Tisch"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802910/vocimage/maison/table/chairs-2181951_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802910/vocimage/maison/table/couch-1845270_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802816/vocimage/maison/table/restaurant-766050_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802816/vocimage/maison/table/feet-932346_960_720.jpg"]
}, {
    "id": 17,
    "name": "télévision",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "télévision"
        },
        "nl": {
            "article": "De",
            "mainWord": "televisie"
        },
        "en": {
            "article": "A",
            "mainWord": "television"
        },
        "es": {
            "article": "El",
            "mainWord": "televisor"
        },
        "de": {
            "article": "Der",
            "mainWord": "Fernseher"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802673/vocimage/maison/television/elephant-964898_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802673/vocimage/maison/television/camera-973107_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802673/vocimage/maison/television/remote-control-932273_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802673/vocimage/maison/television/gaming-2259191_960_720.jpg"]
}, {
    "id": 18,
    "name": "terrasse",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "terrasse"
        },
        "nl": {
            "article": "Het",
            "mainWord": "terras"
        },
        "en": {
            "article": "A",
            "mainWord": "terrace"
        },
        "es": {
            "article": "La",
            "mainWord": "terraza"
        },
        "de": {
            "article": "Die",
            "mainWord": "Terasse"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802654/vocimage/maison/terrasse/chairs-4033042_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802654/vocimage/maison/terrasse/table-1489063_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802654/vocimage/maison/terrasse/castle-park-806854_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802654/vocimage/maison/terrasse/architecture-3623321_960_720.jpg"]
}, {
    "id": 19,
    "name": "voiture",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "La",
            "mainWord": "voiture"
        },
        "nl": {
            "article": "De",
            "mainWord": "auto"
        },
        "en": {
            "article": "A",
            "mainWord": "car"
        },
        "es": {
            "article": "El",
            "mainWord": "coche"
        },
        "de": {
            "article": "Das",
            "mainWord": "Auto"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1686819899/vocimage/maison/voiture/speedometer-1249610_1280.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1686819891/vocimage/maison/voiture/ford-290615_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1686819878/vocimage/maison/voiture/car-4148514_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1686819870/vocimage/maison/voiture/vintage-car-852239_960_720.jpg"]
}, {
    "id": 20,
    "name": "bureau",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "bureau"
        },
        "nl": {
            "article": "Het",
            "mainWord": "bureau"
        },
        "en": {
            "article": "An",
            "mainWord": "office"
        },
        "es": {
            "article": "El",
            "mainWord": "despacho"
        },
        "de": {
            "article": "Das",
            "mainWord": "Arbeitzimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802633/vocimage/maison/bureau/workplace-5517744_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802578/vocimage/maison/bureau/living-room-7822517_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802408/vocimage/maison/bureau/office-932926_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802408/vocimage/maison/bureau/pencils-762555_960_720.jpg"]
}, {
    "id": 21,
    "name": "fauteuil",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "fauteuil"
        },
        "nl": {
            "article": "De",
            "mainWord": "sofa"
        },
        "en": {
            "article": "A",
            "mainWord": "sofa"
        },
        "es": {
            "article": "El",
            "mainWord": "sillón"
        },
        "de": {
            "article": "Der",
            "mainWord": "sessel"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802380/vocimage/maison/fauteuil/chair-1049325_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802379/vocimage/maison/fauteuil/furniture-2436901_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802379/vocimage/maison/fauteuil/sofa-1078931_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802379/vocimage/maison/fauteuil/furniture-2436878_960_720.jpg"]
}, {
    "id": 22,
    "name": "frigo",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "frigo"
        },
        "nl": {
            "article": "De",
            "mainWord": "koelkast"
        },
        "en": {
            "article": "A",
            "mainWord": "fridge"
        },
        "es": {
            "article": "La",
            "mainWord": "nevera"
        },
        "de": {
            "article": "Der",
            "mainWord": "Kühlschrank"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802363/vocimage/maison/frigo/fridge-3475996_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802322/vocimage/maison/frigo/ice-cream-1631846_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802321/vocimage/maison/frigo/water-bottle-3475997_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802321/vocimage/maison/frigo/full-fridge-1729679_960_720.jpg"]
}, {
    "id": 23,
    "name": "garage",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "garage"
        },
        "nl": {
            "article": "De",
            "mainWord": "garage"
        },
        "en": {
            "article": "A",
            "mainWord": "garage"
        },
        "es": {
            "article": "El",
            "mainWord": "garaje"
        },
        "de": {
            "article": "Die",
            "mainWord": "Garage"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802306/vocimage/maison/garage/car-4419081_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802265/vocimage/maison/garage/garage-265669_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802265/vocimage/maison/garage/things-2609870_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690802264/vocimage/maison/garage/autos-214033_960_720.jpg"]
}, {
    "id": 24,
    "name": "grenier",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "grenier"
        },
        "nl": {
            "article": "De",
            "mainWord": "zolder"
        },
        "en": {
            "article": "An",
            "mainWord": "attic"
        },
        "es": {
            "article": "El",
            "mainWord": "ático"
        },
        "de": {
            "article": "Der",
            "mainWord": "Speicher"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690802246/vocimage/maison/grenier/skylight-2777582_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801967/vocimage/maison/grenier/bear-3214226_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801967/vocimage/maison/grenier/lost-places-4211518_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801967/vocimage/maison/grenier/tricycle-1755577_960_720.jpg"]
}, {
    "id": 25,
    "name": "hall",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "hall"
        },
        "nl": {
            "article": "De",
            "mainWord": "hal"
        },
        "en": {
            "article": "A",
            "mainWord": "hall"
        },
        "es": {
            "article": "El",
            "mainWord": "pasillo"
        },
        "de": {
            "article": "Der",
            "mainWord": "Flur"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690801946/vocimage/maison/hall/indoors-3117053_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801946/vocimage/maison/hall/apartment-185776_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801773/vocimage/maison/hall/school-417612_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801773/vocimage/maison/hall/governors-mansion-1612734_960_720.jpg"]
}, {
    "id": 26,
    "name": "jardin",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "jardin"
        },
        "nl": {
            "article": "De",
            "mainWord": "tuin"
        },
        "en": {
            "article": "A",
            "mainWord": "garden"
        },
        "es": {
            "article": "El",
            "mainWord": "jardín"
        },
        "de": {
            "article": "Der",
            "mainWord": "Garten"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690801755/vocimage/maison/jardin/girl-535251_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801708/vocimage/maison/jardin/seedling-5009286_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801708/vocimage/maison/jardin/garden-2040714_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801708/vocimage/maison/jardin/pathway-2289978_960_720.jpg"]
}, {
    "id": 27,
    "name": "lit",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "lit"
        },
        "nl": {
            "article": "Het",
            "mainWord": "bed"
        },
        "en": {
            "article": "A",
            "mainWord": "bed"
        },
        "es": {
            "article": "La",
            "mainWord": "cama"
        },
        "de": {
            "article": "Das",
            "mainWord": "Bett"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690801686/vocimage/maison/lit/bed-1846251_640.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801612/vocimage/maison/lit/bed-1853907_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801612/vocimage/maison/lit/mattress-2489615_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801612/vocimage/maison/lit/bedroom-1285156_960_720.jpg"]
}, {
    "id": 28,
    "name": "salon",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Le",
            "mainWord": "salon"
        },
        "nl": {
            "article": "De",
            "mainWord": "woonkamer"
        },
        "en": {
            "article": "A",
            "mainWord": "living room"
        },
        "es": {
            "article": "El",
            "mainWord": "salón"
        },
        "de": {
            "article": "Das",
            "mainWord": "Wohnzimmer"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690801550/vocimage/maison/salon/apartment-lounge-3147892_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801550/vocimage/maison/salon/pool-bar-3652849_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801550/vocimage/maison/salon/apartment-3677491_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801549/vocimage/maison/salon/living-room-2037945_960_720.jpg"]
}, {
    "id": 29,
    "name": "toilettes",
    "themeId": 1,
    "translations": {
        "fr": {
            "article": "Les",
            "mainWord": "toilettes"
        },
        "nl": {
            "article": "Het",
            "mainWord": "toilet"
        },
        "en": {
            "article": "A",
            "mainWord": "bath"
        },
        "es": {
            "article": "El",
            "mainWord": "baño"
        },
        "de": {
            "article": "Das",
            "mainWord": "WC"
        }
    },
    "images": ["https://res.cloudinary.com/chaudard/image/upload/v1690801526/vocimage/maison/toilettes/bathroom-1867354_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801526/vocimage/maison/toilettes/crisis-4974243_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801526/vocimage/maison/toilettes/shopping-4974313_960_720.jpg", "https://res.cloudinary.com/chaudard/image/upload/v1690801526/vocimage/maison/toilettes/frog-914131_960_720.jpg"]
}]

module.exports={
    getWords(){
        return words;
    }
}