import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nextWord, RemovePlayerWord } from '../actions/game.js';
import { changePlay } from '../actions/play.js';
import { increaseWallet } from '../actions/wallet.js';
import allWords from '../../data/allWords';
import { isFullWord } from '../../helpers/letters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Delay from 'react-delay';
import UIfx from 'uifx';
import successMp3 from '../../data/sounds/success.mp3';
import errorMp3 from '../../data/sounds/error.mp3';
import winMp3 from '../../data/sounds/win.mp3';


class Icon extends React.Component {

    constructor(props) {
        super(props);
        this.delay = 400; // to display icon
        this.delayNextWord = 1200; // time of winning song
        this.wordFoundReward = 3;
        this.busy = false;
    }

    winSong = () => {
        const song = new UIfx(
            winMp3,
            {
                volume: 0.1 // number between 0.0 ~ 1.0
            }  
        );
        song.play();
    }

    increaseWallet = () => {
        this.props.increaseWallet(this.wordFoundReward);
    }

    celebration = (lastWord) => {
        const commonMessage = 'Bravo ! Vous avez gagné 3 nouveaux chapeaux.';
        let message = commonMessage;
        if (lastWord) {
            message += "\nRetour au menu."
        } else {
            message += "\nPrêt(e) pour le mot suivant ?"
        }
        // alert(message);
        this.winSong();
    }

    nextWord = () => {
        if (!this.busy) {
            this.busy = true;            
            // const lastWord = false;
            // this.celebration(lastWord);
            // setTimeout(() => {
                const words = allWords();
                this.props.nextWord(this.props.game.word, 
                                    this.props.game.wordIds, 
                                    words, 
                                    this.props.language, 
                                    this.props.level)
                this.increaseWallet();
                this.busy = false;
            // }, this.delayNextWord);
        }
    }

    removeWord = () => {
        this.props.RemovePlayerWord(this.props.game.possibleLetters, 
                                    this.props.game.playerLetters);
    }

    playAgain = () => {
        if (!this.busy) { 
            this.busy = true;           
            // const lastWord = true;
            // this.celebration(lastWord);
            // setTimeout(() => {
                this.props.changePlay(false);
                this.increaseWallet();
                this.busy = false;
            // }, this.delayNextWord);
        }
    }

    delaySong = (song) => {
        setTimeout(() => {song.play()}, this.delay);
    }

    successSong = () => {
        const song = new UIfx(
            successMp3,
            {
                volume: 0.2 // number between 0.0 ~ 1.0
            }  
        );
        this.delaySong(song);            
    }

    errorSong = () => {
        const song = new UIfx(errorMp3);
        this.delaySong(song);            
    }

    renderIcon = () => {
        let backgroundUrl = '';
        if (this.props.game.found) {
            if (this.props.game.finished) {
                return(
                    <Delay wait={this.delay}>
                        {/* {this.successSong()}    */}
                        <div id="last-good-word" className="button-icon" onClick={this.playAgain}>                        
                            <FontAwesomeIcon icon={faCheckCircle} size="2x"/>                            
                            <div>
                                Terminer
                            </div>
                        </div>
                    </Delay>
                )
            } else {
                return(                       
                    <Delay wait={this.delay}>
                        {/* {this.successSong()} */}
                        <div id="good-word" className="button-icon" onClick={this.nextWord}>
                            <FontAwesomeIcon icon={faCheckCircle} size="2x"/>
                            <div>
                                Continuer
                            </div>
                        </div>
                    </Delay>
                )
            }
        } else {
            if (isFullWord(this.props.game.playerLetters)) {
                return(
                    <Delay wait={this.delay}>
                        <div id="wrong-word" className="button-icon" onClick={this.removeWord}>
                            <FontAwesomeIcon icon={faTimesCircle} size="2x"/>
                            <div>
                                Effacer
                            </div>
                        </div>
                        {/* {this.errorSong()} */}
                    </Delay>
                )
            }
        }        
    }

    render() {
        return(
            <div className="flex-container">
                {this.renderIcon()}
            </div>     
        )
    }
}

function mapStateToProps(state) {
    return {game: state.game, 
            language: state.language,
            level: state.level,
            wallet: state.wallet}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { nextWord, RemovePlayerWord, changePlay, increaseWallet },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Icon);