export const SELECT_THEME = 'SELECT_THEME';
export const DESELECT_THEME = 'DESELECT_THEME';

export function selectTheme(themeId) {
    return {
        type: SELECT_THEME,
        payload: themeId
    }
}

export function deselectTheme(themeId) {
    return {
        type: DESELECT_THEME,
        payload: themeId
    }
}