import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLanguages } from '../actions/languages.js';
import { fetchThemes } from '../actions/themes.js';
import { deselectAllTheme } from '../actions/themes.js';
import Header from '../components/Header';
import Languages from './Languages';
import Themes from './Themes';
import Level from './Level';
import Maxwords from './Maxwords';
import Playbutton from './Playbutton';
import Myle from '../components/Myle';
import Wallet from './Wallet.jsx';
import Faqcollapse from '../components/Faqcollapse.jsx';

class Home extends React.Component {

    componentWillMount() {
        if (this.props.play == false) {            
            this.props.fetchLanguages();
            this.props.fetchThemes();
            this.props.deselectAllTheme();
        }
    }

    render() {
        return(
            <div className="container-vocimage">
                <Header />                     
                <Faqcollapse />
                <div id="form-home">
                    <Languages />
                    <Themes />
                    <Level />
                    <Maxwords />
                </div>
                <div className="flex-container">
                    <Wallet sizeicon={1}/>
                </div>
                <Playbutton />
                <Myle />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { play: state.play }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { fetchLanguages,      
      fetchThemes,
      deselectAllTheme },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Home);