import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nextWord } from '../actions/game.js';
import { changePlay } from '../actions/play.js';
import allWords from '../../data/allWords';
import { setFinished } from '../actions/game.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

class Testools extends React.Component {
    
    endTest = () => {
        if (window.confirm("Etes-vous certain de revenir au menu ?")) {            
            this.props.changePlay(false);
            this.props.setFinished(true);
        }
    }
    
    getPositionInTheme = (Id) => {
        let result = Id;
        while (result > 100) {
            result -= 100;
        }
        return result;
    }

    nextWord = () => {
        if (!this.busy) {
            this.busy = true;            
                const words = allWords();
                this.props.nextWord(this.props.game.word, 
                                    this.props.game.wordIds, 
                                    words, 
                                    this.props.language, 
                                    this.props.level)
            this.busy = false;
        }
    }

    render() {
        const theme = this.props.themes.find(theme => theme.id === this.props.game.word.themeId);
        const positionInTheme = this.getPositionInTheme(this.props.game.word.id);
        const canNextWord = this.props.game.wordIds.length > 1;
        if (canNextWord == true) {
            return(            
                <div>
                    <div id="icon-next" className="flex-container" onClick={this.nextWord}>
                        <FontAwesomeIcon icon={faPlayCircle} size="2x" className="app-main-color"/>
                    </div>
                    <p>
                        mot à trouver : {this.props.game.word.name} <br/>
                    </p>
                    <p>
                        thème : {theme.name} (mot : {positionInTheme})
                    </p>
                    <div id="icon-power-off" className="flex-container" onClick={this.endTest}>
                        <FontAwesomeIcon icon={faPauseCircle} size="2x" className="app-main-color"/>
                    </div>
                </div>
            )
        } else {
            return(            
                <div>
                    <p>
                        Vous êtes arrivé au bout du test.
                    </p>
                    <p>
                        mot à trouver : {this.props.game.word.name} <br/>
                    </p>
                    <p>
                        thème : {theme.name} (mot : {positionInTheme})
                    </p>
                    <div id="icon-power-off" className="flex-container" onClick={this.endTest}>
                        <FontAwesomeIcon icon={faPauseCircle} size="2x" className="app-main-color"/>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {game: state.game, 
            language: state.language,
            level: state.level,
            themes: state.themes
            }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { nextWord, changePlay, setFinished },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Testools);
