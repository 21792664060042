import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectTheme, deselectTheme } from '../actions/theme.js';

class Theme extends React.Component {

    handleClickTheme = (event) => {
        if (event.target.checked) {
            this.props.selectTheme(Number(event.target.value))
        } else {
            this.props.deselectTheme(Number(event.target.value))
        }
    }

    render() {
        const {theme} = this.props;
        return(
            <div className="radiobtn">
            <input type="checkbox" 
                   id={theme.id}
                   name="themes" 
                   value={theme.id} 
                   onChange={this.handleClickTheme} 
                   checked={this.props.themesids.includes(theme.id)} />
            <label htmlFor={theme.id}>{theme.name}</label>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {themesids: state.themesids}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    { selectTheme, deselectTheme },
    dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (Theme);